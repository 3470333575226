/* eslint-disable */
import { get } from 'lodash';
import debugMod from 'debug';
// eslint-disable-next-line
const debug = debugMod('roster:stateMateralizers');

const retrieveSingleRelationship = (state, type, id) =>
  get(state, `[${type}s].resources[${id}]`, {});
export const retrieveRelationship = (state, rel) => {
  if (Array.isArray(rel.data)) {
    return rel.data.map((r) => retrieveSingleRelationship(state, r.type, r.id));
  }
  if (rel.data && rel.data.type && rel.data.id) {
    return retrieveSingleRelationship(state, rel.data.type, rel.data.id);
  }
  return [];
};
export const retrieveRelationships = (state, resource) => {
  return Object.entries(resource.relationships || []).reduce(
    (a, [k, v]) => (a[k] = retrieveRelationship(state, v)) && a,
    {}
  );
};

export function heterogeneousIncludedResources(
  dispatch,
  action,
  body,
  excludes = []
) {
  const resources = (body.included || []).reduce((m, o) => {
    const k = `${get(o, 'type', 'UNKNOWN')}s`;
    if (!excludes.includes(k)) (m[k] = m[k] || []).push(o);
    return m;
  }, {});
  dispatch({
    ...action,
    requestProperties: { responseMeta: body.meta },
    includedResources: resources,
  });
}
/* eslint-enable */
