/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import {
  uniq as _uniq,
  uniqBy as _uniqBy,
  merge as _merge,
  get as _get,
} from 'lodash';
import debugMod from 'debug';

import { Route, Switch } from 'react-router';
import {
  ControlOwnersApp,
  ControlOwnersGrid,
  ControlOwnersGridLoading,
  ControlOwnersGridNoResults,
  ControlOwnersGridError,
} from '../components/controlOwners/ControlOwnersApp';
import {
  ControlOwnersHeaderLoading,
  ControlOwnersHeader,
} from '../components/controlOwners/ControlOwnersHeader';

import { readControlOwners, createReport } from '../store/apiActions';
import {
  lastControlOwnersRequestAndStatusSelector,
  controlOwnersSelector,
  currentUserSelector,
  currentUserJurisdictionSelector,
} from '../store/selectors';
import { ALERT_TYPES, createAlert } from '../store/alertManager';
import { retrieveRelationships } from '../store/stateMaterializers';

import { ControlOwnersFacetPanel } from '../components/controlOwners/ControlOwnersFacetPanel';
import { PagingFilterInfo } from '../components/grid/PagingFilterInfo';
import Error from '../components/Error';

import {
  queryStringStringify,
  queryStringParse,
} from '../utils/QueryStringParser';
import {
  isImpersonating,
  regionsAndCountriesForJurisdiction,
} from '../utils/JurisdictionUtils';
import { fullNameFromUserAttributes } from '../utils/ProfileUtils';
import { assureArray, parsedOrOrig } from '../utils/FormatUtils';
import {
  enableCurrentViewReport,
  disableCurrentViewReport,
} from '../store/reportsManager';

import ControlOwnersJurisdictionRouter from '../components/controlOwners/ControlOwnersJurisdictionRouter';
import { intl } from '../intl/IntlFormat';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line
const debug = debugMod('roster:ControlOwnersContainer');

export const CONTROL_OWNERS_REPORT_TYPE = 'controlOwners';

const PAGE_LIMIT = 3000;

const parseQueryParam = (queryParam = {}) => {
  const { page = 1, pagelimit = PAGE_LIMIT } = queryParam;
  return {
    // If page is not parsible as int, default to 1. If page < 1, default to 1
    page: Math.max(parseInt(page, 10) || 1, 1),
    pagelimit: Math.max(parseInt(pagelimit, 10) || 1, 1),
  };
};

const mapStateToProps = (state, props) => {
  const {
    JwtAuth: { csrftoken },
  } = state;
  const {
    location: { pathname, search },
  } = props;
  const queryParam = queryStringParse(search);
  const { page, pagelimit } = parseQueryParam(queryParam);
  const filters = _get(queryParam, 'tabStates.controlOwners.filters', {});

  const currentUserAttrs = currentUserSelector(state);
  const jurisdictionAttrs = currentUserJurisdictionSelector(state);

  const [request, status] = lastControlOwnersRequestAndStatusSelector(state);
  const { responseMeta } = request || {};

  const controlOwners = controlOwnersSelector(state);
  const gridRows = controlOwners.map((co) => ({
    templateName: 'controlOwner',
    controlOwner: co,
    ...retrieveRelationships(state, co),
  }));
  const custs = _uniqBy(
    gridRows.map((gr) => gr.cust),
    'id'
  );
  const firms = _uniqBy(
    gridRows.map((gr) => gr.firm),
    'id'
  );
  const jurisdictions = _uniqBy(
    gridRows.map((gr) => gr.jurisdiction),
    'id'
  );
  const users = _uniqBy(
    gridRows.map((gr) => gr.user),
    'id'
  );

  return {
    page,
    pathname,
    pagelimit,
    queryParam,
    filters,
    currentUserAttrs,
    jurisdictionAttrs,
    csrftoken,
    custs,
    firms,
    jurisdictions,
    users,
    gridRows,
    controlOwners,
    responseMeta,
    request,
    status,
  };
};

const mapDispatchToProps = {
  readControlOwners,
  createAlert,
  createReport,
  enableCurrentViewReport,
  disableCurrentViewReport,
};

export class ControlOwnersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingPrevious: false,
      isLoadingNext: false,
      generateReportModalInfo: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;

    if (!location.pathname.includes('jurisdictions')) {
      this.readControlOwners();
      this.resetState();
    }

    this.props.enableCurrentViewReport(this.showGenerateReportModal);
  }

  componentWillUnmount() {
    this.props.disableCurrentViewReport();
  }

  componentDidUpdate(prevProps) {
    const { page, location, controlOwners } = this.props;
    const { page: prevPage, location: prevLocation } = prevProps;

    if (
      (page !== prevPage || location !== prevLocation) &&
      (!controlOwners.length ||
        (!location.pathname.includes('jurisdictions') &&
          !prevLocation.pathname.includes('jurisdictions')))
    ) {
      this.readControlOwners();
      this.resetState();
    }
  }

  resetState = () => {
    this.setState({ isLoadingPrevious: false, isLoadingNext: false });
  };

  onCommitFilter = () => {
    this.resetState();
  };

  onLoadPrevious = () => {
    this.setState({ isLoadingPrevious: true, isLoadingNext: false });
  };

  onLoadNext = () => {
    this.setState({ isLoadingPrevious: false, isLoadingNext: true });
  };

  readControlOwners = (props = this.props) => {
    const { page, pagelimit, readControlOwners, filters } = props;

    this.readControlOwnersPromise = readControlOwners({
      ...filters,
      offset: (page - 1) * pagelimit,
      limit: pagelimit,
    });
  };

  isPending = (props = this.props) => {
    const { status } = props;
    return !!(status && status.pending);
  };

  isFailed = (props = this.props) => {
    const { status } = props;
    return !!(status && status.failed);
  };

  isSucceeded = (props = this.props) => {
    const { status } = props;
    return !!(status && status.succeeded);
  };

  rowCountInfo = (props = this.props) => {
    const { queryParam, page, pathname, pagelimit, responseMeta } = props;

    const totalCount = (responseMeta && responseMeta.total) || 0;

    if (totalCount > 0) {
      const firstRowNum = (page - 1) * pagelimit + 1;
      const lastRowNum = Math.min(totalCount, page * pagelimit);
      const previousLink =
        page > 1
          ? `${pathname}${queryStringStringify(
              _merge({}, queryParam, { page: page - 1 })
            )}`
          : '';
      const nextLink =
        totalCount - firstRowNum + 1 > pagelimit
          ? `${pathname}${queryStringStringify(
              _merge({}, queryParam, { page: page + 1 })
            )}`
          : '';

      return {
        onLoadPrevious: previousLink ? this.onLoadPrevious : undefined,
        onLoadNext: nextLink ? this.onLoadNext : undefined,
        totalCount,
        firstRowNum,
        lastRowNum,
        nextLink,
        previousLink,
      };
    }
    return {
      totalCount: 0,
      firstRowNum: 0,
      lastRowNum: 0,
      nextLink: '',
      previousLink: '',
    };
  };

  headerNode = (props = this.props) => {
    const { responseMeta } = props;

    return responseMeta ? (
      <ControlOwnersHeader headerData={responseMeta} />
    ) : this.isFailed() ? (
      <Error />
    ) : (
      <ControlOwnersHeaderLoading />
    );
  };

  pagingNode = (props = this.props, state = this.state) => {
    const rowCountInfo = this.rowCountInfo();
    const { isLoadingPrevious, isLoadingNext } = state;

    return rowCountInfo &&
      rowCountInfo.totalCount &&
      !(isLoadingPrevious || isLoadingNext) ? (
      <PagingFilterInfo {...rowCountInfo} />
    ) : (
      ''
    );
  };

  facetPanelNode = (props = this.props) => {
    const {
      custs,
      firms,
      jurisdictions,
      users,
      queryParam,
      pathname,
      filters,
    } = props;
    const countryResultRow = (c) => ({
      pillValue: c,
      country: c,
    });
    const custResultRows = custs.map((c) => ({
      pillValue: c.id,
      cust: c.id,
      custAcctName: c.attributes.name,
      status: c.attributes.active ? 'Active' : 'Inactive',
      region: c.attributes.region,
      country: c.attributes.country,
    }));
    const custCountryResultRows = _uniq(
      custs.map((c) => c.attributes.country)
    ).map(countryResultRow);

    const firmResultRows = firms.map((f) => ({
      pillValue: f.id,
      id: f.id,
      firmName: f.attributes.firmName,
    }));

    const userResultRows = users.map((u) => ({
      pillValue: u.id,
      uuid: u.id,
      userName: fullNameFromUserAttributes(u.attributes),
    }));
    const jurisdictionCountryResultRows = _uniq(
      jurisdictions
        .reduce((v, j) => {
          // eslint-disable-next-line
          const [regions, countries] = regionsAndCountriesForJurisdiction(j);
          v.push(countries);
          return v;
        }, [])
        .reduce((a, v) => a.concat(v), [])
    ).map(countryResultRow);
    return (
      <ControlOwnersFacetPanel
        onCommitFilter={this.onCommitFilter}
        queryParams={queryParam}
        appliedFilters={filters}
        pathname={pathname}
        custResultRows={custResultRows}
        custCountryResultRows={custCountryResultRows}
        firmResultRows={firmResultRows}
        userResultRows={userResultRows}
        jurisdictionCountryResultRows={jurisdictionCountryResultRows}
      />
    );
  };

  gridContentNode = (props = this.props, state = this.state) => {
    const { gridRows } = props;

    return this.isPending() ? (
      <ControlOwnersGridLoading />
    ) : gridRows && gridRows.length ? (
      <ControlOwnersGrid rows={gridRows} pagingControl={this.rowCountInfo()} />
    ) : this.isSucceeded() ? (
      <ControlOwnersGridNoResults />
    ) : this.isFailed() ? (
      <ControlOwnersGridError />
    ) : (
      <ControlOwnersGridLoading />
    );
  };

  showGenerateReportModal = (props = this.props) => {
    const { jurisdictionAttrs, currentUserAttrs } = props;
    const reportTitle = (
      <div>
        <FormattedMessage id="report.control_owners_report" />{' '}
        {new Date().toLocaleDateString('en-US')}
      </div>
    );
    this.setState({
      generateReportModalInfo: {
        reportTitle,
        isImpersonating: isImpersonating(jurisdictionAttrs),
        bbgEmail: currentUserAttrs.bbgEmail,
        corpEmail: currentUserAttrs.corpEmail,
      },
    });
  };

  onCancelGenerateReportModal = (props = this.props) => {
    this.setState({ generateReportModalInfo: null });
  };

  onGenerateReport = (props = this.props, state = this.state) => {
    const { filters = {}, createReport, createAlert, csrftoken } = props;
    Array.from([
      'filterNameOrId',
      'filterFirmId',
      'filterLicenseType',
      'filterUserRegion',
      'filterUserCountry',
      'filterJurisdictionRegion',
      'filterJurisdictionCountry',
      'filterStatus',
    ]).forEach((f) => {
      filters[f] && (filters[f] = assureArray(filters[f]));
    });
    filters.filterFirmId &&
      (filters.filterFirmId = filters.filterFirmId.map(parsedOrOrig));

    createReport(CONTROL_OWNERS_REPORT_TYPE, csrftoken, filters).then(
      (v) => {
        debug(v);
        createAlert(ALERT_TYPES.INFO, intl('report.report_delivered'), 5000);
      },
      (r) => {
        debug('REJECT', r);
        createAlert(ALERT_TYPES.ERROR, intl('report.error_generating_report'));
      }
    );

    this.setState({ generateReportModalInfo: null });
  };

  render() {
    const { filters = {} } = this.props;
    const { generateReportModalInfo } = this.state;
    return (
      <ControlOwnersApp
        headerNode={this.headerNode()}
        facetPanelNode={this.facetPanelNode()}
        pagingNode={this.pagingNode()}
        filtersApplied={Object.keys(filters).length}
        generateReportModalInfo={generateReportModalInfo}
        onCancelGenerateReportModal={this.onCancelGenerateReportModal}
        onGenerateReport={this.onGenerateReport}
      >
        {this.gridContentNode()}
        <Switch>
          <Route
            path="/controlOwners/jurisdictions/:uuid"
            component={ControlOwnersJurisdictionRouter}
          />
        </Switch>
      </ControlOwnersApp>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlOwnersContainer);
/* eslint-enable */
