/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
const FontIcon = ({ icon, disabled, onClick = () => {} }) => (
  <i
    className={`bb-font-icon bb-font-icon__${icon}${
      disabled ? ' disabled' : ''
    }`}
    onClick={(e) => onClick(e)}
  />
);
FontIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
export default FontIcon;
/* eslint-enable */
