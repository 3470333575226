/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import './styles/TabNav.css';
import { Link } from 'react-router-dom';

export const ROOT_LINK = '/';

const TabNav = ({
  linkPrefix,
  tabs,
  selectedTab,
  className = '',
  queryString = '',
}) => (
  <ul className={`tabNav${className ? ` ${className}` : className}`}>
    {Object.keys(tabs).map((key, i) => (
      <li key={i} className={key === selectedTab ? 'selected' : ''}>
        {ROOT_LINK === key ? (
          <Link to={`${linkPrefix}${queryString}`}>{tabs[key]}</Link>
        ) : (
          <Link to={`${linkPrefix}/${key}${queryString}`}>{tabs[key]}</Link>
        )}
      </li>
    ))}
  </ul>
);

TabNav.propTypes = {
  linkPrefix: PropTypes.string.isRequired,
  tabs: PropTypes.object.isRequired,
  selectedTab: PropTypes.string.isRequired,
  className: PropTypes.string,
  queryString: PropTypes.string,
};

export default TabNav;
/* eslint-enable */
