// External dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router';
import { ConnectedRouter } from 'react-router-redux';
import PropTypes from 'prop-types';
import {
  currentUserJurisdictionAndStatusSelector,
  currentUserAndStatusSelector,
} from './store/selectors';
import debugMod from 'debug';

import {
  readCurrentUser,
  readJurisdiction,
  readCurrentUserAndJurisdiction,
} from './store/apiActions';

// Components
import Loader from './components/Loader';
import { NetworkErrorModal } from './components/Modals';
import FooterBar from './components/FooterBar';

// Containers
import FirmContainer from './containers/FirmContainer';
import SuperUserScopeSearchContainer from './containers/SuperUserScopeSearchContainer';
import HeaderBarContainer from './containers/HeaderBarContainer';
import LoginScreenContainer from './containers/LoginScreenContainer';
import PrivateRouteContainer from './containers/PrivateRouteContainer';
import AlertsContainer from './containers/AlertsContainer';
import ControlOwnersContainer from './containers/ControlOwnersContainer';

// Stylesheets
import './styles/App.css';

// eslint-disable-next-line
const debug = debugMod('roster:RosterApp');

const mapStateToProps = (state) => {
  const {
    JwtAuth: { isInitialized, isAuthenticated, isServerError },
  } = state;

  const [currentUserAttrs, currentUserStatus] =
    currentUserAndStatusSelector(state);
  const [currentUserJurisdiction, jurisdictionStatus] =
    currentUserJurisdictionAndStatusSelector(state);
  const { firmId: currentUserFirm } = { ...currentUserAttrs };
  const {
    isInternalUser: isCurrentUserInternal,
    isSuperUser: isCurrentUserSuperUser,
  } = { ...currentUserJurisdiction };

  const isLoaded =
    isInitialized && // Non-0 Status received from server AND Error received from server
    (isServerError || // OR User and Jurisdiction requests complete
      !(currentUserStatus.pending && jurisdictionStatus.pending));
  return {
    currentUserFirm,
    isCurrentUserSuperUser,
    isCurrentUserInternal,
    isLoaded,
    isAuthenticated,
    isServerError,
  };
};

const mapDispatchToProps = {
  readCurrentUser,
  readJurisdiction,
  readCurrentUserAndJurisdiction,
};

export class RosterApp extends Component {
  componentDidMount() {
    this.props.readCurrentUserAndJurisdiction();
  }

  render() {
    const {
      currentUserFirm,
      isCurrentUserSuperUser,
      isCurrentUserInternal,
      isLoaded,
      isAuthenticated,
      isServerError,
      history,
    } = this.props;

    return isLoaded ? (
      <div className="bb-asset-vault">
        <ConnectedRouter history={history}>
          <div className="appWrapper">
            <Route component={HeaderBarContainer} />
            <AlertsContainer />
            <Switch>
              <Route path="/login" component={LoginScreenContainer} />
              {
                // It seems like a good idea to wrap this around a <div> or <React.Fragment>
                // BUT somehow doing so messes up react router redirect
                isCurrentUserInternal && isCurrentUserSuperUser ? (
                  <PrivateRouteContainer
                    exact
                    path="/"
                    component={SuperUserScopeSearchContainer}
                  />
                ) : (
                  <PrivateRouteContainer
                    exact
                    path="/"
                    component={() => (
                      <Redirect to={`/firm/${currentUserFirm}/roster`} />
                    )}
                  />
                )
              }
              {isCurrentUserSuperUser && (
                <PrivateRouteContainer
                  path="/controlOwners"
                  component={ControlOwnersContainer}
                />
              )}
              {!isAuthenticated ||
              (isCurrentUserInternal && isCurrentUserSuperUser) ? (
                <PrivateRouteContainer
                  path="/firm/:firmId([1-9][0-9]*)/:firmTab(roster)/:subFirmTab(admins|monitoredEmployees|jurisdiction)?"
                  component={FirmContainer}
                />
              ) : (
                <PrivateRouteContainer
                  path={`/firm/:firmId(${currentUserFirm})/:firmTab(roster)/:subFirmTab(admins|monitoredEmployees|jurisdiction)?`}
                  component={FirmContainer}
                />
              )}
              <Redirect to="/" />
            </Switch>
            {isServerError && <NetworkErrorModal />}
            <FooterBar />
          </div>
        </ConnectedRouter>
      </div>
    ) : (
      <div className="bb-asset-vault">
        <div className="appLoader row">
          <Loader />
        </div>
      </div>
    );
  }
}

RosterApp.propTypes = {
  currentUserFirm: PropTypes.number,
  isCurrentUserSuperUser: PropTypes.bool,
  isCurrentUserInternal: PropTypes.bool,
  isLoaded: PropTypes.bool.isRequired,
  isServerError: PropTypes.bool.isRequired,
  readCurrentUserAndJurisdiction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RosterApp);
