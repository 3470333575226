import React from 'react';
import PropTypes from 'prop-types';

import './styles/AttributeListCellTemplate.css';
import { formatIfNumeral, colorSpecialAttr } from '../../utils/FormatUtils';
import { Link } from 'react-router-dom';
import OverflowCellValueTooltip from './OverflowCellValueTooltip';

const listCellElement = (value, attribute) =>
  value.link ? (
    <Link to={value.link}>
      {value.numericAttributes
        ? formatIfNumeral(attribute.value)
        : attribute.value}
    </Link>
  ) : value.numericAttributes ? (
    formatIfNumeral(attribute.value)
  ) : (
    colorSpecialAttr(attribute.value)
  );

const AttributeListCellTemplate = ({ value, className }) => {
  return (
    <div
      className={
        'attributeListCellTemplate' +
        (value.isUserDeleted ? ' user-deleted' : '')
      }
    >
      <table>
        <tbody>
          {value.attributes.map((attribute, index) => (
            <tr key={index} className={className || ''}>
              {value.showAttributeKeys && (
                <td className="key">{attribute.key}</td>
              )}
              {undefined !== attribute.value && null !== attribute.value ? (
                attribute.canOverflow ? (
                  <OverflowCellValueTooltip
                    content={String(attribute.value).toUpperCase()}
                  >
                    {listCellElement(value, attribute)}
                  </OverflowCellValueTooltip>
                ) : (
                  <td className="value">{listCellElement(value, attribute)}</td>
                )
              ) : (
                <td className="value loading" />
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AttributeShape = PropTypes.shape({
  key: PropTypes.string,
  value: PropTypes.any.isRequired,
});

const AttributeListShape = PropTypes.shape({
  showAttributeKeys: PropTypes.bool.isRequired,
  attributes: PropTypes.arrayOf(AttributeShape).isRequired,
});

AttributeListCellTemplate.propTypes = {
  value: AttributeListShape.isRequired,
  className: PropTypes.string,
};
export const AttributeListCellTemplateLoading = ({
  attributesCount = 2,
  className,
}) => {
  return (
    <div className="attributeListCellTemplate loading">
      <table>
        <tbody>
          {[...Array(attributesCount)].map((attribute, index) => (
            <tr key={index} className={className || ''}>
              <td className="key" />
              <td className="value" />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
AttributeListCellTemplateLoading.propTypes = {
  attributesCount: PropTypes.number,
  className: PropTypes.string,
};

export default AttributeListCellTemplate;
