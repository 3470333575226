import { connect } from 'react-redux';

import PrivateRoute from '../components/PrivateRoute';

const mapStateToProps = (state) => ({
  isInitialized: state.JwtAuth.isInitialized,
  isAuthenticated: state.JwtAuth.isAuthenticated,
});

/* eslint-disable no-unused-vars */
const mapDispatchToProps = (dispatch) => ({});
/* eslint-enable no-unused-vars */

const PrivateRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);

export default PrivateRouteContainer;
