/* eslint-disable */
import React from 'react';
import numeral from 'numeral';
import { FormattedMessage } from 'react-intl';

export const formatIfNumeral = (str) => {
  if (isNaN(str)) return str; // Don't parse ratios, eg: 1:945
  const parsed = parseInt(str, 10);
  return isNaN(parsed) ? str : numeral(parsed).format();
};

export const parsedOrOrig = (str) => {
  const parsed = parseInt(str, 10);
  return isNaN(parsed) ? str : parsed;
};

export const assureArray = (val) =>
  val ? (Array.isArray(val) ? val : [val]) : val;

export const sortObjectOnValue = (obj) => {
  const sortable = [];
  for (const item in obj) {
    sortable.push([item, obj[item]]);
  }
  sortable.sort(function (a, b) {
    return b[1] - a[1];
  });

  const sorted = {};
  sortable.forEach((item) => {
    sorted[item[0]] = item[1];
  });
  return sorted;
};

const SPECIAL_ATTRIBUTES = ['Data Holds'];

export const colorSpecialAttr = (attribute) =>
  SPECIAL_ATTRIBUTES.includes(attribute) ? (
    <span className="orange">{attribute}</span>
  ) : (
    attribute
  );
/* eslint-enable */

// Format the "Showing x of y" message for internationalization
export const formatPaginationCount = (firstRowNum, lastRowNum, totalCount) => {
  const rowsShowingText = `${formatIfNumeral(firstRowNum)} - ${formatIfNumeral(
    lastRowNum
  )}`;
  return (
    <FormattedMessage id="control_owner.showing_$1_of_$2">
      {(message) =>
        String(message)
          .replace('$1', rowsShowingText)
          .replace('$2', formatIfNumeral(totalCount))
      }
    </FormattedMessage>
  );
};
