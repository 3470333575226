import React from 'react';
import PropTypes from 'prop-types';
import debugMod from 'debug';
import FauxtoCompleteFilter from '../filters/FauxtoCompleteFilter';
import RosterFacetPanel from '../RosterFacetPanel';
import { ADMIN_STATUS_RADIO_GROUP } from '../../utils/Enums';
import { CheckboxGroup } from '../filters/Checkbox';
import { addEventTracker } from '../../init/analytics';
import { intl } from '../../intl/IntlFormat';
import { FormattedMessage } from 'react-intl';
import RadioButtonGroup from '../filters/RadioButtonGroup';

// eslint-disable-next-line
const debug = debugMod('roster:ControlOwnersFacetPanel');

const CUST_HEADER_SPECS = [
  {
    col: 'custAcctName',
    displayName: intl('firm.cust_acct_name'),
    isSearchable: true,
  },
  {
    col: 'cust',
    displayName: intl('firm.cust_id'),
    isSearchable: true,
  },
];
const FIRM_HEADER_SPECS = [
  {
    col: 'firmName',
    displayName: intl('user.firm_name'),
    isSearchable: true,
  },
  {
    col: 'id',
    displayName: intl('user.firm_id'),
    isSearchable: true,
  },
];
const USER_HEADER_SPECS = [
  {
    col: 'userName',
    displayName: intl('user.name'),
    isSearchable: true,
  },
  {
    col: 'uuid',
    displayName: 'UUID',
    isSearchable: true,
  },
];
const COUNTRY_HEADER_SPECS = [
  {
    col: 'country',
    displayName: intl('control_owner.country'),
    isSearchable: true,
  },
];

export class ControlOwnersFacetPanel extends React.Component {
  render() {
    const {
      onCommitFilter = () => {},
      queryParams = {},
      appliedFilters = {},
      pathname = '',
      custResultRows = [],
      custCountryResultRows = [],
      firmResultRows = [],
      userResultRows = [],
      jurisdictionCountryResultRows = [],
    } = this.props;

    const ControlOwnersFacetSpec = {
      sections: [
        {
          label: intl('control_owner.firm'),
          collapsible: true,
          filters: [
            {
              label: intl('control_owner.find_firm'),
              filterKey: 'filterFirmId',
              defaultValue: [],
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <FormattedMessage id="control_owner.enter_firm_name_or_id">
                    {(message) => (
                      <FauxtoCompleteFilter
                        placeholder={message}
                        headerSpecs={FIRM_HEADER_SPECS}
                        resultRows={firmResultRows}
                        value={filterState}
                        onChange={addEventTracker(onFilterChange, {
                          category: 'controlOwnersFacet',
                          action: 'fauxtoFilterChange',
                          label: 'findFirm',
                        })}
                      />
                    )}
                  </FormattedMessage>
                ),
              },
            },
          ],
        },
        {
          label: intl('control_owner.user'),
          collapsible: true,
          filters: [
            {
              label: intl('control_owner.find_control_owner'),
              filterKey: 'filterNameOrId',
              defaultValue: [],
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <FormattedMessage id="control_owner.enter_name_or_uuid">
                    {(message) => (
                      <FauxtoCompleteFilter
                        placeholder={message}
                        headerSpecs={USER_HEADER_SPECS}
                        resultRows={userResultRows}
                        value={filterState}
                        onChange={addEventTracker(onFilterChange, {
                          category: 'controlOwnersFacet',
                          action: 'fauxtoFilterChange',
                          label: 'findControlOwner',
                        })}
                      />
                    )}
                  </FormattedMessage>
                ),
              },
            },
            {
              label: intl('control_owner.license_status'),
              filterKey: 'filterStatus',
              defaultValue: '',
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <RadioButtonGroup
                    items={ADMIN_STATUS_RADIO_GROUP.filter(
                      (s) => s.label !== intl('firm.status_all')
                    )}
                    currentValue={filterState}
                    onValueChange={addEventTracker(onFilterChange, {
                      category: 'controlOwnersFacet',
                      action: 'checkboxChange',
                      label: 'licenseStatus',
                    })}
                  />
                ),
              },
            },
            {
              label: intl('control_owner.license_type'),
              filterKey: 'filterLicenseType',
              defaultValue: [],
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <CheckboxGroup
                    name="filterLicenseType"
                    items={[
                      {
                        label: intl('control_owner.full_bloomberg_anywhere'),
                        value: 'Full Bloomberg Anywhere',
                      },
                      {
                        label: intl('control_owner.vault_limited_license'),
                        value: 'Vault Limited License',
                      },
                      {
                        label: intl('control_owner.vault_core_license'),
                        value: 'Vault Core License',
                      },
                      {
                        label: intl('control_owner.open_terminal'),
                        value: 'Open Terminal',
                      },
                      { label: intl('control_owner.other'), value: 'Other' },
                    ]}
                    currentValue={filterState}
                    onValueChange={addEventTracker(onFilterChange, {
                      category: 'controlOwnersFacet',
                      action: 'checkboxChange',
                      label: 'licenseType',
                    })}
                  />
                ),
              },
            },
          ],
        },
        {
          label: intl('control_owner.location'),
          collapsible: true,
          filters: [
            {
              label: intl('control_owner.cust_acct_membership'),
              filterKey: 'customerIds',
              defaultValue: [],
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <FormattedMessage id="control_owner.enter_cust_acct">
                    {(message) => (
                      <FauxtoCompleteFilter
                        placeholder={message}
                        headerSpecs={CUST_HEADER_SPECS}
                        resultRows={custResultRows}
                        value={filterState}
                        onChange={addEventTracker(onFilterChange, {
                          category: 'controlOwnersFacet',
                          action: 'fauxtoFilterChange',
                          label: 'custAcctMembership',
                        })}
                      />
                    )}
                  </FormattedMessage>
                ),
              },
            },
            {
              label: intl('control_owner.regions'),
              filterKey: 'filterUserRegion',
              defaultValue: [],
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <CheckboxGroup
                    name="filterUserRegion"
                    items={[
                      {
                        label: intl('control_owner.americas'),
                        value: 'Americas',
                      },
                      {
                        label: intl('control_owner.europe_africa'),
                        value: 'Europe/Africa',
                      },
                      {
                        label: intl('control_owner.asia_pac'),
                        value: 'Asia Pac',
                      },
                    ]}
                    currentValue={filterState}
                    onValueChange={addEventTracker(onFilterChange, {
                      category: 'controlOwnersFacet',
                      action: 'checkboxChange',
                      label: 'regions',
                    })}
                  />
                ),
              },
            },
            {
              label: intl('control_owner.country'),
              filterKey: 'filterUserCountry',
              defaultValue: [],
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <FormattedMessage id="control_owner.enter_country_names">
                    {(message) => (
                      <FauxtoCompleteFilter
                        placeholder={message}
                        headerSpecs={COUNTRY_HEADER_SPECS}
                        resultRows={custCountryResultRows}
                        value={filterState}
                        onChange={addEventTracker(onFilterChange, {
                          category: 'controlOwnersFacet',
                          action: 'fauxtoFilterChange',
                          label: 'country',
                        })}
                      />
                    )}
                  </FormattedMessage>
                ),
              },
            },
          ],
        },
        {
          label: intl('control_owner.bloomberg_membership_tiers'),
          collapsible: true,
          filters: [
            {
              label: intl('control_owner.cust_acct_membership'),
              filterKey: 'customerIds',
              defaultValue: [],
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <FormattedMessage id="control_owner.enter_cust_acct">
                    {(message) => (
                      <FauxtoCompleteFilter
                        placeholder={message}
                        headerSpecs={CUST_HEADER_SPECS}
                        resultRows={custResultRows}
                        value={filterState}
                        onChange={addEventTracker(onFilterChange, {
                          category: 'controlOwnersFacet',
                          action: 'fauxtoFilterChange',
                          label: 'bbMembershipTiers',
                        })}
                      />
                    )}
                  </FormattedMessage>
                ),
              },
            },
          ],
        },
        {
          label: intl('control_owner.jurisdiction_location'),
          collapsible: true,
          filters: [
            {
              label: intl('control_owner.regions'),
              filterKey: 'filterJurisdictionRegion',
              defaultValue: [],
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <CheckboxGroup
                    name="filterJurisdictionRegion"
                    items={[
                      {
                        label: intl('control_owner.americas'),
                        value: 'Americas',
                      },
                      {
                        label: intl('control_owner.europe_africa'),
                        value: 'Europe/Africa',
                      },
                      {
                        label: intl('control_owner.asia_pac'),
                        value: 'Asia Pac',
                      },
                    ]}
                    currentValue={filterState}
                    onValueChange={addEventTracker(onFilterChange, {
                      category: 'controlOwnersFacet',
                      action: 'checkboxChange',
                      label: 'jurisdictionLocation',
                    })}
                  />
                ),
              },
            },
            {
              label: intl('control_owner.country'),
              filterKey: 'filterJurisdictionCountry',
              defaultValue: [],
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <FormattedMessage id="control_owner.enter_country_names">
                    {(message) => (
                      <FauxtoCompleteFilter
                        placeholder={message}
                        headerSpecs={COUNTRY_HEADER_SPECS}
                        resultRows={jurisdictionCountryResultRows}
                        value={filterState}
                        onChange={addEventTracker(onFilterChange, {
                          category: 'controlOwnersFacet',
                          action: 'fauxtoFilterChange',
                          label: 'country',
                        })}
                      />
                    )}
                  </FormattedMessage>
                ),
              },
            },
          ],
        },
      ],
    };

    return (
      <RosterFacetPanel
        spec={ControlOwnersFacetSpec}
        appliedFilters={appliedFilters}
        pathname={pathname}
        tab="controlOwners"
        queryParams={queryParams}
        onCommitFilter={onCommitFilter}
        // queryString={queryString}

        // The key is necessary here to trigger a re-construct on appliedFilters
        // change. If we don't add this here it will cause trouble when switching
        // subtabs with subtab specific filter types set.
        // This is one of the officially recommended ways. Please see
        // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
        key={JSON.stringify(appliedFilters)}
      />
    );
  }
}
ControlOwnersFacetPanel.propTypes = {
  onCommitFilter: PropTypes.func.isRequired,
  queryParams: PropTypes.object.isRequired,
  appliedFilters: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  custResultRows: PropTypes.array,
  custCountryResultRows: PropTypes.array,
  firmResultRows: PropTypes.array,
  userResultRows: PropTypes.array,
  jurisdictionCountryResultRows: PropTypes.array,
};
