/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { getStatus, getResources } from 'redux-resource';

import {
  readFirm,
  readFirmFromCaid,
  readFirmFromCust,
  readUser,
  readUserFromCuid,
} from '../store/apiActions';
import { createAlert, ALERT_TYPES } from '../store/alertManager';
import { queryStringStringify } from '../utils/QueryStringParser';

import ScopeSearchPanel from '../components/ScopeSearchPanel';
import { intl } from '../intl/IntlFormat';
import { FormattedMessage } from 'react-intl';

const NUMERIC_INPUT_TEST = /^([1-9]\d*)?$/;

const SEARCH_CATEGORIES = {
  FIRM: 'FIRM',
  CAID: 'CAID',
  CUST: 'CUST',
  CUID: 'CUID',
  UUID: 'UUID',
};

const SEARCH_CATEGORY_CONFIG = {
  [SEARCH_CATEGORIES.FIRM]: {
    label: intl('firm.firms'),
    displayName: 'FIRM',
    getCustCaidInfo: () => {},
    resourceName: 'firms',
  },
  [SEARCH_CATEGORIES.CAID]: {
    label: intl('firm.firms'),
    displayName: 'CAID',
    getCustCaidInfo: (caid) => ({ caid }),
    resourceName: 'firmFromCaid',
  },
  [SEARCH_CATEGORIES.CUST]: {
    label: intl('firm.customer_accounts'),
    displayName: 'CUST',
    getCustCaidInfo: (cust) => ({ cust }),
    resourceName: 'firmFromCust',
  },
  [SEARCH_CATEGORIES.CUID]: {
    label: 'CUID',
    displayName: 'CUID',
    getCustCaidInfo: () => {},
    resourceName: 'usersFromCuid',
  },
  [SEARCH_CATEGORIES.UUID]: {
    label: intl('search.users'),
    displayName: 'UUID',
    getCustCaidInfo: () => {},
    resourceName: 'users',
  },
};

const mapStateToProps = (state, props) => {
  const { firms, firmFromCaid, firmFromCust, users, usersFromCuid } = state;

  return {
    firmLookupState: {
      firms,
      firmFromCaid,
      firmFromCust,
      users,
      usersFromCuid,
    },
  };
};

const mapDispatchToProps = {
  readFirm,
  readFirmFromCaid,
  readFirmFromCust,
  readUser,
  readUserFromCuid,
  createAlert,
};

export class SuperUserScopeSearchContainer extends React.Component {
  state = {
    searchInput: '',
  };

  componentDidUpdate() {
    const { clickInfo } = this.state;

    const { createAlert } = this.props;

    if (clickInfo) {
      const { category, id } = clickInfo;

      const lookupStatus = this.getFirmLookupStatus(category, id);
      const firmResource = this.getFirmLookupResource(category, id);

      if (!lookupStatus || lookupStatus.failed) {
        // If read status is failed or no lookup happened, unset click info and notify user
        this.clearClickInfo();

        createAlert(
          // Not translating this for now since this does not translate easily
          ALERT_TYPES.ERROR,
          `Failed to find firm id for ${category} ${id}`,
          5000
        );
      } else if (firmResource) {
        // If firm info is available, set redirect
        this.redirect(
          this.getFirmIdFromResource(firmResource, category, id),
          category,
          id
        );
      }
    }
  }

  isFreezeInteraction = () => {
    return !!this.state.clickInfo;
  };

  clearClickInfo = () => {
    this.setState({
      ...this.state,
      clickInfo: null,
    });
  };

  getRedirectUrl = (firmId, subtabOverride, category, id) => {
    subtabOverride = subtabOverride ? `/${subtabOverride}` : '';
    const custCaidInfo = SEARCH_CATEGORY_CONFIG[category].getCustCaidInfo(id);
    const userLevelSubtabOverride = subtabOverride || 'monitoredEmployees';

    switch (category) {
      case SEARCH_CATEGORIES.FIRM:
      case SEARCH_CATEGORIES.CAID:
      case SEARCH_CATEGORIES.CUST:
        return `/firm/${firmId}/roster${subtabOverride}${queryStringStringify(
          custCaidInfo
        )}`;
      case SEARCH_CATEGORIES.CUID:
        return `/firm/${firmId}/roster/${userLevelSubtabOverride}/userProfile/c/${id}`;
      case SEARCH_CATEGORIES.UUID:
        return `/firm/${firmId}/roster/${userLevelSubtabOverride}/userProfile/u/${id}`;
      default:
        return '';
    }
  };

  redirect = (firmId, category, id) => {
    const { subtabOverride, history, onClosePanel = () => {} } = this.props;

    history.push(this.getRedirectUrl(firmId, subtabOverride, category, id));
    this.clearClickInfo();
    onClosePanel();
  };

  getFirmLookupStatus = (category, id) => {
    const { firmLookupState } = this.props;

    return getStatus(
      firmLookupState,
      `${SEARCH_CATEGORY_CONFIG[category].resourceName}.meta.${id}.readStatus`,
      false
    );
  };

  getFirmLookupResource = (category, id) => {
    const { firmLookupState } = this.props;

    function retrieveResourceFromList(store, list) {
      const resources = getResources(store, list);
      return resources.length ? resources[0] : undefined;
    }
    switch (category) {
      case SEARCH_CATEGORIES.CAID:
        return retrieveResourceFromList(
          firmLookupState[SEARCH_CATEGORY_CONFIG[category].resourceName],
          `caid:${id}`
        );
      case SEARCH_CATEGORIES.CUST:
        return retrieveResourceFromList(
          firmLookupState[SEARCH_CATEGORY_CONFIG[category].resourceName],
          `cust:${id}`
        );
      default:
        return firmLookupState[SEARCH_CATEGORY_CONFIG[category].resourceName]
          .resources[id];
    }
  };

  getFirmIdFromResource = (resource, category, id) => {
    switch (category) {
      case SEARCH_CATEGORIES.FIRM:
      case SEARCH_CATEGORIES.CAID:
      case SEARCH_CATEGORIES.CUST:
        return resource.id;
      case SEARCH_CATEGORIES.CUID:
        return resource.attributes.firmId;
      case SEARCH_CATEGORIES.UUID:
        return resource.attributes.firmId;
      default:
        return -1;
    }
  };

  dispatchFirmLookup = (category, id) => {
    const {
      readFirm,
      readFirmFromCaid,
      readFirmFromCust,
      readUser,
      readUserFromCuid,
    } = this.props;

    if (SEARCH_CATEGORIES.FIRM === category) {
      readFirm({ firmId: id });
    } else if (SEARCH_CATEGORIES.CAID === category) {
      readFirmFromCaid(id);
    } else if (SEARCH_CATEGORIES.CUST === category) {
      readFirmFromCust(id);
    } else if (SEARCH_CATEGORIES.CUID === category) {
      readUserFromCuid({ cuid: id });
    } else if (SEARCH_CATEGORIES.UUID === category) {
      readUser(id);
    }
  };

  onSearchInputChange = (newValue) => {
    if (this.isFreezeInteraction()) {
      return;
    }

    if (NUMERIC_INPUT_TEST.test(newValue)) {
      this.setState({
        ...this.state,
        searchInput: newValue,
      });
    }
  };

  onClickResult = (event, clickInfo) => {
    event && event.preventDefault();

    if (this.isFreezeInteraction()) {
      return;
    }

    const { category, id } = clickInfo;

    // If resource is available, redirect
    const firmResource = this.getFirmLookupResource(category, id);
    if (firmResource) {
      this.redirect(
        this.getFirmIdFromResource(firmResource, category, id),
        category,
        id
      );
    } else {
      // else, set clickInfo and perform lookup
      this.dispatchFirmLookup(category, id);

      this.setState({
        ...this.state,
        clickInfo,
      });
    }
  };

  searchResults() {
    const { searchCategories = SEARCH_CATEGORIES } = this.props;

    const { searchInput } = this.state;

    if (searchInput === '') {
      return [];
    }

    const inputId = parseInt(searchInput, 10);

    return Object.values(searchCategories).map((category) => ({
      sectionName: SEARCH_CATEGORY_CONFIG[category].label,
      totalCount: 1,
      results: [
        {
          id: inputId,
          linkTo: '',
          displayName: `${SEARCH_CATEGORY_CONFIG[category].displayName} ${inputId}`,
          clickInfo: {
            category,
            id: inputId,
          },
          meta: '',
        },
      ],
    }));
  }

  render() {
    const {
      instructionText,
      resetLink,
      showInstructionPanel,
      onClosePanel,
    } = this.props;

    return (
      <FormattedMessage id="search.numeric_characters_tip">
        {(message) => (
          <ScopeSearchPanel
            placeholder={message}
            inputValue={this.state.searchInput}
            searchResults={this.searchResults()}
            showInstructionPanel={showInstructionPanel}
            instructionText={instructionText}
            resetLink={resetLink}
            onSearchInputChange={this.onSearchInputChange}
            onClickResult={this.onClickResult}
            onClosePanel={onClosePanel}
          />
        )}
      </FormattedMessage>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperUserScopeSearchContainer);
/* eslint-enable */
