/* eslint-disable */
import { resourceReducer } from 'redux-resource';
import { httpStatusCodes, includedResources } from 'redux-resource-plugins';
import { merge } from 'lodash';

import debugMod from 'debug';
import { heterogeneousIncludedResources } from './stateMaterializers';
import { jwtCrudRequest, API_HOST } from '../utils/JwtAuth';
import { queryStringStringify } from '../utils/QueryStringParser';
import { parsedOrOrig, assureArray } from '../utils/FormatUtils';
import { ROSTER_TABS } from '../utils/Enums';
import { CONTROL_OWNERS_REPORT_TYPE } from '../containers/ControlOwnersContainer';
import { SURVEILLANCE_REPORT_TYPE } from '../containers/ReportsManagerContainer';

const debug = debugMod('roster:apiActions');

export { API_HOST };

const API_URL_PREFIX = `${API_HOST}/bvault`;
// const API_URL_PREFIX = "http://gb-mesosmaster-shared-nj1.bdns.bloomberg.com:20406/bvault"
const FIRMS_URL_PREFIX = `${API_URL_PREFIX}/firms/`;
const FIRM_FROM_CAID_URL_PREFIX = `${API_URL_PREFIX}/firms?caid=`;
const FIRM_FROM_CUST_URL_PREFIX = `${API_URL_PREFIX}/firms?cust=`;
const CONTRACTS_URL_PREFIX = `${API_URL_PREFIX}/contracts?firmId=`;
const USERS_URL_PREFIX = `${API_URL_PREFIX}/users/`;
const USERS_FROM_CUID_URL_PREFIX = `${API_URL_PREFIX}/users?cuids=`;
const JURISDICTIONS_URL_PREFIX = `${API_URL_PREFIX}/jurisdictions`;
const CONTROL_OWNERS_URL_PREFIX = `${API_URL_PREFIX}/controlOwners`;
const ADMIN_UI_URL_PREFIX = `${API_URL_PREFIX}/adminui`;
const ADMIN_ROSTER_URL_PREFIX = `${ADMIN_UI_URL_PREFIX}/roster/admins`;
const MONITORED_EMPLOYEES_ROSTER_URL_PREFIX = `${ADMIN_UI_URL_PREFIX}/roster/monitoredEmployees`;
const MONITORED_BY_URL_PREFIX = `${ADMIN_UI_URL_PREFIX}/roster/memberDetails/monitoredBy`;
const REPORTS_URL_PREFIX = `${ADMIN_UI_URL_PREFIX}/roster/reports`;

const headers = {
  // "X-User-Agent": 'redux-resource-xhr',
  // 'Accept': 'application/json',
  // credentials: 'include'
};

const DEFAULT_ID_OVERRIDE = (resource) => resource.data.id;

export const ResourceTransformers = {
  idInjection: (idOverride = DEFAULT_ID_OVERRIDE) => (resource) => ({
    ...resource,
    id: typeof idOverride === 'function' ? idOverride(resource) : idOverride,
  }),
  singleResource: (idOverride) => (resource) => [
    ResourceTransformers.idInjection(idOverride)(resource),
  ],
  multiResources: (idOverride) => (resources) =>
    resources.data.map(ResourceTransformers.idInjection(idOverride)),
};

export function relativeUrl(url) {
  if (url && url.length > 1 && url[0] === '/') {
    const result = API_HOST + url;
    debug('relativeUrl(', url, result);
    return result;
  }
  return url;
}

/// /////////////////
// Firms Resource //
/// /////////////////

export const firmsReducer = resourceReducer('firms', {
  plugins: [httpStatusCodes, includedResources],
});
export function readFirm({ firmId, firmUrl, eagerContracts = false }) {
  firmUrl = firmUrl || `${FIRMS_URL_PREFIX}${firmId}`;

  const xhrOptions = {
    method: 'GET',
    url: firmUrl,
    json: true,
    headers,
  };

  debug('readFirm(', firmId, firmUrl);
  return (dispatch) =>
    new Promise((resolve, reject) => {
      jwtCrudRequest('read', {
        actionDefaults: {
          requestKey: 'readFirm',
          resourceType: 'firms',
          resources: [firmId],
        },
        transformData: (resource) => resource.data,
        xhrOptions,
        dispatch,
      }).then((res) => {
        if (eagerContracts) {
          const relatedLink = relativeUrl(
            res[0].relationships.contracts.links.related
          );
          if (relatedLink) {
            dispatch(readContract(firmId, relatedLink)).then((dispatchResult) =>
              resolve(dispatchResult)
            );
          } else {
            resolve(res);
          }
        } else {
          resolve(res);
        }
      });
    });
}

/// /////////////////////
// Contracts Resource //
/// /////////////////////

export const contractsReducer = resourceReducer('contracts', {
  plugins: [httpStatusCodes],
});
export function readContract(firmId, contractUrl) {
  contractUrl = contractUrl || `${CONTRACTS_URL_PREFIX}${firmId}`;

  debug('readContract(', firmId, contractUrl);
  const xhrOptions = {
    method: 'GET',
    url: contractUrl,
    json: true,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readContract',
        resourceType: 'contracts',
        resources: [firmId],
      },
      transformData: ResourceTransformers.singleResource(firmId),
      xhrOptions,
      dispatch,
    });
}

/// /////////////////
// Users Resource //
/// /////////////////

export const usersReducer = resourceReducer('users', {
  plugins: [httpStatusCodes, includedResources],
});
export function readCurrentUser() {
  const uuid = 'currentUser';
  const userUrl = `${USERS_URL_PREFIX}currentUser`;

  const xhrOptions = {
    method: 'GET',
    url: userUrl,
    json: true,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readUser',
        resourceType: 'users',
        resources: [uuid],
      },
      transformData: (resource) => {
        const uuid = resource.data.id;
        return [
          ResourceTransformers.idInjection('currentUser')(resource.data),
          ResourceTransformers.idInjection(uuid)(resource.data),
        ];
      },
      xhrOptions,
      dispatch,
    });
}

export function readUser(uuid, userUrl) {
  userUrl = userUrl || `${USERS_URL_PREFIX}${uuid}`;

  const xhrOptions = {
    method: 'GET',
    url: userUrl,
    json: true,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readUser',
        resourceType: 'users',
        resources: [uuid],
      },
      transformData: (resource) => [resource.data],
      xhrOptions,
      dispatch,
    });
}

/// /////////////////////////
// Jurisdictions Resource //
/// /////////////////////////

export const jurisdictionsReducer = resourceReducer('jurisdictions', {
  plugins: [httpStatusCodes, includedResources],
});
export function readJurisdiction(uuid, jurisdictionUrl) {
  jurisdictionUrl =
    jurisdictionUrl ||
    `${JURISDICTIONS_URL_PREFIX}${queryStringStringify({ uuids: [uuid] })}`;
  const xhrOptions = {
    method: 'GET',
    url: jurisdictionUrl,
    json: true,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readJurisdiction',
        resourceType: 'jurisdictions',
        resources: [uuid],
      },
      transformData: ResourceTransformers.multiResources(uuid),
      xhrOptions,
      dispatch,
      onSucceeded: (action, res, body) => {
        dispatch({
          ...action,
          includedResources: { custs: body.included },
        });
      },
    });
}

export function readCurrentUserAndJurisdiction() {
  return (dispatch) =>
    dispatch(readCurrentUser()).then((v) => {
      return dispatch(readJurisdiction(v.id));
    });
}

/// ////////////////////////
// ControlOwner Resource //
/// ////////////////////////

export const controlOwnersReducer = resourceReducer('controlOwners', {
  plugins: [httpStatusCodes, includedResources],
});
export function readControlOwners({
  controlOwnersUrl,
  offset = 0,
  limit = 300,
  ...filters
}) {
  controlOwnersUrl =
    controlOwnersUrl ||
    `${CONTROL_OWNERS_URL_PREFIX}${queryStringStringify({
      offset,
      limit,
      ...filters,
    })}`;
  const xhrOptions = {
    method: 'GET',
    url: controlOwnersUrl,
    json: true,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readControlOwners',
        resourceType: 'controlOwners',
      },
      transformData: (resource) => resource.data,
      xhrOptions,
      dispatch,
      onSucceeded: (action, res, body) =>
        heterogeneousIncludedResources(dispatch, action, body),
      /* onSucceeded: (action, res, body) => {
        const resources = (body.included || []).reduce((m, o) => {
          const k = get(o, 'type', 'UNKNOWN') + "s";
          (m[k] = m[k] || []).push(o)
          return m
        }, {})
        dispatch({
          ...action,
          requestProperties: {responseMeta: body.meta},
          includedResources: resources
        })
      } */
    });
}

/// ///////////////////
// Admin UI Service //
/// ///////////////////

export const adminRosterReducer = resourceReducer('adminRoster', {
  plugins: [httpStatusCodes, includedResources],
});
export function readAdminRosterRequestKey({
  firmId,
  offset = 0,
  limit = 300,
  filterNameOrId,
  filterStatus,
  filterApplicationStatus,
  filterRoles,
  customerIds,
  caids,
}) {
  return btoa(
    JSON.stringify({
      firmId,
      offset,
      limit,
      filterNameOrId,
      filterStatus,
      filterApplicationStatus,
      filterRoles,
      customerIds,
      caids,
    })
  );
}
export function readAdminRoster({
  firmId,
  adminRosterUrl,
  offset = 0,
  limit = 300,
  filterNameOrId,
  filterStatus,
  filterApplicationStatus,
  filterRoles,
  customerIds,
  caids,
}) {
  const requestKey = readAdminRosterRequestKey({
    firmId,
    offset,
    limit,
    filterNameOrId,
    filterStatus,
    filterApplicationStatus,
    filterRoles,
    customerIds,
    caids,
  });

  adminRosterUrl =
    adminRosterUrl ||
    ADMIN_ROSTER_URL_PREFIX +
      queryStringStringify({
        firmId,
        offset,
        limit,
        filterNameOrId,
        filterStatus,
        filterApplicationStatus,
        filterRoles,
        customerIds,
        caids,
      });

  const xhrOptions = {
    method: 'GET',
    url: adminRosterUrl,
    json: true,
    headers,
  };

  debug('readAdminRoster(', firmId, adminRosterUrl);
  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey,
        resourceType: 'adminRoster',
        resources: [requestKey],
      },
      transformData: ResourceTransformers.singleResource(requestKey),
      xhrOptions,
      dispatch,
      // 'user' response included with adminRoster is incomplete, in that it does not contain jurisdiction, so exclude it.
      onSucceeded: (action, res, body) =>
        heterogeneousIncludedResources(dispatch, action, body, ['users']),
    });
}

export const monitoredEmployeesRosterReducer = resourceReducer(
  'monitoredEmployeesRoster',
  { plugins: [httpStatusCodes] }
);
export function readMonitoredEmployeesRosterRequestKey({
  firmId,
  offset = 0,
  limit = 300,
  filterNameOrId,
  filterApplicationStatus,
  filterStatus,
  filterRoles,
  customerIds,
  caids,
}) {
  return btoa(
    JSON.stringify({
      firmId,
      offset,
      limit,
      filterNameOrId,
      filterApplicationStatus,
      filterStatus,
      filterRoles,
      customerIds,
      caids,
    })
  );
}
export function readMonitoredEmployeesRoster({
  firmId,
  meRosterUrl,
  offset = 0,
  limit = 300,
  filterNameOrId,
  filterApplicationStatus,
  filterStatus,
  filterRoles,
  customerIds,
  caids,
}) {
  const requestKey = readMonitoredEmployeesRosterRequestKey({
    firmId,
    offset,
    limit,
    filterNameOrId,
    filterStatus,
    filterApplicationStatus,
    filterRoles,
    customerIds,
    caids,
  });

  meRosterUrl =
    meRosterUrl ||
    MONITORED_EMPLOYEES_ROSTER_URL_PREFIX +
      queryStringStringify({
        firmId,
        offset,
        limit,
        filterNameOrId,
        filterApplicationStatus,
        filterStatus,
        filterRoles,
        customerIds,
        caids,
      });

  const xhrOptions = {
    method: 'GET',
    url: meRosterUrl,
    json: true,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey,
        resourceType: 'monitoredEmployeesRoster',
        resources: [requestKey],
      },
      transformData: ResourceTransformers.singleResource(requestKey),
      xhrOptions,
      dispatch,
    });
}

/// /////////////////
// Firm From CAID //
/// /////////////////

export const firmFromCaidReducer = resourceReducer('firmFromCaid', {
  plugins: [httpStatusCodes],
});
export function readFirmFromCaid(caid, firmFromCaidUrl) {
  firmFromCaidUrl = firmFromCaidUrl || `${FIRM_FROM_CAID_URL_PREFIX}${caid}`;

  const xhrOptions = {
    method: 'GET',
    url: firmFromCaidUrl,
    json: true,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readFirmFromCaid',
        resourceType: 'firmFromCaid',
        list: `caid:${caid}`,
      },
      transformData: (resource) => resource.data,
      xhrOptions,
      dispatch,
    });
}

/// /////////////////
// Firm From CUST //
/// /////////////////

export const firmFromCustReducer = resourceReducer('firmFromCust', {
  plugins: [httpStatusCodes],
});
export function readFirmFromCust(cust, firmFromCustUrl) {
  firmFromCustUrl = firmFromCustUrl || `${FIRM_FROM_CUST_URL_PREFIX}${cust}`;

  const xhrOptions = {
    method: 'GET',
    url: firmFromCustUrl,
    json: true,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readFirmFromCust',
        resourceType: 'firmFromCust',
        list: `cust:${cust}`,
      },
      transformData: (resource) => resource.data,
      xhrOptions,
      dispatch,
    });
}

/// /////////////////
// User From CUID //
/// /////////////////

export const usersFromCuidReducer = resourceReducer('usersFromCuid', {
  plugins: [httpStatusCodes],
});
export function readUserFromCuid({ cuid, userFromCuidUrl }) {
  userFromCuidUrl = userFromCuidUrl || `${USERS_FROM_CUID_URL_PREFIX}${cuid}`;

  const xhrOptions = {
    method: 'GET',
    url: userFromCuidUrl,
    json: true,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readUserFromCuid',
        resourceType: 'usersFromCuid',
        resources: [cuid],
      },
      transformData: ResourceTransformers.multiResources(
        (user) => user.attributes.cuid
      ),
      xhrOptions,
      dispatch,
      onSucceeded: (action, res, body) => {
        if (!body.data || body.data.length === 0) {
          dispatch(
            merge({}, action, {
              resources: [cuid],
              requestProperties: { statusCode: 404 },
              res: { statusCode: 404 },
              type: 'READ_RESOURCES_FAILED',
            })
          );
        } else {
          dispatch(action);
        }
      },
    });
}

/// /////////////////////////
// Monitored By From UUID //
/// /////////////////////////
export const monitoredByFromUuidReducer = resourceReducer(
  'monitoredByFromUuid',
  { plugins: [httpStatusCodes] }
);
export function readMonitoredByFromUuid({
  firmId,
  uuid,
  monitoredByFromUuidUrl,
}) {
  monitoredByFromUuidUrl =
    monitoredByFromUuidUrl ||
    `${MONITORED_BY_URL_PREFIX}${queryStringStringify({ firmId, uuid })}`;

  const xhrOptions = {
    method: 'GET',
    url: monitoredByFromUuidUrl,
    json: true,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readMonitoredByFromUuid',
        resourceType: 'monitoredByFromUuid',
        resources: [uuid],
      },
      transformData: ResourceTransformers.singleResource(uuid),
      xhrOptions,
      dispatch,
    });
}

/// /////////////////////////
// Monitored By From CUID //
/// /////////////////////////
export const monitoredByFromCuidReducer = resourceReducer(
  'monitoredByFromCuid',
  { plugins: [httpStatusCodes] }
);
export function readMonitoredByFromCuid({
  firmId,
  cuid,
  monitoredByFromCuidUrl,
}) {
  monitoredByFromCuidUrl =
    monitoredByFromCuidUrl ||
    `${MONITORED_BY_URL_PREFIX}${queryStringStringify({ firmId, cuid })}`;

  const xhrOptions = {
    method: 'GET',
    url: monitoredByFromCuidUrl,
    json: true,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readMonitoredByFromCuid',
        resourceType: 'monitoredByFromCuid',
        resources: [cuid],
      },
      transformData: ResourceTransformers.singleResource(cuid),
      xhrOptions,
      dispatch,
    });
}

/// ///////////////////////
// Monitoring From UUID //
/// ///////////////////////
const MONITORING_RESOURCE_TRANSFORMER = (idType, id) => (resource) => {
  const resourceData = resource.data;
  let adminData;
  for (let ii = 0; ii < resourceData.length; ++ii) {
    const data = resourceData[ii];
    if (data.attributes[idType] === id) {
      adminData = data;
      break;
    }
  }
  return [
    {
      id,
      data: adminData,
    },
  ];
};

export const adminMonitoredEmployeesReducer = resourceReducer(
  'adminMonitoredEmployees',
  { plugins: [httpStatusCodes, includedResources] }
);
export const monitoringFromUuidReducer = resourceReducer('monitoringFromUuid', {
  plugins: [httpStatusCodes, includedResources],
});
export function readMonitoringFromUuid({
  firmId,
  uuid,
  monitoringFromUuidUrl,
  offset = 0,
  limit = 50000,
}) {
  monitoringFromUuidUrl =
    monitoringFromUuidUrl ||
    `${ADMIN_ROSTER_URL_PREFIX}/${uuid}${queryStringStringify({
      firmId,
      offset,
      limit,
    })}`;

  const xhrOptions = {
    method: 'GET',
    url: monitoringFromUuidUrl,
    json: true,
    headers,
  };

  debug('readMonitoringFromUuid(', firmId, uuid, monitoringFromUuidUrl);
  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readMonitoringFromUuid',
        resourceType: 'monitoringFromUuid',
        resources: [uuid],
      },
      transformData: MONITORING_RESOURCE_TRANSFORMER('uuid', uuid),
      xhrOptions,
      dispatch,
      onSucceeded: (action, res, body) =>
        heterogeneousIncludedResources(dispatch, action, body, ['users']),
    });
}

/// ///////////////////////
// Monitoring From CUID //
/// ///////////////////////
export const monitoringFromCuidReducer = resourceReducer('monitoringFromCuid', {
  plugin: [httpStatusCodes],
});
export function readMonitoringFromCuid({
  firmId,
  cuid,
  monitoringFromCuidUrl,
}) {
  monitoringFromCuidUrl =
    monitoringFromCuidUrl ||
    `${ADMIN_ROSTER_URL_PREFIX}${queryStringStringify({
      firmId,
      filterNameOrId: cuid,
    })}`;

  const xhrOptions = {
    method: 'GET',
    url: monitoringFromCuidUrl,
    json: true,
    headers,
  };

  debug('readMonitoringFromCuid(', firmId, cuid, monitoringFromCuidUrl);
  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults: {
        requestKey: 'readMonitoringFromCuid',
        resourceType: 'monitoringFromCuid',
        resources: [cuid],
      },
      transformData: MONITORING_RESOURCE_TRANSFORMER('cuid', cuid),
      xhrOptions,
      dispatch,
      onSucceeded: (action, res, body) =>
        heterogeneousIncludedResources(dispatch, action, body, ['users']),
    });
}

/// /////////////////
// Custs Resource //
/// /////////////////

const CUSTS_API_PREFIX = `${API_URL_PREFIX}/custs`;
export const custsReducer = resourceReducer('custs', {
  plugins: [httpStatusCodes, includedResources],
});
export function readCusts({ firmId, custs, offset, limit, active, custsUrl }) {
  custsUrl =
    custsUrl ||
    `${CUSTS_API_PREFIX}${queryStringStringify({
      firmId,
      cust: custs,
      offset,
      limit,
      active,
    })}`;
  const xhrOptions = {
    method: 'GET',
    url: custsUrl,
    json: true,
    headers,
  };
  const actionDefaults = {
    requestKey: 'readCusts',
    resourceType: 'custs',
  };
  if (!custs || custs.length === 0) {
    actionDefaults.list = `firm-${firmId}`;
  }

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults,
      transformData: (body) => body.data,
      xhrOptions,
      dispatch,
    });
}

/// /////////////////
// CAIDs Resource //
/// /////////////////

const CAIDS_API_PREFIX = `${API_URL_PREFIX}/caids`;
export const caidsReducer = resourceReducer('caids', {
  plugins: [httpStatusCodes],
});
export function readCaids({ firmId, caids, offset, limit, active, caidsUrl }) {
  caidsUrl =
    caidsUrl ||
    `${CAIDS_API_PREFIX}${queryStringStringify({
      firmId,
      caid: caids,
      offset,
      limit,
      active,
    })}`;

  const xhrOptions = {
    method: 'GET',
    url: caidsUrl,
    json: true,
    headers,
  };
  const actionDefaults = {
    requestKey: 'readCusts',
    resourceType: 'custs',
  };
  if (!caids || caids.length === 0) {
    actionDefaults.list = `firm-${firmId}`;
  }

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults,
      transformData: (body) => body.data,
      xhrOptions,
      dispatch,
    });
}

/// ////////////////
// LEIs Resource //
/// ////////////////

const LEIS_API_PREFIX = `${API_URL_PREFIX}/leis`;
export const leisReducer = resourceReducer('leis', {
  plugins: [httpStatusCodes, includedResources],
});
export function readLeis({
  firmId,
  caids,
  custs,
  tradeEnvType,
  offset,
  limit,
  caidsUrl: leisUrl,
}) {
  leisUrl =
    leisUrl ||
    `${LEIS_API_PREFIX}${queryStringStringify({
      firmId,
      caids,
      custs,
      tradeEnvType,
      offset,
      limit,
    })}`;

  const xhrOptions = {
    method: 'GET',
    url: leisUrl,
    json: true,
    headers,
  };
  const actionDefaults = {
    requestKey: 'readLeis',
    resourceType: 'leis',
  };

  return (dispatch) =>
    jwtCrudRequest('read', {
      actionDefaults,
      transformData: (body) => body.data,
      xhrOptions,
      dispatch,
    });
}

/// ////////////
// Reporting //
/// ////////////

const REPORT_PATHS = {
  [ROSTER_TABS.ADMINS]: 'adminReports',
  [ROSTER_TABS.MONITORED_EMPLOYEES]: 'monitoredEmployeesReports',
  [ROSTER_TABS.JURISDICTION]: 'jurisdictionReports',
  adminMonitoring: 'adminMonitoringReports',
  [CONTROL_OWNERS_REPORT_TYPE]: 'controlOwnerReports',
  [SURVEILLANCE_REPORT_TYPE]: 'monitoredEmployeesReports/surveillance',
};
export function createReport(reportType, csrftoken, filters) {
  const adminReportsUrl = `${REPORTS_URL_PREFIX}/${REPORT_PATHS[reportType]}?csrfToken=${csrftoken}`;

  filters.firmId && (filters.firmId = parsedOrOrig(filters.firmId));
  filters.customerIds &&
    (filters.customerIds = assureArray(filters.customerIds).map(parsedOrOrig));
  filters.caids &&
    (filters.caids = assureArray(filters.caids).map(parsedOrOrig));
  filters.uuid && (filters.uuid = parsedOrOrig(filters.uuid));
  filters.adminUuid && (filters.adminUuid = parsedOrOrig(filters.adminUuid));

  const xhrOptions = {
    method: 'POST',
    url: adminReportsUrl,
    json: true,
    contentType: 'application/json',
    body: filters,
    headers,
  };

  return (dispatch) =>
    jwtCrudRequest('create', {
      actionDefaults: {
        requestKey: 'createAdminReport',
        resourceType: 'report',
      },
      xhrOptions,
      dispatch,
    });
}
/* eslint-enable */
