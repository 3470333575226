/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import FontIcon from './FontIcon';
import { intl } from '../intl/IntlFormat';

import './styles/AdminAppStatusIcon.css';

const ADMIN_APP_STATUS_TYPES = {
  MONITORING: 'Monitoring',
  NOT_MONITORING: 'NotMonitoring',
  SELF_MONITORING: 'SelfMonitoring',
  NOT_ADMIN: 'NotAdmin',
};

const STATUS_INDICATOR_MAP = {
  // Admin Tab Statuses
  [ADMIN_APP_STATUS_TYPES.MONITORING]: {
    icon: 'ok',
    className: 'monitoring',
    label: intl('firm.enabled_and_configured'),
  },
  [ADMIN_APP_STATUS_TYPES.NOT_MONITORING]: {
    svgIcon: (
      <svg height="10" width="10">
        <path d="M5 0 L0 5 L5 10 L10 5 Z" stroke="yellow" fill="yellow" />
      </svg>
    ),
    className: 'notMonitoring',
    label: intl('firm.enabled_and_not_configured'),
  },
  [ADMIN_APP_STATUS_TYPES.SELF_MONITORING]: {
    icon: 'circle-alert',
    className: 'selfMonitoring',
    label: intl('firm.self_monitoring'),
  },
  [ADMIN_APP_STATUS_TYPES.NOT_ADMIN]: {
    icon: 'circle_solid',
    className: 'notAdmin',
    label: intl('firm.not_enabled'),
  },
};

const AdminAppStatusIcon = ({ status, displayLabel = false }) => (
  <span
    className={`adminAppStatusIcon ${STATUS_INDICATOR_MAP[status].className}`}
  >
    {STATUS_INDICATOR_MAP[status].icon && (
      <FontIcon icon={STATUS_INDICATOR_MAP[status].icon} />
    )}
    {STATUS_INDICATOR_MAP[status].svgIcon && (
      <span className="svgIcon">{STATUS_INDICATOR_MAP[status].svgIcon}</span>
    )}
    {displayLabel && (
      <span className="adminAppStatusLabel">
        {STATUS_INDICATOR_MAP[status].label}
      </span>
    )}
  </span>
);

AdminAppStatusIcon.propTypes = {
  status: PropTypes.oneOf(Object.values(ADMIN_APP_STATUS_TYPES)).isRequired,
  displayLabel: PropTypes.bool,
};

export default AdminAppStatusIcon;
export { ADMIN_APP_STATUS_TYPES };
/* eslint-enable */
