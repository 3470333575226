import React from 'react';
import { SlideOut } from '../../components/SlideOut';
import { connect } from 'react-redux';
import { getStatus } from 'redux-resource';
import { chunk } from 'lodash';
import { readCusts, readJurisdiction, readUser } from '../../store/apiActions';
import AttributeListCellTemplate, {
  AttributeListCellTemplateLoading,
} from '../grid/AttributeListCellTemplate';
import Grid, { GridLoading } from '../grid/Grid';
import {
  expandCountryCode,
  jurisdictionDescriptionDatum,
} from '../../utils/JurisdictionUtils';
import { getResources } from 'redux-resource';
import { queryStringParse } from '../../utils/QueryStringParser';
import { ROSTER_TABS } from '../../utils/Enums';
import {
  uuidJurisdictionSelector,
  uuidUserSelector,
} from '../../store/selectors';
import {
  DatumHero,
  DatumHeroLoading,
  ExtendedDatumValueWithOverflow,
} from '../../components/Datum';
import { Link } from 'react-router-dom';
import ReportsManagerContainer from '../../containers/ReportsManagerContainer';
import FontIcon from '../FontIcon';
import './styles/ControlOwnerJurisdictionApp.css';
import { FormattedMessage } from 'react-intl';
import { intl } from '../../intl/IntlFormat';

const JURISDICTION_PAGE_LIMIT = 2000;

const LOADING_ROW_COUNT = 30;

const closeLink = '/controlOwners/';

const mapDispatchToProps = { readCusts, readJurisdiction, readUser };

const mapStateToProps = (state, props) => {
  const { custs: custsStore } = state;
  const {
    match: {
      params: { uuid },
    },
    location: { search },
  } = props;

  const queryParam = queryStringParse(search);
  const { tabStates: qpTabStates = {} } = queryParam;

  const tabStates = {
    [ROSTER_TABS.JURISDICTION]: parseTabStates(
      qpTabStates[ROSTER_TABS.JURISDICTION]
    ),
  };

  const jurisdictionAttrs = uuidJurisdictionSelector(state, props);
  const userInfo = uuidUserSelector(state, props);
  const jurisdictionPage =
    jurisdictionAttrs && jurisdictionAttrs.controlOwnerJurisdiction
      ? getJurisdictionCusts({
          controlOwnerJurisdiction: jurisdictionAttrs.controlOwnerJurisdiction,
          pagelimit: JURISDICTION_PAGE_LIMIT,
          tabState: tabStates[ROSTER_TABS.JURISDICTION],
        })
      : undefined;

  const custsResources = jurisdictionPage
    ? getResources(custsStore, jurisdictionPage.jurisdictionCusts)
    : undefined;
  if (
    jurisdictionPage &&
    (!custsResources ||
      custsResources.length < jurisdictionPage.jurisdictionCusts.length)
  ) {
    const jurCusts = jurisdictionPage.jurisdictionCusts;
    const custsResourcesStatus = getStatus(
      custsStore,
      jurCusts.map((c) => `meta.${c}.readStatus`),
      false
    );
    if (
      !custsResourcesStatus ||
      !(custsResourcesStatus.pending || custsResourcesStatus.failed)
    ) {
      this.readCustsPromise = this.readCusts({
        firmId: jurisdictionAttrs.firmId,
        custs: jurisdictionPage.jurisdictionCusts,
      });
    }
  }

  const custsLoaded =
    custsResources &&
    custsResources.length === jurisdictionPage.jurisdictionCusts.length;

  return {
    jurisdictionAttrs,
    uuid,
    userInfo,
    custsResources,
    custsLoaded,
  };
};

const CO_JURISDICTION_CONFIG = {
  allowGenerateReport: true,
  columnSpec: [
    {
      title: intl('firm.cust_acct_name_in_jurisdiction'),
      className: 'align-left cust-acct-name-column',
    },
    {
      title: intl('firm.cust_acct_id'),
      className: 'align-left cust-acct-id-column',
    },
    {
      title: intl('control_owner.region'),
      className: 'align-left region-column',
    },
    {
      title: intl('control_owner.country'),
      className: 'align-left country-column',
    },
    {
      title: intl('firm.associated_caid'),
      className: 'align-left caid-column',
    },
    {
      title: intl('firm.caid_in_jurisdiction'),
      className: 'align-left caid-in-jurisdiction-column',
    },
  ],

  rowTemplateCells: [
    {
      mapDataToProps: (data) => ({
        showAttributeKeys: false,
        attributes: [{ value: data.custName }],
      }),
      cellTemplate: AttributeListCellTemplate,
      className: 'textAttributes',
    },
    {
      mapDataToProps: (data) => ({
        showAttributeKeys: false,
        attributes: [{ value: data.id }],
      }),
      cellTemplate: AttributeListCellTemplate,
      className: 'textAttributes',
    },
    {
      mapDataToProps: (data) => ({
        showAttributeKeys: false,
        attributes: [{ value: data.region }],
      }),
      cellTemplate: AttributeListCellTemplate,
      className: 'textAttributes',
    },
    {
      mapDataToProps: (data) => ({
        showAttributeKeys: false,
        attributes: [{ value: data.country }],
      }),
      cellTemplate: AttributeListCellTemplate,
      className: 'textAttributes',
    },
    {
      mapDataToProps: (data) => ({
        showAttributeKeys: false,
        attributes: [{ value: data.caid }],
      }),
      cellTemplate: AttributeListCellTemplate,
      className: 'textAttributes',
    },
    {
      mapDataToProps: (data) => ({
        showAttributeKeys: false,
        attributes: [{ value: data.caidInJurisdiction ? 'Yes' : 'No' }],
      }),
      cellTemplate: AttributeListCellTemplate,
      className: 'textAttributes',
    },
  ],

  loadingRowTemplate: [
    {
      cellTemplate: AttributeListCellTemplateLoading,
      className: 'textAttributes',
    },
    {
      cellTemplate: AttributeListCellTemplateLoading,
      className: 'textAttributes',
    },
    {
      cellTemplate: AttributeListCellTemplateLoading,
      className: 'textAttributes',
    },
    {
      cellTemplate: AttributeListCellTemplateLoading,
      className: 'textAttributes',
    },
    {
      cellTemplate: AttributeListCellTemplateLoading,
      className: 'textAttributes',
    },
    {
      cellTemplate: AttributeListCellTemplateLoading,
      className: 'textAttributes',
    },
  ],

  legend: () => <div />,
};

const JurisdictionRowTemplates = {
  jurisdiction: {
    cells: CO_JURISDICTION_CONFIG.rowTemplateCells,
  },
  jurisdictionLoading: {
    cells: CO_JURISDICTION_CONFIG.loadingRowTemplate,
  },
};

export class ControlOwnersJurisdictionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingPrevious: false,
      isLoadingNext: false,
      generateReportModalInfo: null,
    };
  }

  componentWillMount() {
    const { uuid } = this.props;
    this.props.readJurisdiction(uuid);
    this.props.readUser(uuid);
  }

  dataRetriever = (props = this.props, state = this.state) => {
    const { jurisdictionAttrs, custsResources, custsLoaded } = props;

    const loadingRows = (count, templateName, rowGenerator) => {
      return Array.from(Array(count))
        .map(() => {
          return { templateName };
        })
        .map(rowGenerator);
    };

    return {
      gridContent(rowGenerator) {
        const jurisdictionGrid = [];
        if (jurisdictionAttrs) {
          //Transforming Data to Grid
          const controlOwnerJurisdiction =
            jurisdictionAttrs.controlOwnerJurisdiction;
          if (custsResources && custsLoaded) {
            custsResources.forEach((c) => {
              const caids =
                c.attributes.caids && c.attributes.caids.length
                  ? c.attributes.caids
                  : [' '];
              caids.forEach((caid, i) => {
                jurisdictionGrid.push({
                  templateName: 'jurisdiction',
                  id: i === 0 ? c.id : ' ',
                  custName: i === 0 ? c.attributes.name : ' ',
                  region: i === 0 ? c.attributes.region : ' ',
                  caid: caid,
                  country:
                    i === 0 ? expandCountryCode(c.attributes.country) : ' ',
                  caidInJurisdiction:
                    controlOwnerJurisdiction.caids.includes(caid),
                });
              });
            });
          } else {
            controlOwnerJurisdiction.custs.forEach((c) => {
              jurisdictionGrid.push({
                templateName: 'jurisdiction',
                id: c,
              });
            });
          }

          if (jurisdictionGrid.length > 0) {
            return jurisdictionGrid.map(rowGenerator);
          } else {
            return loadingRows(
              LOADING_ROW_COUNT,
              'jurisdictionLoading',
              rowGenerator
            );
          }
        } else {
          return (
            <h1>
              <FormattedMessage id="control_owner.jurisdiction_unavailable" />
            </h1>
          );
        }
      },
      summaryHero() {
        if (jurisdictionAttrs && jurisdictionAttrs) {
          let summary = [];
          const jurisdiction = jurisdictionAttrs;
          const datum = jurisdictionDescriptionDatum(jurisdiction);
          summary.push({
            name: intl('control_owner.jurisdiction_coverage'),
            value: (
              <ExtendedDatumValueWithOverflow
                name={datum.name}
                value={datum.value}
              />
            ),
          });
          summary.push({
            name: intl('firm.cust_accts'),
            value: `${
              jurisdiction.controlOwnerJurisdiction
                ? jurisdiction.controlOwnerJurisdiction.custs.length
                : 0
            }`,
          });
          summary.push({
            name: intl('firm.caids'),
            value: `${
              jurisdiction.controlOwnerJurisdiction
                ? jurisdiction.controlOwnerJurisdiction.caids.length
                : 0
            }`,
          });

          return <DatumHero data={summary} />;
        } else {
          return <DatumHeroLoading count={3} />;
        }
      },
    };
  };

  render() {
    const { jurisdictionAttrs, userInfo } = this.props;

    const grid =
      jurisdictionAttrs &&
      jurisdictionAttrs &&
      jurisdictionAttrs.controlOwnerJurisdiction ? (
        <Grid
          className={
            'controlOwnerJurisdictionGrid tab_jurisdiction filters-expanded'
          }
          columns={CO_JURISDICTION_CONFIG.columnSpec}
          rowtemplates={JurisdictionRowTemplates}
          gridContent={this.dataRetriever().gridContent}
        />
      ) : (
        <div className="controlOwnerJurisdictionGrid loading">
          <GridLoading
            columns={CO_JURISDICTION_CONFIG.columnSpec}
            rowtemplate={CO_JURISDICTION_CONFIG.loadingRowTemplate}
            rowCount={LOADING_ROW_COUNT}
          />
        </div>
      );

    const datumList = this.dataRetriever().summaryHero();
    const controlOwnerName = userInfo
      ? userInfo.firstName + ' ' + userInfo.lastName
      : '';

    return (
      <SlideOut>
        <div className="controlOwnerJurisdictionApp">
          <div className="controlOwnerJurisdictionHeader">
            <div className="controlOwnerJurisdictionTopBar">
              <span className="controlOwnerJurisdictionTitle">
                <h3>{controlOwnerName}</h3>
                <h1>
                  <FormattedMessage id="control_owners.jurisdiction_coverage" />
                </h1>
              </span>
              <span className="controlOwnerJurisdictionActions">
                <Link to={closeLink} className="closeButton">
                  <FontIcon icon="close" />
                </Link>
              </span>
            </div>
            <div className="controlOwnerJurisdictionDatumList">{datumList}</div>
          </div>
          <div className="controlOwnerJurisdictionBody">
            <div className="facetControl">
              {/* TODO: Paging */}
              <span className="filler" />
              <ReportsManagerContainer />
            </div>
            {grid}
          </div>
        </div>
      </SlideOut>
    );
  }
}

const getJurisdictionCusts = ({
  controlOwnerJurisdiction,
  pagelimit,
  tabState,
}) => {
  const pagedJurisdictionCusts = chunk(
    controlOwnerJurisdiction.custs,
    pagelimit
  );

  // Get current page
  const pageCount = pagedJurisdictionCusts.length;
  const currentPage = Math.min(Math.max(tabState.page, 1), pageCount);
  return {
    totalCount: controlOwnerJurisdiction.custs.length,
    currentPage,
    pageCount,
    jurisdictionCusts: pagedJurisdictionCusts[currentPage - 1] || [],
  };
};

const parseTabStates = (tabState) => {
  const { page = '1', filters = {} } = tabState || {};
  return {
    // If page is not parsible as int, default to 1. If page < 1, default to 1
    page: Math.max(parseInt(page, 10) || 1, 1),
    filters,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlOwnersJurisdictionContainer);
