import qs from 'qs';

export function queryStringParse(query) {
  return qs.parse(query, { ignoreQueryPrefix: true });
}

export function queryStringStringify(queryObj) {
  return qs.stringify(queryObj, {
    encodeValuesOnly: true,
    addQueryPrefix: true,
    strictNullHandling: true,
    arrayFormat: 'repeat',
    format: 'RFC3986',
  });
}
