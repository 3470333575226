/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './styles/Modals.css';
import { Link } from 'react-router-dom';
import { AppLogo } from '../AppLogo';
import { FormattedMessage } from 'react-intl';

const modalRoot = () => {
  let modalRoot = document.getElementById('modal-root');
  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'modal-root');
    document.body.appendChild(modalRoot);
  }
  return modalRoot;
};

/**
 * https://reactjs.org/docs/portals.html
 */
export class Modal extends React.Component {
  constructor(props) {
    const { className } = props;
    super(props);
    this.el = document.createElement('div');
    this.el.setAttribute('class', `modal ${className || ''}`);
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot().appendChild(this.el);
    modalRoot().style.display = 'block';
  }

  componentWillUnmount() {
    modalRoot().removeChild(this.el);
    modalRoot().style.display = 'none';
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export class NetworkErrorModal extends React.Component {
  render() {
    return (
      <Modal className="networkError">
        <div className="description">
          <AppLogo />
          <div className="message">
            <p>
              <FormattedMessage id="error.no_response" />
            </p>
            <p>
              <FormattedMessage id="error.check_network_data" />
            </p>
          </div>
          <Link to="" onClick={() => window.location.reload()}>
            <FormattedMessage id="error.try_again" />
          </Link>
        </div>
      </Modal>
    );
  }
}

export const LoginModal = ({ loginUrl }) => {
  return (
    <Modal className="loginModal">
      <div className="description">
        <AppLogo />
        <a className="loginLink" href={loginUrl}>
          <FormattedMessage id="login.log_in" />
        </a>
        <div className="message">
          <p>
            <FormattedMessage id="login.through_the_bloomberg_portal" />
          </p>
          <i>
            <FormattedMessage id="login.you_will_be_redirected_bauth" />
          </i>
        </div>
      </div>
    </Modal>
  );
};
LoginModal.propTypes = {
  loginUrl: PropTypes.string.isRequired,
};

export default Modal;
/* eslint-enable */
