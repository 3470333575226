import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { capitalize } from 'lodash';

import Grid, { GridLoading } from '../grid/Grid';
import UserInfoCellTemplate, {
  UserInfoCellTemplateLoading,
} from '../grid/UserInfoCellTemplate';
import AttributeListCellTemplate, {
  AttributeListCellTemplateLoading,
} from '../grid/AttributeListCellTemplate';
import GenerateReportModal from '../GenerateReportModal';
import ReportsManagerContainer from '../../containers/ReportsManagerContainer';
import FontIcon from '../FontIcon';
import { intl } from '../../intl/IntlFormat';

import './styles/AdminMonitoringApp.css';
import { SUPPORTED_APPS } from '../../utils/Enums';
import { formatPaginationCount } from '../../utils/FormatUtils';

export const AdminMonitoringApp = ({
  userName,
  app,
  appName,
  datumList,
  closeLink,
  showGenerateReport,
  generateReportModalInfo,
  rowCountInfo,
  onShowGenerateReportModal = () => {},
  onCancelGenerateReportModal = () => {},
  onGenerateReport = () => {},
  children,
}) => (
  <div className={`adminMonitoringApp app_${app}`}>
    {generateReportModalInfo && (
      <GenerateReportModal
        firmName={generateReportModalInfo.firmName}
        reportTitle={generateReportModalInfo.reportTitle}
        scope={generateReportModalInfo.scope}
        bbgEmail={generateReportModalInfo.bbgEmail}
        corpEmail={generateReportModalInfo.corpEmail}
        isImpersonating={generateReportModalInfo.isImpersonating}
        onCancel={onCancelGenerateReportModal}
        onGenerate={onGenerateReport}
      />
    )}
    <div className="adminMonitoringHeader">
      <div className="userMonitoringTopBar">
        <span className="adminMonitoringTitle">
          <h3>{userName}</h3>
          <h1>{appName}</h1>
        </span>
        <span className="adminMonitoringActions">
          <Link to={closeLink} className="closeButton">
            <FontIcon icon="close" />
          </Link>
        </span>
      </div>
      <div className="adminMonitoringDatumList">{datumList}</div>
    </div>
    <div className="adminMonitoringBody">
      <div className="facetControl">
        <span className="filler" />
        {rowCountInfo && rowCountInfo.totalCount && (
          <span className="pageCount">
            {' '}
            {formatPaginationCount(
              rowCountInfo.firstRowNum,
              rowCountInfo.lastRowNum,
              rowCountInfo.totalCount
            )}{' '}
          </span>
        )}
        <ReportsManagerContainer />
      </div>
      {children}
    </div>
  </div>
);

AdminMonitoringApp.propTypes = {
  userName: PropTypes.string,
  appName: PropTypes.string.isRequired,
  showGenerateReport: PropTypes.bool,
  datumList: PropTypes.element.isRequired,
  closeLink: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

const columnSpec = [
  {
    title: intl('admin_monitoring.monitored_employee_coverage'),
    className: 'align-left monitored-column',
  },
  { title: intl('user.status'), className: 'align-left status-column' },
  {
    title: intl('admin_monitoring.monitored_employee_membership'),
    className: 'align-left membership-column',
  },
];
const leisColumnSpec = [
  { title: 'LEI', className: 'align-left lei-column' },
  //{title: 'NAME', className: 'align-left name-column'},
  //{title: 'REGION', className: 'align-left region-column'},
  //{title: 'COUNTRY', className: 'align-left country-column'},
  { title: intl('firm.associated_caid'), className: 'align-right caid-column' },
];
const rowTemplates = {
  adminMonitoringApp: {
    cells: [
      {
        mapDataToProps: (data) => ({
          fullName: data.fullName,
          uuid: data.uuid,
          cuid: data.cuid,
          isNameUnknown: data.isNameUnknown,
          isUserDeleted: data.isUserDeleted,
        }),
        cellTemplate: UserInfoCellTemplate,
      },
      {
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: data.statuses.map((status) => ({
            value: capitalize(status),
          })),
          isUserDeleted: data.isUserDeleted,
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        mapDataToProps: (data) => ({
          showAttributeKeys: true,
          attributes: data.memberships,
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
    ],
  },
  adminMonitoringLeis: {
    cells: [
      {
        mapDataToProps: (data) => {
          console.log(data);
          return {
            showAttributeKeys: false,
            attributes: [{ value: data.lei }],
          };
        },
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      /* {
        mapDataToProps: (data) => ({ showAttributeKeys: false, attributes: [{value: data.name}] }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes'
      },
      {
        mapDataToProps: (data) => ({ showAttributeKeys: false, attributes: [{value: data.region}] }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes'
      },
      {
        mapDataToProps: (data) => ({ showAttributeKeys: false, attributes: [{value: data.country}] }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes'
      },*/
      {
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [{ value: data.caid }],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'numericAttributes',
      },
    ],
  },
};
const loadingRowTemplate = [
  {
    cellTemplate: UserInfoCellTemplateLoading,
  },
  {
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
  {
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
];

export const AdminMonitoringGrid = ({
  app,
  rows,
  onLoadPrevious,
  onLoadNext,
  gridFooter,
}) => {
  return (
    <div className={`adminMonitoringGrid`}>
      <Grid
        columns={SUPPORTED_APPS.TRADE === app ? leisColumnSpec : columnSpec}
        rowtemplates={rowTemplates}
        gridContent={(rowGenerator) => rows.map(rowGenerator)}
        pagingControl={{
          isLoadingPrevious: false,
          isLoadingNext: false,
          onLoadPrevious: onLoadPrevious,
          onLoadNext: onLoadNext,
        }}
        gridFooter={gridFooter}
      />
    </div>
  );
};
AdminMonitoringGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export const AdminMonitoringGridLoading = ({ rowCount = 30 }) => (
  <div className="adminMonitoringGrid loading">
    <GridLoading
      columns={columnSpec}
      rowtemplate={loadingRowTemplate}
      rowCount={rowCount}
    />
  </div>
);
AdminMonitoringGridLoading.propTypes = {
  rowCount: PropTypes.number.isRequired,
};
