/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { SearchResultsSectionType } from '../PropTypes';
import { SearchInput, SearchResults } from './Search';

import './styles/ScopeSearchPanel.css';
import { FormattedMessage } from 'react-intl';

class ScopeSearchPanel extends React.Component {
  state = {
    selectedResult: 0,
  };

  instructionPanelRenderer() {
    const {
      instructionText = '',
      resetLink = '',
      onClosePanel = () => {},
    } = this.props;

    return (
      <div className="row instructionPanel">
        <span className="instructionText">{instructionText}</span>
        <span className="panelControl">
          <Link
            className="resetButton"
            to={resetLink}
            onClick={() => onClosePanel()}
          >
            <FormattedMessage id="firm.show_entire_jurisdiction" />
          </Link>
          {/* eslint-disable-next-line */}
          <a
            className="cancelButton"
            onClick={(e) => {
              e.preventDefault();
              onClosePanel();
            }}
          >
            <FormattedMessage id="utility.cancel" />
          </a>
        </span>
      </div>
    );
  }

  flatSearchResults = (props = this.props) => {
    const { searchResults = [] } = props;
    return searchResults.map((s) => s.results).flat();
  };

  onKeyDown = (event, props = this.props, state = this.state) => {
    const { selectedResult } = state;
    const { onClickResult = () => {} } = props;

    const flatSearchResults = this.flatSearchResults();

    switch (event.keyCode) {
      case 13: // ENTER
        onClickResult(
          undefined,
          flatSearchResults[selectedResult - 1].clickInfo
        );
        break;
      case 38: // UP
        this.setState({
          ...state,
          selectedResult: Math.max(selectedResult - 1, 0),
        });
        break;
      case 40: // DOWN
        this.setState({
          ...state,
          selectedResult: Math.min(
            selectedResult + 1,
            flatSearchResults.length
          ),
        });
        break;
      default:
      // NO-OP
    }
  };

  render() {
    const {
      placeholder = '',
      inputValue = '',
      searchResults = [],
      showInstructionPanel = false,
      onSearchInputChange = () => {},
      onClickResult = () => {},
    } = this.props;
    const { selectedResult } = this.state;

    return (
      <div className="scopeSearchPanel">
        {showInstructionPanel && this.instructionPanelRenderer()}
        <div className="row">
          <SearchInput
            placeholder={placeholder}
            value={inputValue}
            onChange={onSearchInputChange}
            onKeyDown={this.onKeyDown}
          />
          <SearchResults
            sections={searchResults}
            onClickResult={onClickResult}
            selectedResult={selectedResult}
          />
        </div>
      </div>
    );
  }
}

ScopeSearchPanel.propTypes = {
  placeholder: PropTypes.string,
  inputValue: PropTypes.string,
  searchResults: PropTypes.arrayOf(PropTypes.shape(SearchResultsSectionType)),
  showInstructionPanel: PropTypes.bool,
  instructionText: PropTypes.string,
  resetLink: PropTypes.string,
  onClosePanel: PropTypes.func,
  onSearchInputChange: PropTypes.func,
  onClickResult: PropTypes.func,
};

export default ScopeSearchPanel;
/* eslint-enable */
