import { intl } from '../intl/IntlFormat';

export const SUPPORTED_APPS = {
  MFCA: 'mfca',
  SEARCH_AND_EXPORT: 'searchAndExport',
  SURVEILLANCE: 'surveillance',
  PCHL: 'pchl',
  TRADE: 'trade',
};

export const APP_ORDER = [
  SUPPORTED_APPS.MFCA,
  SUPPORTED_APPS.SEARCH_AND_EXPORT,
  SUPPORTED_APPS.SURVEILLANCE,
  SUPPORTED_APPS.PCHL,
  SUPPORTED_APPS.TRADE,
];

export const APP_FULL_NAME = {
  [SUPPORTED_APPS.MFCA]: intl('firm.mfca_description'),
  [SUPPORTED_APPS.SEARCH_AND_EXPORT]: intl('firm.bvtw_description'),
  [SUPPORTED_APPS.SURVEILLANCE]: intl('firm.bvsv_description'),
  [SUPPORTED_APPS.PCHL]: intl('firm.pchl_description'),
  [SUPPORTED_APPS.TRADE]: intl('firm.ta_description'),
};

export const APP_FRIENDLY_NAME = {
  [SUPPORTED_APPS.MFCA]: intl(
    'admin_monitoring.multi_firm_chat_administration'
  ),
  [SUPPORTED_APPS.SEARCH_AND_EXPORT]: intl(
    'admin_monitoring.search_and_export'
  ),
  [SUPPORTED_APPS.SURVEILLANCE]: intl('admin_monitoring.surveillance'),
  [SUPPORTED_APPS.PCHL]: intl('admin_monitoring.persistent_chat_room_list'),
  [SUPPORTED_APPS.TRADE]: intl('admin_monitoring.trade_archive'),
};

export const APP_SHORT_NAME = {
  [SUPPORTED_APPS.MFCA]: 'MFCA',
  [SUPPORTED_APPS.SEARCH_AND_EXPORT]: 'BVTW',
  [SUPPORTED_APPS.SURVEILLANCE]: 'BVSV',
  [SUPPORTED_APPS.PCHL]: 'PCHL',
  [SUPPORTED_APPS.TRADE]: 'TA',
};

export const MONITORING_COUNT_TYPES = {
  EMPLOYEE: 'Employee',
  FIRM: 'FIRM',
  CAID: 'CAID',
  CUST: 'CUST',
  UUID: 'UUID',
  CUID: 'CUID',
  LEI: 'LEI',
  CUSTOM_GROUP: 'CustomGroup',
};

export const MONITORING_COUNT_LABEL_MAP = {
  [MONITORING_COUNT_TYPES.EMPLOYEE]: intl('firm.employees'),
  [MONITORING_COUNT_TYPES.FIRM]: intl('control_owner.firm'),
  [MONITORING_COUNT_TYPES.CAID]: intl('firm.caids'),
  [MONITORING_COUNT_TYPES.CUST]: intl('firm.cust_accts'),
  [MONITORING_COUNT_TYPES.UUID]: intl('search.users'),
  [MONITORING_COUNT_TYPES.CUID]: 'CUIDs',
  [MONITORING_COUNT_TYPES.LEI]: 'LEIs',
  [MONITORING_COUNT_TYPES.CUSTOM_GROUP]: intl('user.custom_groups'),
};

export const ADMIN_ROLE_TYPES = {
  ADMIN: 'admin', // Search
  REVIEWER: 'reviewer', // MFCA
  REVIEW: 'review', // Surveillance
  MANAGE: 'manage', // Surveillance
  OWNER: 'owner', // Surveillance
  POLICY_ADMIN: 'policy_admin', // Surveillance
  ROOT_ADMIN: 'root_admin', // Surveillance // TODO deprecated role
  MASTER_ADMIN: 'master_admin', // Surveillance
  CONTROL_OWNER: 'Control Owner', // Surveillance
};

// Mapping all admin role labels to intl labels
export const ADMIN_ROLE_INTL_LABELS = {
  ADMIN_REVIEWER: 'firm.admin_reviewer',
  MANAGE_ADMIN: 'firm.manage_admin',
  OWNER: 'firm.owner',
  POLICY_ADMIN: 'firm.policy_admin',
  FIRM_WIDE_MASTER_ADMIN: 'firm.firm_wide_master_admin',
  CONTROL_OWNER: 'control_owner.control_owner',
};

export const ADMIN_ROLE_LABEL_MAP = {
  [ADMIN_ROLE_TYPES.ADMIN]: ADMIN_ROLE_INTL_LABELS.ADMIN_REVIEWER, // Search
  [ADMIN_ROLE_TYPES.REVIEWER]: ADMIN_ROLE_INTL_LABELS.ADMIN_REVIEWER, // MFCA
  [ADMIN_ROLE_TYPES.REVIEW]: ADMIN_ROLE_INTL_LABELS.ADMIN_REVIEWER, // Surveillance
  [ADMIN_ROLE_TYPES.MANAGE]: ADMIN_ROLE_INTL_LABELS.MANAGE_ADMIN, // Surveillance
  [ADMIN_ROLE_TYPES.OWNER]: ADMIN_ROLE_INTL_LABELS.OWNER, // Surveillance
  [ADMIN_ROLE_TYPES.POLICY_ADMIN]: ADMIN_ROLE_INTL_LABELS.POLICY_ADMIN, // Surveillance
  [ADMIN_ROLE_TYPES.ROOT_ADMIN]: ADMIN_ROLE_INTL_LABELS.FIRM_WIDE_MASTER_ADMIN, // Surveillance
  [ADMIN_ROLE_TYPES.MASTER_ADMIN]:
    ADMIN_ROLE_INTL_LABELS.FIRM_WIDE_MASTER_ADMIN, // Surveillance
  [ADMIN_ROLE_TYPES.CONTROL_OWNER]: ADMIN_ROLE_INTL_LABELS.CONTROL_OWNER, // Surveillance
};

export const isAppSupported = (app) =>
  Object.values(SUPPORTED_APPS).includes(app);

export const intlRoleLabels = (roles) =>
  Array.from(
    new Set(
      roles.map((role) => ADMIN_ROLE_LABEL_MAP[role.toLowerCase()] || role)
    )
  );

export const rolesLabels = (roles) => {
  // Ensure each role label is only returned once in resulting array
  return intlRoleLabels(roles).map((label) => intl(label));
};

export const MFCA_SETUP_TYPES = {
  MAT: 'mat',
  BVTS: 'bvts',
};

export const PCHL_SETUP_TYPES = {
  MAT: 'mat',
  BVTS: 'bvts',
};

export const SETUP_TYPE_LABEL_MAP = {
  [SUPPORTED_APPS.MFCA]: {
    [MFCA_SETUP_TYPES.MAT]: 'MAT',
    [MFCA_SETUP_TYPES.BVTS]: 'BVTW',
  },
  [SUPPORTED_APPS.SEARCH_AND_EXPORT]: {},
  [SUPPORTED_APPS.SURVEILLANCE]: {},
  [SUPPORTED_APPS.PCHL]: {
    [PCHL_SETUP_TYPES.MAT]: 'MAT',
    [PCHL_SETUP_TYPES.BVTS]: 'BVTW',
  },
};

export const setupTypeLabels = (app, setupTypes) =>
  Array.from(
    new Set(
      setupTypes.map(
        (setupType) =>
          SETUP_TYPE_LABEL_MAP[app][setupType.toLowerCase()] || setupType
      )
    )
  );

export const ROSTER_TABS = {
  ADMINS: 'admins',
  MONITORED_EMPLOYEES: 'monitoredEmployees',
  JURISDICTION: 'jurisdiction',
};

export const ADMIN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};
export const statusIsInactive = (status) => status === ADMIN_STATUS.INACTIVE;
export const statusIsActive = (status) => status === ADMIN_STATUS.ACTIVE;

export const ADMIN_STATUS_RADIO_GROUP = [
  {
    label: intl('firm.status_all'),
    value: '',
  },
  {
    label: intl('firm.status_active'),
    value: 'Active',
  },
  {
    label: intl('firm.status_inactive'),
    value: 'Inactive',
  },
];
