/* eslint-disable */
// External Dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';

// Components
import debugMod from 'debug';
import { LoginModal } from '../components/Modals';

// Utils
import { getLoginUrl } from '../utils/JwtAuth';

const debug = debugMod('roster:LoginScreenContainer');

const mapStateToProps = (state) => ({
  isAuthenticated: state.JwtAuth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({});

class LoginScreenContainer extends React.Component {
  render() {
    const { isAuthenticated, location } = this.props;
    debug('[location]', location);
    const redirectTo = (location.state && location.state.from) || {
      pathname: '/',
      search: '',
    };
    return isAuthenticated ? (
      <Redirect to={redirectTo} />
    ) : (
      <LoginModal
        loginUrl={getLoginUrl(redirectTo.pathname, redirectTo.search)}
      />
    );
  }
}

LoginScreenContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginScreenContainer);
/* eslint-enable */
