/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { getResources } from 'redux-resource';

import { queryStringStringify } from '../utils/QueryStringParser';

import SuperUserScopeSearchContainer from './SuperUserScopeSearchContainer';
import ScopeSearchPanel from '../components/ScopeSearchPanel';
import {
  currentUserJurisdictionSelector,
  currentUserSelector,
} from '../store/selectors';
import { intl } from '../intl/IntlFormat';
import { FormattedMessage } from 'react-intl';

const SEARCH_CATEGORIES = {
  CAID: 'CAID',
  CUST: 'CUST',
};

const SEARCH_CATEGORY_CONFIG = {
  [SEARCH_CATEGORIES.CAID]: {
    label: intl('firm.caids'),
    displayName: intl('control_owner.caid'),
    lookup: (userJurisdiction) => userJurisdiction.caids,
    getCustCaidInfo: (caid) => ({ caid }),
  },
  [SEARCH_CATEGORIES.CUST]: {
    label: intl('firm.customer_accounts'),
    displayName: intl('search.cust'),
    lookup: (userJurisdiction) => userJurisdiction.custs,
    getCustCaidInfo: (cust) => ({ cust }),
  },
};

const mapStateToProps = (state, props) => {
  const { custs } = state;

  const currentUserData = currentUserSelector(state);
  const currentUserFirm = currentUserData && currentUserData.firmId;
  const currentUserJurisdiction = currentUserJurisdictionSelector(state);
  const isCurrentUserInternal =
    currentUserJurisdiction && currentUserJurisdiction.isInternalUser;
  const isCurrentUserSuperUser =
    currentUserJurisdiction && currentUserJurisdiction.isSuperUser;
  const jurisdictionCusts =
    (currentUserJurisdiction &&
      currentUserJurisdiction.controlOwnerJurisdiction &&
      currentUserJurisdiction.controlOwnerJurisdiction.custs) ||
    [];
  const jurisdictionCaids =
    (currentUserJurisdiction &&
      currentUserJurisdiction.controlOwnerJurisdiction &&
      currentUserJurisdiction.controlOwnerJurisdiction.caids) ||
    [];

  return {
    currentUserFirm,
    currentUserJurisdiction: {
      custs: getResources(custs, jurisdictionCusts).map((cust) => ({
        id: cust.id,
        name: cust.attributes.name,
      })),
      caids: jurisdictionCaids.map((caid) => ({
        id: caid,
        name: `CAID ${caid}`,
      })),
    },
    isCurrentUserSuperUser,
    isCurrentUserInternal,
  };
};

const mapDispatchToProps = {};

export class ScopeSearchContainer extends React.Component {
  state = {
    searchInput: '',
  };

  onSearchInputChange = (newValue) => {
    this.setState({
      ...this.state,
      searchInput: newValue,
    });
  };

  onClickResult = (event, clickInfo) => {
    const { onClosePanel = () => {} } = this.props;
    onClosePanel();
  };

  getUrl = (firmId, subtabOverride, custCaidInfo) => {
    subtabOverride = subtabOverride ? `/${subtabOverride}` : '';

    return `/firm/${firmId}/roster${subtabOverride}${queryStringStringify(
      custCaidInfo
    )}`;
  };

  jurisdictionSearch = (searchInput, jurisdiction, getCustCaidInfo) => {
    const { subtabOverride, currentUserFirm } = this.props;

    return jurisdiction
      .filter(({ id, name }) => {
        return (
          `${id}`.includes(searchInput) ||
          name.toLowerCase().includes(searchInput.toLowerCase())
        );
      })
      .map(({ id, name }) => ({
        id,
        linkTo: this.getUrl(
          currentUserFirm,
          subtabOverride,
          getCustCaidInfo(id)
        ),
        displayName: name,
        clickInfo: {},
        meta: '',
      }));
  };

  searchResults() {
    const { currentUserJurisdiction } = this.props;
    const { searchInput } = this.state;

    if (searchInput.length < 3) {
      return [];
    }

    const sectionOrder = [SEARCH_CATEGORIES.CAID, SEARCH_CATEGORIES.CUST];

    return sectionOrder.map((category) => {
      const config = SEARCH_CATEGORY_CONFIG[category];

      const results = this.jurisdictionSearch(
        searchInput,
        config.lookup(currentUserJurisdiction),
        config.getCustCaidInfo
      );

      return {
        sectionName: config.label,
        totalCount: results.length,
        results,
      };
    });
  }

  render() {
    const {
      isCurrentUserSuperUser,
      isCurrentUserInternal,
      subtabOverride,
      history,
      instructionText,
      resetLink,
      showInstructionPanel,
      onClosePanel,
    } = this.props;

    if (isCurrentUserInternal && isCurrentUserSuperUser) {
      return (
        <SuperUserScopeSearchContainer
          searchCategories={SEARCH_CATEGORIES}
          subtabOverride={subtabOverride}
          showInstructionPanel={showInstructionPanel}
          resetLink={resetLink}
          instructionText={instructionText}
          history={history}
          onClosePanel={onClosePanel}
        />
      );
    }

    return (
      <FormattedMessage id="search.results_after_3_characters_tip">
        {(message) => (
          <ScopeSearchPanel
            placeholder={message}
            inputValue={this.state.searchInput}
            searchResults={this.searchResults()}
            showInstructionPanel={showInstructionPanel}
            instructionText={instructionText}
            resetLink={resetLink}
            onSearchInputChange={this.onSearchInputChange}
            onClickResult={this.onClickResult}
            onClosePanel={onClosePanel}
          />
        )}
      </FormattedMessage>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScopeSearchContainer);
/* eslint-enable */
