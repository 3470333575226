/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import {
  DatumPair,
  DatumPairWithTooltip,
  DatumColumn,
  DatumSection,
  DatumSubSection,
  DatumSectionLoading,
} from '../Datum';
import { userProfileLink } from '../../utils/LinkUtils';
import { MemberProfile } from './Profile';
import { intl } from '../../intl/IntlFormat';

import './styles/UserProfileMemberDetails.css';

class UserProfileMemberDetails extends React.Component {
  renderDataHolds(dataHolds, firm, uuid) {
    return dataHolds.map((dataHold, key) => {
      const {
        status,
        enabledByName,
        creationDate,
        lastUpdateDate,
        caid,
        description,
      } = dataHold;

      const userLink = (
        <Link
          to={userProfileLink({ firmId: firm, userIdType: 'u', userId: uuid })}
        >
          {enabledByName}
        </Link>
      );

      switch (status) {
        case 'Not Enabled':
          return (
            <DatumPair
              key={key}
              datum={{ name: intl('firm.status'), value: `${status}` }}
            />
          );
        case 'Enabled':
          return (
            <div key={key}>
              <DatumPair
                datum={{ name: intl('firm.status'), value: `${status}` }}
              />
              <DatumPair
                datum={{
                  name: intl('user.enabled_date'),
                  value: `${creationDate} - ${intl('user.present')}`,
                }}
              />
              <DatumPair
                datum={{ name: intl('user.enabled_by'), value: userLink }}
              />
              <DatumPair
                datum={{ name: intl('user.affected_caids'), value: `${caid}` }}
              />
              <DatumPair
                datum={{
                  name: intl('user.description'),
                  value: `${description}`,
                }}
              />
            </div>
          );
        case 'Migrated':
          return (
            <div key={key}>
              <DatumPair
                datum={{ name: intl('firm.status'), value: `${status}` }}
              />
              <DatumPair
                datum={{
                  name: intl('user.enabled_date'),
                  value: `${creationDate} - ${intl('user.present')}`,
                }}
              />
              <DatumPair
                datum={{ name: intl('user.migrated_by'), value: userLink }}
              />
              <DatumSubSection
                subSectionDatum={
                  <DatumPair
                    datum={{ name: intl('user.affected_caids'), value: `` }}
                  />
                }
              >
                <DatumPair
                  datum={{
                    name: intl('user.originating_caid'),
                    value: `${caid}`,
                  }}
                />
                <DatumPair
                  datum={{
                    name: intl('user.receiving_caid'),
                    value: `${caid}`,
                  }}
                />
              </DatumSubSection>
              <DatumPair
                datum={{
                  name: intl('user.description'),
                  value: `${description}`,
                }}
              />
            </div>
          );
        case 'Disabled':
          return (
            <div key={key}>
              <DatumPair
                datum={{ name: intl('firm.status'), value: `${status}` }}
              />
              <DatumPair
                datum={{
                  name: intl('user.enabled_date'),
                  value: `${creationDate} - ${lastUpdateDate}`,
                }}
              />
              <DatumPair
                datum={{ name: intl('user.migrated_by'), value: userLink }}
              />
              <DatumPair
                datum={{ name: intl('user.affected_caids'), value: `${caid}` }}
              />
              <DatumPair
                datum={{
                  name: intl('user.description'),
                  value: `${description}`,
                }}
              />
            </div>
          );
        default:
          return <div key={key} />;
      }
    });
  }

  render() {
    const {
      userInfo: {
        firstName,
        lastName,
        bbgEmail,
        corpEmail,
        uuid,
        cuid,
        bbgLogin,
        licenseTypeInfo = {
          isInactive: true,
          type: '--',
        },
        caid,
        cust,
        firm,
        firmName,
        controlOwnerInfo = {},
        isPolicyAdmin,
        isReviewer,
        isControlOwner,
        isBillable,
        compCheck,
        dataHolds,
      },
    } = this.props;

    var compCheckHoverMessage = intl(
      'user.surveillance_policies_hover_message'
    );
    var billableHoverMessage = intl('user.vault_billing_status_hover_message');
    var dataHoldsSection = this.renderDataHolds(dataHolds || []);
    var hasDataHold = dataHoldsSection.length !== 0;

    return (
      <div className="userProfileMemberDetails">
        <MemberProfile>
          <DatumColumn>
            <DatumSection sectionTitle={intl('user.titles_contact_info')}>
              <DatumPair
                datum={{
                  name: intl('user.name'),
                  value: `${firstName} ${lastName}`,
                }}
              />
              <DatumPair
                datum={{
                  name: intl('user.bbg_email'),
                  value: `${bbgEmail || '--'}`,
                }}
              />
              <DatumPair
                datum={{
                  name: intl('user.corporate_email'),
                  value: `${corpEmail || '--'}`,
                }}
              />
            </DatumSection>
            <DatumSection sectionTitle={intl('user.identifiers')}>
              {!!bbgLogin ? (
                <DatumSubSection
                  subSectionDatum={
                    <DatumPair
                      datum={{
                        name: intl('user.bbg_login'),
                        value: `${bbgLogin || '--'}`,
                      }}
                    />
                  }
                >
                  <DatumPair
                    datum={{
                      name: intl('user.status'),
                      value: licenseTypeInfo.isInactive
                        ? intl('firm.status_inactive')
                        : intl('firm.status_active'),
                    }}
                  />
                  <DatumPair
                    datum={{
                      name: intl('user.license_type'),
                      value: `${licenseTypeInfo.type}`,
                    }}
                  />
                </DatumSubSection>
              ) : (
                <DatumPair
                  datum={{ name: intl('user.bbg_login'), value: '--' }}
                />
              )}
              <DatumPair datum={{ name: 'UUID', value: `${uuid || '--'}` }} />
              <DatumPair datum={{ name: 'CUID', value: `${cuid || '--'}` }} />
            </DatumSection>
            {hasDataHold ? (
              <DatumSection sectionTitle={intl('user.data_hold')}>
                {dataHoldsSection}
              </DatumSection>
            ) : (
              <DatumSection />
            )}
          </DatumColumn>
          <DatumColumn>
            <DatumSection
              sectionTitle={intl('user.bloomberg_structural_membership')}
            >
              <DatumPair
                datum={{
                  name: intl('user.firm_name'),
                  value: `${firmName || '--'}`,
                }}
              />
              <DatumPair
                datum={{ name: intl('user.firm_id'), value: `${firm || '--'}` }}
              />
              <DatumPair
                datum={{
                  name: intl('firm.cust_acct_id'),
                  value: `${cust || '--'}`,
                }}
              />
              <DatumPair
                datum={{
                  name: intl('control_owner.caid'),
                  value: `${caid || '--'}`,
                }}
              />
            </DatumSection>
            <DatumSection sectionTitle={intl('user.bloomberg_roles')}>
              {isControlOwner ? (
                <DatumSubSection
                  subSectionDatum={
                    <DatumPair
                      datum={{
                        name: intl('control_owner.control_owner'),
                        value: intl('user.yes'),
                      }}
                    />
                  }
                >
                  <DatumPair
                    datum={{
                      name: intl('user.status'),
                      value: intl('user.available'),
                    }}
                  />
                  <DatumPair
                    datum={{
                      name: intl('user.jurisdiction_type'),
                      value: controlOwnerInfo.jurisdictionType,
                    }}
                  />
                  <DatumPair
                    datum={{
                      name: intl('header.jurisdiction'),
                      value: controlOwnerInfo.jurisdiction,
                    }}
                  />
                </DatumSubSection>
              ) : (
                <DatumPair
                  datum={{
                    name: intl('control_owner.control_owner'),
                    value: intl('user.no'),
                  }}
                />
              )}
              <DatumPair
                datum={{
                  name: intl('user.policy_administrator'),
                  value: isPolicyAdmin ? intl('user.yes') : intl('user.no'),
                }}
              />
              <DatumPair
                datum={{
                  name: intl('user.reviewer'),
                  value: isReviewer ? intl('user.yes') : intl('user.no'),
                }}
              />
            </DatumSection>
            <DatumSection sectionTitle={intl('user.ecommunication_settings')}>
              <DatumPairWithTooltip
                datum={{
                  name: intl('user.surveillance_policies_applied'),
                  value: compCheck ? intl('user.yes') : intl('user.no'),
                  dtContent: compCheckHoverMessage,
                }}
              />
              <DatumPairWithTooltip
                datum={{
                  name: intl('user.vault_billing_status'),
                  value: isBillable ? intl('user.on') : intl('user.off'),
                  dtContent: billableHoverMessage,
                }}
              />
            </DatumSection>
          </DatumColumn>
        </MemberProfile>
      </div>
    );
  }
}

const LicenseTypeInfoShape = {
  isInactive: PropTypes.bool,
  type: PropTypes.string,
};

const ControlOwnerInfoShape = {
  isControlOwner: PropTypes.bool,
  jurisdiction: PropTypes.string,
};

const UserInfoShape = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  bbgEmail: PropTypes.string,
  uuid: PropTypes.number,
  cuid: PropTypes.number,

  licenseTypeInfo: PropTypes.shape(LicenseTypeInfoShape),

  caid: PropTypes.number,
  cust: PropTypes.number,
  firm: PropTypes.number,
  firmName: PropTypes.string,

  controlOwnerInfo: PropTypes.shape(ControlOwnerInfoShape),
  isPolicyAdmin: PropTypes.bool,
  isReviewer: PropTypes.bool,
};

UserProfileMemberDetails.propTypes = {
  userInfo: PropTypes.shape(UserInfoShape).isRequired,
};

export default UserProfileMemberDetails;

const UserProfileMemberDetailsLoading = () => (
  <MemberProfile>
    <DatumColumn>
      <DatumSectionLoading rowCount={12} />
      <DatumSectionLoading rowCount={12} />
    </DatumColumn>
    <DatumColumn>
      <DatumSectionLoading rowCount={6} />
      <DatumSectionLoading rowCount={6} />
    </DatumColumn>
  </MemberProfile>
);

const UserProfileMemberDetailsError = () => <div />;

export { UserProfileMemberDetailsLoading, UserProfileMemberDetailsError };
