import React from 'react';
import PropTypes from 'prop-types';
import { SearchInput } from '../Search';
import { uniqBy as _uniqBy } from 'lodash';
import debugMod from 'debug';

import './styles/FauxtoCompleteFilter.css';
import FontIcon from '../FontIcon';

// eslint-disable-next-line
const debug = debugMod('roster:FauxtoCompleteFilter');

const FAUXTO_REULT_LIMIT = 5;

class FauxtoCompleteFilter extends React.Component {
  constructor(props) {
    super(props);

    const { value = [] } = props;
    const pills = (Array.isArray(value) ? value : [value]).map((v) => ({
      value: v,
      type: 'applied',
    }));

    this.state = {
      pills,
      searchInput: '',
      selectedResult: 0,
    };
  }

  onSearchInputChange = (newValue) =>
    this.setState({ ...this.state, searchInput: newValue });

  onKeyDown = (event, state = this.state) => {
    const { selectedResult } = state;
    const searchResults = this.searchResults();

    switch (event.keyCode) {
      case 13: // ENTER
        this.addPendingPill(searchResults[selectedResult - 1].pillValue);
        break;
      case 38: // UP
        this.setState({
          ...state,
          selectedResult: Math.max(selectedResult - 1, 0),
        });
        break;
      case 40: // DOWN
        this.setState({
          ...state,
          selectedResult: Math.min(selectedResult + 1, searchResults.length),
        });
        break;
      default:
      // NO-OP
    }
  };

  addPendingPill = (newPillValue) => {
    const { pills = [] } = this.state;
    pills.push({ value: newPillValue, type: 'pending' });

    this.setPills(_uniqBy(pills, 'value'));
  };

  removePill = (pill) => {
    const { pills = [] } = this.state;

    this.setPills(pills.filter((p) => p.value !== pill.value));
  };

  setPills = (pills) => {
    const { onChange = () => {} } = this.props;

    onChange(pills.map((p) => p.value));

    this.setState({ ...this.state, pills, searchInput: '', selectedResult: 0 });
  };

  searchResults() {
    const { headerSpecs = [], resultRows = [] } = this.props;
    const { searchInput = '' } = this.state;

    if (searchInput.length) {
      const searchCols = headerSpecs
        .filter((s) => s.isSearchable)
        .map((s) => s.col);
      return resultRows
        .filter((r) =>
          searchCols.find((c) => {
            return (r[c] || '')
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase());
          })
        )
        .slice(0, FAUXTO_REULT_LIMIT);
    } else {
      return [];
    }
  }

  render() {
    const { placeholder = '', headerSpecs = [] } = this.props;
    const { pills = [], searchInput = '', selectedResult = 0 } = this.state;

    const searchResults = this.searchResults();

    return (
      <div className="fauxtoCompleteFilter">
        <SearchInput
          placeholder={placeholder}
          value={searchInput}
          onChange={this.onSearchInputChange}
          onKeyDown={this.onKeyDown}
        />
        {searchResults.length ? (
          <FauxtoResults
            searchInput={searchInput}
            headerSpecs={headerSpecs}
            resultRows={searchResults}
            selectedResult={selectedResult}
            onClickResult={this.addPendingPill}
          />
        ) : (
          ''
        )}
        <Pills pills={pills} onClickClose={this.removePill} />
      </div>
    );
  }
}
const HeaderSpecShape = {
  col: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  isSearchable: PropTypes.bool,
};
const ResultRowShape = {
  pillValue: PropTypes.node.isRequired,
};
FauxtoCompleteFilter.propTypes = {
  placeholder: PropTypes.string,
  headerSpecs: PropTypes.arrayOf(PropTypes.shape(HeaderSpecShape)),
  resultRows: PropTypes.arrayOf(PropTypes.shape(ResultRowShape)),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export const Pills = ({ pills = [], onClickClose = () => {} }) => (
  <ul className="pills">
    {pills.map((p, i) => (
      <li key={i} className={`pill ${p.type}`}>
        {p.value}{' '}
        <FontIcon
          icon="close"
          onClick={(e) => {
            e.preventDefault();
            onClickClose(p);
          }}
        />
      </li>
    ))}
  </ul>
);
Pills.propTypes = {
  pills: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
    })
  ),
  onClickClose: PropTypes.func,
};

const HighlightResult = ({ searchInput = '', headerSpec, row }) => {
  const val = row[headerSpec.col];
  if (headerSpec.isSearchable) {
    const re = new RegExp(`(.*)(${searchInput})(.*)`, 'i'),
      parts = re.exec(val);

    if (parts) {
      return (
        <span>
          {parts[1]}
          <b>{parts[2]}</b>
          {parts[3]}
        </span>
      );
    }
  }
  return val;
};
HighlightResult.propTypes = {
  searchInput: PropTypes.string,
  headerSpec: PropTypes.shape(HeaderSpecShape),
  row: PropTypes.shape(ResultRowShape),
};

export const FauxtoResults = ({
  searchInput = '',
  headerSpecs = [],
  resultRows = [],
  selectedResult = 0,
  onClickResult = () => {},
}) => (
  <div className="fauxtoResults">
    <table className="searchResultsSection">
      <tbody>
        <tr className="sectionHeader fauxtoHeader">
          {headerSpecs.map((hs, i) => (
            <th key={i}>{hs.displayName}</th>
          ))}
        </tr>
        {resultRows.map((r, i) => (
          <tr
            key={i}
            className={`searchResult ${
              i + 1 === selectedResult ? 'selected' : ''
            }`}
            onClick={(e) => {
              e.preventDefault();
              onClickResult(r.pillValue);
            }}
          >
            {headerSpecs.map((hs, j) => (
              <td key={j}>
                <HighlightResult
                  searchInput={searchInput}
                  headerSpec={hs}
                  row={r}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

FauxtoResults.propTypes = {
  searchInput: PropTypes.string,
  headerSpecs: PropTypes.arrayOf(PropTypes.shape(HeaderSpecShape)),
  resultRows: PropTypes.arrayOf(PropTypes.shape(ResultRowShape)),
  selectedResult: PropTypes.number,
  onClickResult: PropTypes.func,
};

export default FauxtoCompleteFilter;
