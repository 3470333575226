/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { get as _get } from 'lodash';
import debugMod from 'debug';
import DropdownBox from '../components/DropdownBox';
import { isImpersonating } from '../utils/JurisdictionUtils';
import GenerateReportModal from '../components/GenerateReportModal';
import {
  currentUserSelector,
  currentUserJurisdictionSelector,
} from '../store/selectors';
import { createReport } from '../store/apiActions';
import { createAlert, ALERT_TYPES } from '../store/alertManager';
import { intl } from '../intl/IntlFormat';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line
const debug = debugMod('roster:ReportsManagerContainer');

export const SURVEILLANCE_REPORT_TYPE = 'surveillance';

export const MENU_VALUES = {
  CURRENT_VIEW: 'CURRENT_VIEW',
  SURVEILLANCE_REPORT: 'SURVEILLANCE_REPORT',
};
export const MENU_LABELS = {
  [MENU_VALUES.CURRENT_VIEW]: intl('header.current_view'),
  [MENU_VALUES.SURVEILLANCE_REPORT]: intl('header.surveillance_report'),
};

const mapStateToProps = (state, props) => {
  const {
    JwtAuth: { csrftoken },
    reportsManager,
    firms,
  } = state;

  const currentUserAttrs = currentUserSelector(state);
  const jurisdictionAttrs = currentUserJurisdictionSelector(state);

  const surveillanceFirmId = _get(reportsManager, 'surveillance.firmId');
  const surveillanceFirmResource =
    surveillanceFirmId && firms.resources[`${surveillanceFirmId}`];

  return {
    csrftoken,
    currentUserAttrs,
    jurisdictionAttrs,
    surveillanceFirmId,
    surveillanceFirmResource,
    currentViewCallback: reportsManager.currentView.callback,
  };
};
const mapDispatchToProps = { createAlert, createReport };

export class ReportsManagerContainer extends React.Component {
  constructor() {
    super();
    this.state = { generateReportModalInfo: null };
  }

  showGenerateSurveillanceReport = (props = this.props, state = this.state) => {
    const {
      surveillanceFirmResource,
      jurisdictionAttrs,
      currentUserAttrs,
    } = props;
    const curDateStr = new Date().toLocaleDateString('en-US');
    const surveillanceReportModalInfo = {
      reportTitle: (
        <div>
          <FormattedMessage id="report.surveillance_report_name" /> {curDateStr}
        </div>
      ),
      firmName: _get(surveillanceFirmResource, 'attributes.firmName', ''),
      isImpersonating: isImpersonating(jurisdictionAttrs),
      bbgEmail: currentUserAttrs.bbgEmail,
      corpEmail: currentUserAttrs.corpEmail,
      description: (
        <div>
          <p>
            <FormattedMessage id="report.surveillance_info_paragraph" />
          </p>
          <p>
            <FormattedMessage id="report.this_report_includes_data_as_of" /> (
            {curDateStr}).
          </p>
        </div>
      ),
      additionalDatum: [
        {
          name: intl('report.data_covered'),
          value: (
            <div>
              <FormattedMessage id="report.all_data_as_of" /> {curDateStr}
            </div>
          ),
        },
      ],
    };

    this.setState({
      ...state,
      generateReportModalInfo: surveillanceReportModalInfo,
    });
  };

  onCancelGenerateReportModal = (props = this.props, state = this.state) => {
    this.setState({
      ...state,
      generateReportModalInfo: null,
    });
  };

  onGenerateReport = (props = this.props, state = this.state) => {
    const { surveillanceFirmId, createReport, createAlert, csrftoken } = props;

    createReport(SURVEILLANCE_REPORT_TYPE, csrftoken, {
      firmId: surveillanceFirmId,
    }).then(
      (v) => {
        debug(v);
        createAlert(ALERT_TYPES.INFO, intl('report.report_delivered'), 5000);
      },
      (r) => {
        debug('REJECT', r);
        createAlert(ALERT_TYPES.ERROR, intl('report.error_generating_report'));
      }
    );

    this.setState({
      ...state,
      generateReportModalInfo: null,
    });
  };

  dropdownItems = (props = this.props) => {
    const { surveillanceFirmResource, currentViewCallback } = props;

    const items = [
      {
        label: intl('header.export_report'),
        value: '',
      },
      {
        label: MENU_LABELS[MENU_VALUES.CURRENT_VIEW],
        value: MENU_VALUES.CURRENT_VIEW,
        disabled: !currentViewCallback,
      },
      ...(surveillanceFirmResource
        ? [
            {
              label: MENU_LABELS[MENU_VALUES.SURVEILLANCE_REPORT],
              value: MENU_VALUES.SURVEILLANCE_REPORT,
            },
          ]
        : []),
    ];

    return items;
  };

  onDropdownChange = (selectedItem, props = this.props) => {
    const { currentViewCallback } = props;

    if (
      MENU_VALUES.CURRENT_VIEW === selectedItem.value &&
      currentViewCallback
    ) {
      currentViewCallback();
    } else if (MENU_VALUES.SURVEILLANCE_REPORT === selectedItem.value) {
      this.showGenerateSurveillanceReport();
    }
  };

  render() {
    const self = this;
    const { generateReportModalInfo } = this.state;

    return (
      <div className="reportsManager">
        {generateReportModalInfo && (
          <GenerateReportModal
            {...generateReportModalInfo}
            onCancel={this.onCancelGenerateReportModal}
            onGenerate={this.onGenerateReport}
          />
        )}
        <DropdownBox
          items={this.dropdownItems()}
          selectedIndex={0}
          hideSelected={true}
          onChange={(selectedItem, selectedIndex) =>
            self.onDropdownChange(selectedItem)
          }
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsManagerContainer);
/* eslint-enable */
