/* eslint-disable */
// External Dependencies
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debugMod from 'debug';
import {
  jurisdictionDisplay,
  jurisdictionDescriptionDatum,
  isImpersonating,
} from '../utils/JurisdictionUtils';

// Actions
import {
  readUser,
  readCurrentUser,
  readJurisdiction,
} from '../store/apiActions';

// Components
import HeaderBar from '../components/HeaderBar';

// Utils
import { getLogoutUrl } from '../utils/JwtAuth';
import {
  currentUserAndStatusSelector,
  currentUserJurisdictionSelector,
} from '../store/selectors';
import { ROOT_LINK } from '../components/TabNav';
import { helpDocs } from '../utils/LinkUtils';
import { intl } from '../intl/IntlFormat';

// eslint-disable-next-line
const debug = debugMod('roster:HeaderBarContainer');

const mapStateToProps = (state, props) => {
  const { location } = props;
  const [currentUserAttrs, currentUserStatus] = currentUserAndStatusSelector(
    state
  );
  const jurisdictionAttrs = currentUserJurisdictionSelector(state);
  const { isInitialized, isAuthenticated } = { ...state.JwtAuth };
  const { isSuperUser } = { ...jurisdictionAttrs };

  return {
    location,
    isInitialized,
    isAuthenticated,
    isSuperUser,
    currentUserAttrs,
    currentUserStatus,
    jurisdictionAttrs,
  };
};

const mapDispatchToProps = { readUser, readCurrentUser, readJurisdiction };

export class HeaderBarContainer extends React.Component {
  componentDidMount() {
    const {
      currentUserAttrs,
      jurisdictionAttrs,
      readJurisdiction,
    } = this.props;
    if (currentUserAttrs && !jurisdictionAttrs) {
      this.readJurisdictionPromise = readJurisdiction(currentUserAttrs.uuid);
    }
  }

  userName() {
    const { currentUserAttrs } = this.props;
    if (currentUserAttrs) {
      return `${currentUserAttrs.firstName} ${currentUserAttrs.lastName[0]}.`;
    }
  }

  render() {
    const { location, isSuperUser, jurisdictionAttrs } = this.props;
    const tabs = {
      '/': intl('search.search'),
      ...(isSuperUser && {
        controlOwners: intl('control_owner.control_owner'),
      }),
    };
    return (
      <div className="headerBarWrapper">
        <div className="row">
          <HeaderBar
            userName={this.userName()}
            jurisdictionDisplay={jurisdictionDisplay(jurisdictionAttrs)}
            jurisdictionDatum={jurisdictionDescriptionDatum(jurisdictionAttrs)}
            isImpersonating={isImpersonating(jurisdictionAttrs)}
            tabs={tabs}
            selectedTab={
              location.pathname.startsWith('/controlOwners')
                ? 'controlOwners'
                : ROOT_LINK
            }
            logoutUrl={getLogoutUrl()}
            helpDocs={helpDocs}
          />
        </div>
      </div>
    );
  }
}

HeaderBarContainer.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBarContainer);
/* eslint-enable */
