import React from 'react';
import PropTypes from 'prop-types';

import './styles/EmailListCellTemplate.css';

const EmailListCellTemplate = ({ value }) => (
  <div className={'emailListCellTemplate'}>
    <ul>
      {Object.keys(value).map((emailType, i) =>
        emailType === 'bbgEmail' ? (
          <li key={i}>
            <a
              href={`https://blinks.bloomberg.com/msg/${value[emailType]}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {value[emailType]}
            </a>
          </li>
        ) : (
          <li key={i}>
            <a
              href={`mailto:${value[emailType]}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {value[emailType]}
            </a>
          </li>
        )
      )}
    </ul>
  </div>
);

EmailListCellTemplate.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string),
};
export const EmailListCellTemplateLoading = ({ count = 2 }) => (
  <div className="emailListCellTemplate loading">
    <ul>
      {Array.from(Array(count)).map((e, i) => (
        <li key={i} />
      ))}
    </ul>
  </div>
);

export default EmailListCellTemplate;
