// External dependencies
import { crudRequest } from 'redux-resource-xhr';
import present from 'present';
import debugMod from 'debug';

const debug = debugMod('roster:JwtAuth');
const timing = debugMod('roster:timing');

if (!window.location.origin) {
  window.location.origin = `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}`;
}

export const HOST_ORIGIN = window && window.location && window.location.origin;
export const API_HOST = process.env.REACT_APP_API_HOST || HOST_ORIGIN;
export const DEFAULT_REACT_APP_LOGIN_BASE_URL =
  'API_HOST/bvault/login?redirect_uri=HOST_ORIGIN';
export const LOGIN_BASE_URL = (
  process.env.REACT_APP_LOGIN_BASE_URL || DEFAULT_REACT_APP_LOGIN_BASE_URL
)
  .replace(/API_HOST/g, API_HOST)
  .replace(/HOST_ORIGIN/g, HOST_ORIGIN);
export const DEFAULT_REACT_APP_LOGOUT_BASE_URL =
  'API_HOST/bvault/logout?redirect_uri=HOST_ORIGIN';
export const LOGOUT_BASE_URL = (
  process.env.REACT_APP_LOGOUT_BASE_URL || DEFAULT_REACT_APP_LOGOUT_BASE_URL
)
  .replace(/API_HOST/g, API_HOST)
  .replace(/HOST_ORIGIN/g, HOST_ORIGIN);

const ACTIONTYPES = {
  AUTH_STATUS: 'AUTH_STATUS',
  SERVER_ERROR: 'SERVER_ERROR',
};

const ACTIONS = {
  setIsAuthenticated(isAuthenticated, headers) {
    return { type: ACTIONTYPES.AUTH_STATUS, isAuthenticated, headers };
  },

  setIsServerError(isServerError) {
    return { type: ACTIONTYPES.SERVER_ERROR, isServerError };
  },
};

const dispatchAuthStatus = (status, headers, dispatch) => {
  if (status === 0) {
    dispatch(ACTIONS.setIsServerError(true));
  } else if (status >= 200 && status <= 300) {
    dispatch(ACTIONS.setIsAuthenticated(true, headers));
    dispatch(ACTIONS.setIsServerError(false));
  } else if (status === 401) {
    dispatch(ACTIONS.setIsAuthenticated(false));
  }
};

export function getLoginUrl(redirectTo = '/', search = '') {
  return LOGIN_BASE_URL + redirectTo + search;
}

export function getLogoutUrl(redirectTo = '/', search = '') {
  return LOGOUT_BASE_URL + redirectTo + search;
}

export function jwtAuthReducer(
  state = {
    isInitialized: false,
    isAuthenticated: false,
    isServerError: false,
  },
  action
) {
  let newState = { ...state };
  switch (action.type) {
    case ACTIONTYPES.AUTH_STATUS:
      newState.isInitialized = true;
      if (undefined !== action.isAuthenticated) {
        newState = {
          ...newState,
          isAuthenticated: action.isAuthenticated,
          ...action.headers,
        };
      }
      return newState;
    case ACTIONTYPES.SERVER_ERROR:
      newState.isInitialized = true;
      if (undefined !== action.isServerError) {
        newState = { ...newState, isServerError: action.isServerError };
      }
      return newState;
    default:
      return state;
  }
}

const timingLoggers = {};
function timingLogger(action) {
  let logger = timing;
  const key = action.requestKey;
  if (key) {
    logger = timingLoggers[key];
    if (!logger) {
      logger = debugMod(`roster:timing:${key}`);
      timingLoggers[key] = logger;
    }
  }
  return logger;
}
function timingStats(startTime, action) {
  const endTime = present();
  const elapsedTime = endTime - startTime;
  const { requestKey, resourceType, res } = action;
  const { url, statusCode } = res;
  return {
    requestKey,
    resourceType,
    url,
    statusCode,
    startTime,
    endTime,
    elapsedTime,
    action,
  };
}
function logTiming(startTime, action) {
  const stats = timingStats(startTime, action);
  const { requestKey, elapsedTime } = stats;
  const logger = timingLogger(action);

  logger(`[${requestKey}] ${elapsedTime.toFixed(2)}ms elapsed`, stats);

  return stats;
}

export function jwtCrudRequest(crudAction, options) {
  return new Promise((resolve, reject) => {
    // wrap XHR in Promise
    const crudOptions = {
      ...options,
      xhrOptions: {
        ...(options && options.xhrOptions),
        headers: {
          ...(options && options.xhrOptions && options.xhrOptions.headers),
        },
        withCredentials: true,
      },
      onFailed: (action, err, res) => {
        logTiming(startTime, action);
        options.onFailed
          ? options.onFailed(action, err, res)
          : options.dispatch(action);
        dispatchAuthStatus(res.statusCode, res.headers, options.dispatch);
        debug(
          '[CRUD Failure]',
          action.resourceType,
          action.resources,
          action.requestProperties
        );
        reject(action);
      },
      onSucceeded: (action, res, body) => {
        logTiming(startTime, action);
        options.onSucceeded
          ? options.onSucceeded(action, res, body)
          : options.dispatch(action);
        dispatchAuthStatus(res.statusCode, res.headers, options.dispatch);

        try {
          resolve(body.data ? body.data : body);
        } catch (error) {
          reject(error);
        }
      },
    };

    debug(
      '[Requesting]',
      crudOptions.xhrOptions.method,
      crudOptions.xhrOptions.url
    );
    let startTime = present();
    return crudRequest(crudAction, crudOptions);
  });
}
