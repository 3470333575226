/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { cloneDeep, isEqual, isEmpty, set, unset } from 'lodash';

import FontIcon from './FontIcon';

import { queryStringStringify } from '../utils/QueryStringParser';

import './styles/RosterFacetPanel.css';
import { FormattedMessage } from 'react-intl';

class Filter extends React.Component {
  render() {
    const { spec, filterState, onFilterChange } = this.props;

    const {
      label,
      filterKey,
      defaultValue,
      spec: { renderer: Renderer },
    } = spec;

    return (
      <div className="filter">
        {!!label && <span className="filterLabel">{label}</span>}
        <Renderer
          filterState={filterState[filterKey] || defaultValue}
          onFilterChange={(newValue) => {
            // If new value equals default value, set it to undefined so field is not
            // included in actual query
            onFilterChange(
              filterKey,
              defaultValue === newValue ? undefined : newValue
            );
          }}
        />
      </div>
    );
  }
}

class FilterSection extends React.Component {
  state = {
    collapsed: false,
  };

  toggleCollapsed = () => {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  };

  render() {
    const { spec, filterState, onFilterChange } = this.props;
    const { collapsed } = this.state;

    return (
      <div className={`filterSection ${collapsed ? 'collapsed' : ''}`}>
        {!!spec.label && (
          <span className="sectionLabel">
            {spec.collapsible && (
              <FontIcon
                icon={collapsed ? 'arrow-down' : 'arrow-up'}
                onClick={this.toggleCollapsed}
              />
            )}
            {spec.label}
          </span>
        )}
        <div className="filters">
          {spec.filters.map((filter, index) => (
            <Filter
              spec={filter}
              filterState={filterState}
              key={index}
              onFilterChange={onFilterChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

class RosterFacetPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModified: false,
      filterState: props.appliedFilters,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(this.props.appliedFilters, prevProps.appliedFilters)) {
      this.setState({
        filterState: this.props.appliedFilters,
      });
    }
  }

  getFilterQueryString = (filter) => {
    const { tab, queryParams } = this.props;

    const newQueryParams = cloneDeep(queryParams);
    set(newQueryParams, `tabStates.${tab}.filters`, filter);
    unset(newQueryParams, `tabStates.${tab}.page`);

    return queryStringStringify(newQueryParams);
  };

  onFilterChange = (filterKey, filterValue) => {
    const newFilterState = cloneDeep(this.state.filterState);
    set(newFilterState, `${filterKey}`, filterValue);
    this.setState({
      ...this.state,
      hasModification: true,
      filterState: newFilterState,
    });
  };

  onResetFilter = () => {
    const { onCommitFilter } = this.props;
    this.setState({
      ...this.state,
      hasModification: false,
      filterState: {},
    });
    onCommitFilter();
  };

  onApplyFilter = () => {
    const { appliedFilters, onCommitFilter } = this.props;
    this.setState({
      ...this.state,
      hasModification: false,
      filterState: appliedFilters,
    });
    onCommitFilter();
  };

  render() {
    const { spec, pathname, appliedFilters } = this.props;

    const { hasModification, filterState } = this.state;

    const isAppliedFilterEmpty = isEmpty(appliedFilters);

    return (
      <div className="rosterFacetPanel">
        {spec.sections.map((section, index) => (
          <FilterSection
            spec={section}
            filterState={filterState}
            key={index}
            onFilterChange={this.onFilterChange}
          />
        ))}
        <div
          className={`controls ${
            !isAppliedFilterEmpty || hasModification ? 'wide' : ''
          }`}
        >
          {
            // If appled filter (from url) is not empty, display <Link> to remove filters from url
            !isAppliedFilterEmpty ? (
              <Link
                to={`${pathname}${this.getFilterQueryString({})}`}
                className="resetButton"
                onClick={this.onResetFilter}
              >
                <FormattedMessage id="utility.reset" />
              </Link>
            ) : (
              // If applied filter (from url) is empty but has uncommited modifications, display <a>
              // to reset to default
              // eslint-disable-next-line
              hasModification && (
                <a className="resetButton" onClick={this.onResetFilter}>
                  <FormattedMessage id="utility.reset" />
                </a>
              )
            )
          }
          {hasModification ? (
            <Link
              to={`${pathname}${this.getFilterQueryString(filterState)}`}
              onClick={this.onApplyFilter}
              className="applyButton"
            >
              <FormattedMessage id="utility.apply" />
            </Link>
          ) : (
            // eslint-disable-next-line
            <a className="applyButton disabled">
              <FormattedMessage id="utility.apply" />
            </a>
          )}
        </div>
      </div>
    );
  }
}

const FilterSpecShape = PropTypes.shape({
  label: PropTypes.string,
  filterKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  spec: PropTypes.shape({
    renderer: PropTypes.func,
  }),
});

const SectionSpecShape = PropTypes.shape({
  label: PropTypes.string,
  filters: PropTypes.arrayOf(FilterSpecShape).isRequired,
});

const SpecShape = PropTypes.shape({
  sections: PropTypes.arrayOf(SectionSpecShape).isRequired,
});

RosterFacetPanel.propTypes = {
  spec: SpecShape.isRequired,
  appliedFilters: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  onCommitFilter: PropTypes.func,
  queryParams: PropTypes.object,
};

export default RosterFacetPanel;
/* eslint-enable */
