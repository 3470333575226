/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { getStatus } from 'redux-resource';
import { Route, Redirect, Switch } from 'react-router';
import debugMod from 'debug';

import { SCOPE_TYPE } from '../PropTypes';
import { readFirm } from '../store/apiActions';
import { queryStringParse } from '../utils/QueryStringParser';

import RosterContainer from './RosterContainer';
import ScopeSearchContainer from './ScopeSearchContainer';
import UserProfileContainer from './UserProfileContainer';

import FirmHeader, {
  FirmBadge,
  FirmBadgeLoading,
} from '../components/FirmHeader';
import Error from '../components/Error';

import './styles/FirmContainer.css';
import { matchedFirmSelector } from '../store/selectors';
import { intl } from '../intl/IntlFormat';

const debug = debugMod('roster:FirmContainer');

const mapStateToProps = (state, props) => {
  const { match, location } = props;
  const { firmId, firmTab, subFirmTab } = match.params;

  const firmResource = matchedFirmSelector(state, props);
  const firmStatus = getStatus(state, `firms.meta.${firmId}.readStatus`, false);
  const contractStatus = getStatus(
    state,
    `contracts.meta.${firmId}.readStatus`,
    false
  );
  const queryParams = queryStringParse(location.search);

  let scopeInfo;
  if (!isNaN(queryParams.cust)) {
    scopeInfo = {
      scopeType: SCOPE_TYPE.CUST,
      scopeId: parseInt(queryParams.cust, 10),
      scopeName: '',
    };
  } else if (!isNaN(queryParams.caid)) {
    scopeInfo = {
      scopeType: SCOPE_TYPE.CAID,
      scopeId: parseInt(queryParams.caid, 10),
      scopeName: '',
    };
  }

  return {
    firmId,
    firmTab,
    subFirmTab,
    firmResource,
    firmStatus,
    contractStatus,
    scopeInfo,
    queryParams,
  };
};
const mapDispatchToProps = { readFirm };

export class FirmContainer extends React.Component {
  state = {
    showScopeSearchPanel: false,
  };

  componentDidMount() {
    this.readFirm();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { firmId: currFirmId, firmResource } = this.props;
    const { firmId: prevFirmId } = prevProps;

    // Read firm if
    // 1. firm resource is undefined or
    // 2. firm id changed
    if (!firmResource || currFirmId !== prevFirmId) {
      this.readFirm();
    }
  }

  readFirm = (props = this.props) => {
    const { firmStatus, readFirm, firmId } = props;

    // Don't read firm if it's already pending or failed
    if (!firmStatus || !(firmStatus.pending || firmStatus.failed)) {
      debug(`Reading firm ${firmId}`);
      this.readFirmPromise = readFirm({ firmId });
    }
  };

  changeScopeOnClick = (event) => {
    event.preventDefault();

    this.setState({
      ...this.state,
      showScopeSearchPanel: true,
    });
  };

  clearScopeOnClick = (event) => {
    event.preventDefault();

    const { location, history } = this.props;

    debug(`Clearing scope, redirecting to ${location.pathname}`);

    history.push(location.pathname);
  };

  onCloseSearchPanel = () => {
    this.setState({
      ...this.state,
      showScopeSearchPanel: false,
    });
  };

  firmElement(props = this.props) {
    const { firmResource, firmStatus, scopeInfo } = props;

    if (firmResource) {
      return (
        <FirmHeader
          firmBadge={
            <FirmBadge
              firm={{
                firmId: firmResource.id,
                firmName: firmResource.attributes.firmName,
              }}
              scope={scopeInfo}
              changeScopeOnClick={this.changeScopeOnClick}
              clearScopeOnClick={this.clearScopeOnClick}
            />
          }
          infoElement={this.infoElement()}
        />
      );
    }
    if (firmStatus && firmStatus.failed) {
      return (
        <FirmHeader
          firmBadge={<Error className="firmBadge" />}
          infoElement={<Error className="contractsInfo" />}
        />
      );
    }
    return (
      <FirmHeader
        firmBadge={<FirmBadgeLoading />}
        infoElement={this.infoElement()}
      />
    );
  }

  infoElement(props = this.props) {
    return <div />;
  }

  render() {
    const { firmId, firmTab, subFirmTab, location, history } = this.props;

    const { showScopeSearchPanel } = this.state;

    if (!subFirmTab) {
      return (
        <Redirect
          to={`/firm/${firmId}/${firmTab}/monitoredEmployees${location.search}`}
        />
      );
    }

    return (
      <div className="firmContainer">
        <div className="firmHeaderNav">
          <div className="row">{this.firmElement()}</div>
        </div>
        {showScopeSearchPanel && (
          <ScopeSearchContainer
            subtabOverride={subFirmTab}
            showInstructionPanel={true}
            resetLink={location.pathname}
            instructionText={intl('firm.search_for_caid_or_customer_account')}
            history={history}
            onClosePanel={this.onCloseSearchPanel}
          />
        )}
        <div className={`rosterPanel${showScopeSearchPanel ? ' hidden' : ''}`}>
          <Switch>
            <Route
              path="/firm/:firmId/:firmTab(roster)/:subFirmTab/userProfile/:userIdType(u|c)/:userId(\d+)"
              component={UserProfileContainer}
            />
            <Route
              path="/firm/:firmId/:firmTab(roster)/:subFirmTab"
              component={RosterContainer}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirmContainer);
/* eslint-enable */
