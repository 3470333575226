import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DatumHeroNav from '../DatumHeroNav';
import Error from '../Error';
import FontIcon from '../FontIcon';
import { FormattedMessage } from 'react-intl';

import { TabsShape } from '../../PropTypes';
import { intl } from '../../intl/IntlFormat';

import './styles/UserProfile.css';

class UserProfile extends React.Component {
  getUserIdString(userIds) {
    let ids = [];

    if (userIds.uuid) {
      ids.push(`UUID: ${userIds.uuid}`);
    }

    if (userIds.cuid) {
      ids.push(`CUID: ${userIds.cuid}`);
    }

    if (ids.length > 0) {
      return `(${ids.join('/')})`;
    } else {
      return '';
    }
  }

  render() {
    const {
      userInfo,
      isUserInfoLoading,
      isUserInfoError,
      tab,
      tabsInfo,
      onBackButtonClick = () => {},
      children,
    } = this.props;

    return (
      <div className="userProfile">
        <div className="userProfileHeader">
          <div className="backButtonControl row">
            <span className="backButton" onClick={onBackButtonClick}>
              <FontIcon icon="arrow-line-left" />
              <span className="backButtonLabel">
                <FormattedMessage id="user.all_members" />
              </span>
            </span>
          </div>
          <div className="basicUserInfo row">
            {isUserInfoError ? (
              <Error />
            ) : (
              <Fragment>
                <span
                  className={'fullName' + (isUserInfoLoading ? ' loading' : '')}
                >
                  {userInfo.fullName || intl('user.name_not_available')}
                </span>
                <span
                  className={'uuidCuid' + (isUserInfoLoading ? ' loading' : '')}
                >
                  {this.getUserIdString(userInfo.userIds)}
                </span>
              </Fragment>
            )}
          </div>
        </div>
        <div className="row">
          <DatumHeroNav
            tabs={tabsInfo}
            selectedIndex={tabsInfo.findIndex((el) => el.name === tab)}
          />
        </div>
        <div className="tabContent">
          <div className="row">{children}</div>
        </div>
      </div>
    );
  }
}

const UserIdShape = {
  uuid: PropTypes.number,
  cuid: PropTypes.number,
};

const UserInfoShape = {
  fullName: PropTypes.string,
  userIds: PropTypes.shape(UserIdShape),
};

UserProfile.propTypes = {
  userInfo: PropTypes.shape(UserInfoShape).isRequired,
  isUserInfoLoading: PropTypes.bool.isRequired,
  isUserInfoError: PropTypes.bool.isRequired,
  tab: PropTypes.string.isRequired,
  tabsInfo: PropTypes.arrayOf(TabsShape).isRequired,
  onBackButtonClick: PropTypes.func,
};

export default UserProfile;
