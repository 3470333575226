import * as enLang from './translations/en.json';
import * as jaLang from './translations/ja.json';
import * as koLang from './translations/ko.json';
import * as frLang from './translations/fr.json';
import * as deLang from './translations/de.json';
import * as esLang from './translations/es.json';
import * as itLang from './translations/it.json';
import * as ptLang from './translations/pt.json';
import * as ruLang from './translations/ru.json';
import * as zhLang from './translations/zh.json';
import * as ztLang from './translations/zt.json';
import { browserLanguage } from '../utils/Browser';

const messageMap = {
  en: enLang.default,
  ja: jaLang.default,
  ko: koLang.default,
  fr: frLang.default,
  de: deLang.default,
  es: esLang.default,
  it: itLang.default,
  pt: ptLang.default,
  ru: ruLang.default,
  zh: zhLang.default,
  zt: ztLang.default,
};

let language = browserLanguage();

// For unsupported languages, default to English
if (!messageMap[language]) {
  language = 'en';
}

export const locale = language;

// Use English messages where locale translations may be incomplete
export const messages = { ...messageMap.en, ...messageMap[locale] };
