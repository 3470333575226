/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { SearchResultsSectionType, SearchResultType } from '../PropTypes';

import './styles/Search.css';
import FontIcon from './FontIcon';
import Loader from './Loader';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export const SearchInput = ({
  placeholder,
  value = '',
  onChange = () => {},
  onKeyDown = () => {},
}) => (
  <span className="searchInput">
    <FontIcon icon="search" />
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={(event) => {
        event.preventDefault();
        onChange(event.target.value);
      }}
      onKeyDown={onKeyDown}
    />
  </span>
);
SearchInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export const NoResult = () => (
  <div className="noResult">
    <FormattedMessage id="search.no_results_found" />
  </div>
);

export const SearchResult = ({
  id,
  linkTo,
  displayName,
  meta,
  selected = false,
  clickInfo = {},
  onClickResult = () => {},
}) => (
  <Link
    to={linkTo}
    onClick={(e) => {
      onClickResult(e, clickInfo);
    }}
  >
    <div className={`searchResult ${selected ? 'selected' : ''}`}>
      <div className="resultPrimary">
        <span className="resultDisplayName">{displayName}</span>
        <span className="resultId">(ID: {id})</span>
      </div>
      <div className="resultSecondary">
        <span className="resultMeta">{meta}</span>
      </div>
    </div>
  </Link>
);
SearchResult.propTypes = SearchResultType;

export const SearchResultsSection = (
  {
    sectionName,
    totalCount,
    results,
    runningIndex = 0,
    selectedResult = 0,
    onClickResult = () => {},
  } // Not translating <count> Results since this building this string is not directly translatable
) => (
  <div className="searchResultsSection">
    <div className="sectionHeader">
      <h1>{sectionName}</h1>
      <span>{totalCount} Results</span>
    </div>
    <div className="sectionBody">
      {results.map((r, i) => (
        <SearchResult
          key={i}
          {...r}
          selected={
            selectedResult > 0 && runningIndex + i === selectedResult - 1
          }
          onClickResult={onClickResult}
        />
      ))}
      {results.length === 0 && <NoResult />}
    </div>
  </div>
);
SearchResultsSection.propTypes = SearchResultsSectionType;

export const SearchResults = ({
  sections,
  selectedResult = 0,
  onClickResult = () => {},
}) => {
  let runningIndex = 0;
  return (
    <div className="searchResults">
      {sections ? (
        sections.map((section, i) => {
          const result = (
            <SearchResultsSection
              key={i}
              {...section}
              runningIndex={runningIndex}
              selectedResult={selectedResult}
              onClickResult={onClickResult}
            />
          );
          runningIndex += section.results.length;
          return result;
        })
      ) : (
        <Loader />
      )}
    </div>
  );
};
SearchResults.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape(SearchResultsSectionType)),
  onClickResult: PropTypes.func,
};
/* eslint-enable */
