import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';

import App from './App';
import { messages, locale } from './intl';

import './index.css';

ReactDOM.render(
  <IntlProvider locale={locale} messages={messages}>
    <App />
  </IntlProvider>,
  document.getElementById('root')
);
