import React from 'react';
import PropTypes from 'prop-types';

import './styles/UserInfoCellTemplate.css';
import { Link } from 'react-router-dom';
import { intl } from '../../intl/IntlFormat';

const UserInfoCellTemplate = ({ value }) => (
  <div
    className={
      'userInfoCellTemplate' +
      (value.isNameUnknown ? ' name-unknown' : '') +
      (value.isUserDeleted ? ' user-deleted' : '') +
      (value.isUserInactive ? ' user-inactive' : '')
    }
  >
    {value.link ? (
      <Link to={value.link} className="name">
        {value.fullName || intl('user.name_not_available')}
      </Link>
    ) : (
      <p className="name">
        {value.fullName || intl('user.name_not_available')}
      </p>
    )}
    {value.uuid > 0 && (
      <p className="id">
        <span className="sup">UUID:</span> {value.uuid}
      </p>
    )}
    {value.cuid > 0 && (
      <p className="id">
        <span className="sup">CUID:</span> {value.cuid}
      </p>
    )}
  </div>
);

const UserInfoShape = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  uuid: PropTypes.number,
  cuid: PropTypes.number,
  isNameUnknown: PropTypes.bool,
  isUserDeleted: PropTypes.bool,
  isUserInactive: PropTypes.bool,
});

UserInfoCellTemplate.propTypes = {
  value: UserInfoShape.isRequired,
};
export const UserInfoCellTemplateLoading = () => (
  <div className="userInfoCellTemplate loading">
    <p className="name" />
    <p className="id" />
    <p className="id" />
  </div>
);

export default UserInfoCellTemplate;
