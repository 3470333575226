import { injectIntl } from 'react-intl';

function formatMessageIntl({ intl, id }) {
  return intl.formatMessage({ id });
}

const formatMessageFromId = injectIntl(formatMessageIntl);

export function intl(id) {
  return formatMessageFromId({ id });
}
