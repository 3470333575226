import React from 'react';
import PropTypes from 'prop-types';

import './styles/ApplicationStatusCellTemplate.css';
import FontIcon from '../FontIcon';

export const ApplicationStatusCellTemplate = ({ value }) => (
  <div className="applicationStatusCellTemplate">
    <b>{value.name}</b>
    {value.alert && <FontIcon icon="circle-alert" />}
  </div>
);

const ApplicationStatusShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  alert: PropTypes.boolean,
});
ApplicationStatusCellTemplate.propTypes = {
  value: ApplicationStatusShape.isRequired,
};

export const ApplicationStatusCellTemplateLoading = ({ value }) => (
  <div className="applicationStatusCellTemplate loading">{value}</div>
);

export default ApplicationStatusCellTemplate;
