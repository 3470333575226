/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import FontIcon from './FontIcon';

import './styles/DropdownBox.css';

/// ////////////////
// Dropdown Item //
/// ////////////////
const DefaultItemRenderer = ({ label, value }) => <div>{label}</div>;

class DropdownItem extends React.Component {
  render() {
    const { spec, selected, index, onClick = () => {} } = this.props;

    const {
      label,
      value,
      disabled = false,
      renderer: Renderer = DefaultItemRenderer,
    } = spec;

    return (
      <div
        className={`dropdownItem${selected ? ' selected' : ''}${
          disabled ? ' disabled' : ''
        }`}
        onClick={() => {
          onClick(spec, index);
        }}
      >
        <Renderer label={label} value={value} />
      </div>
    );
  }
}
const DropdownItemSpecShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderer: PropTypes.func,
});
DropdownItem.propTypes = {
  spec: DropdownItemSpecShape,
  selected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

/// //////////////
// DropdownBox //
/// //////////////

class DropdownBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };

    this.showMenuAction = this.showMenuAction.bind(this);
    this.removeCloseMenuListener = this.removeCloseMenuListener.bind(this);
    this.closeMenuAction = this.closeMenuAction.bind(this);
    this.selectionChange = this.selectionChange.bind(this);
  }

  showMenuAction(event) {
    event.preventDefault();

    this.setState({ ...this.state, showMenu: true }, () => {
      document.addEventListener('click', this.closeMenuAction);
    });
  }

  removeCloseMenuListener() {
    document.removeEventListener('click', this.closeMenuAction);
  }

  closeMenuAction(event) {
    if (this.selectionMenu && !this.selectionMenu.contains(event.target)) {
      this.setState({ ...this.state, showMenu: false }, () => {
        this.removeCloseMenuListener();
      });
    }
  }

  selectionChange(selectedItem, selectedIndex) {
    const { onChange = () => {} } = this.props;

    onChange(selectedItem, selectedIndex);

    this.setState({
      ...this.state,
      showMenu: false,
    });
    this.removeCloseMenuListener();
  }

  render() {
    const { items = [], selectedIndex } = this.props;

    const { showMenu } = this.state;

    const selectedItem = items[selectedIndex];
    const SelectedItemRenderer = selectedItem.renderer || DefaultItemRenderer;

    return (
      <div className="dropdownBox">
        <div
          className={`currentSelection${showMenu ? ' showMenu' : ''}`}
          onClick={this.showMenuAction}
        >
          <div className="selectedItem">
            <SelectedItemRenderer
              label={selectedItem.label}
              value={selectedItem.value}
            />
          </div>
          <FontIcon icon={showMenu ? 'arrow-up' : 'arrow-down'} />
        </div>
        <div
          className={`selectionMenu${showMenu ? '' : ' collapsed'}`}
          ref={(element) => (this.selectionMenu = element)}
        >
          {items.map((item, index) => (
            <DropdownItem
              key={index}
              spec={item}
              selected={index === selectedIndex}
              index={index}
              onClick={this.selectionChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

DropdownBox.propTypes = {
  items: PropTypes.arrayOf(DropdownItemSpecShape).isRequired,
  disabled: PropTypes.bool,
  selectedIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

export default DropdownBox;

export { DropdownItem };
/* eslint-enable */
