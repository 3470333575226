import React from 'react';
import PropTypes from 'prop-types';

import './styles/AppStatusCellTemplate.css';

const AppStatusCellTemplate = (IconRenderer, SupportedStatuses) => {
  const CellTemplate = ({ value }) => (
    <div className="appStatusCellTemplate">
      <IconRenderer status={value.status} />
    </div>
  );

  CellTemplate.propTypes = {
    value: PropTypes.shape({
      status: PropTypes.oneOf(Object.values(SupportedStatuses)).isRequired,
    }),
  };

  return CellTemplate;
};

export const AppStatusCellTemplateLoading = () => (
  <div className="appStatusCellTemplate loading">
    <span />
  </div>
);

export default AppStatusCellTemplate;
