import { countries } from 'country-data';
import { get, capitalize, partition } from 'lodash';
import debugMod from 'debug';
import { intl } from '../intl/IntlFormat';

// eslint-disable-next-line
const debug = debugMod('roster:CountryCodes');

export function expandCountryCode(code) {
  return countries[code] ? countries[code].name : code;
}

export function expandCountryCodes(codes) {
  return codes.map((c) => expandCountryCode(c));
}

export function friendlyScope(scope) {
  return scope
    .split('_')
    .map((j) => capitalize(j))
    .join(' ');
}

export function jurisdictionDisplay(jurisdiction = {}) {
  if (jurisdiction.isInternalUser) {
    return 'Bloomberg Internal';
  }
  if (jurisdiction.scope) {
    return `Jurisdiction: ${friendlyScope(jurisdiction.scope)}`;
  }
  return '';
}

export function jurisdictionDescriptionDatum(jurisdiction = {}) {
  let result = {};
  switch (jurisdiction.scope) {
    case 'GLOBAL':
      result = { name: intl('control_owner.regions'), value: 'Global' };
      break;
    case 'COUNTRY':
      result = {
        name: intl('control_owner.country_only'),
        value: expandCountryCodes(jurisdiction.scopedEntities).join(', '),
      };
      break;
    case 'REGION':
      result = {
        name: intl('control_owner.region_only'),
        value: jurisdiction.scopedEntities.join(', '),
      };
      break;
    case 'ENTITY':
      result = { name: 'Entities Only', value: '' };
      break;
    case 'COUNTRY_AND_ENTITY':
      result = {
        name: 'Country & Entities',
        value: expandCountryCodes(jurisdiction.scopedEntities).join(', '),
      };
      break;
    case 'REGION_AND_COUNTRY':
      result = {
        name: 'Region & Country',
        value: expandCountryCodes(jurisdiction.scopedEntities).join(', '),
      };
      break;
    case 'REGION_AND_ENTITY':
      result = {
        name: 'Region & Entities',
        value: jurisdiction.scopedEntities.join(', '),
      };
      break;
    case 'REGION_AND_COUNTRY_AND_ENTITY':
      result = {
        name: 'Region, Country & Entities',
        value: expandCountryCodes(jurisdiction.scopedEntities).join(', '),
      };
      break;
    default:
      result = {
        name: get(jurisdiction, 'scope', ''),
        value: get(jurisdiction, 'scopedEntities', []).join(', '),
      };
      break;
  }
  return result;
}

export function isImpersonating(jurisdiction = {}) {
  return !!jurisdiction.impersonatorUuid;
}

const REGIONS = ['Americas', 'Asia Pac', 'Europe/Africa', 'GLOBAL'];
export function regionsAndCountriesForJurisdiction(jurisdiction = {}) {
  const entities = get(jurisdiction, 'attributes.scopedEntities', []);

  return partition(entities, (v) => REGIONS.includes(v));
}
