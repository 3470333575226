import React from 'react';
import PropTypes from 'prop-types';
import debugMod from 'debug';

import { get as _get, capitalize as _capitalize } from 'lodash';

import Grid, {
  GridLoading,
  GridMessage,
  PagingControlShape,
} from '../grid/Grid';
import AttributeListCellTemplate, {
  AttributeListCellTemplateLoading,
} from '../grid/AttributeListCellTemplate';
import UserInfoCellTemplate, {
  UserInfoCellTemplateLoading,
} from '../grid/UserInfoCellTemplate';
import {
  fullNameFromUserAttributes,
  userIsUnknown,
  userIsDeleted,
  userIsInactive,
} from '../../utils/ProfileUtils';
import { regionsAndCountriesForJurisdiction } from '../../utils/JurisdictionUtils';
import { userProfileLink, firmLink } from '../../utils/LinkUtils';
import { intl } from '../../intl/IntlFormat';

import './styles/ControlOwnersApp.css';
import GenerateReportModal from '../GenerateReportModal';
import EmailListCellTemplate, {
  EmailListCellTemplateLoading,
} from '../grid/EmailListCellTemplate';
import FontIcon from '../FontIcon';
import Error from '../Error';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line
const debug = debugMod('roster:ControlOwnersApp');

export const CONTROL_OWNERS_PRE_HEADERS = [
  { title: intl('control_owner.firm'), className: 'align-left', colSpan: 2 },
  {
    title: intl('control_owner.control_owner'),
    className: 'align-left',
    colSpan: 3,
  },
  //{title: 'UUID', className: 'align-left', colSpan: 3},
  {
    title: intl('control_owner.jurisdiction_coverage'),
    className: 'align-left',
    colSpan: 3,
  },
  {
    title: intl('control_owner.membership'),
    className: 'align-left',
    colSpan: 3,
  },
  { title: intl('control_owner.email'), className: 'align-left', colSpan: 1 },
];
export const CONTROL_OWNERS_COLUMNS = [
  { title: '#', className: 'align-left firm-id-column' }, // Firm Id
  {
    title: intl('control_owner.name'),
    className: 'align-left firm-name-column',
  }, // Firm Name
  {
    title: intl('control_owner.name'),
    className: 'align-left user-name-column',
  }, // User Name
  //{title: 'UUID', className: 'align-left user-uuid-column'},               // User UUID
  {
    title: intl('control_owner.status'),
    className: 'align-left user-status-column',
  }, // User Status
  {
    title: intl('control_owner.lic_type_header'),
    className: 'align-left user-lic-column',
  }, // User Lic
  {
    title: intl('control_owner.type'),
    className: 'align-left jurisdiction-type-column',
  }, // Jurisdiction Type
  {
    title: intl('control_owner.region'),
    className: 'align-left jurisdiction-region-column',
  }, // Jursidiction Region
  {
    title: intl('control_owner.country'),
    className: 'align-left jurisdiction-country-column',
  }, // Jursidiction Country
  {
    title: intl('control_owner.cus_num_header'),
    className: 'align-left cust-id-column',
  }, // Cust id
  {
    title: intl('control_owner.region'),
    className: 'align-left cust-region-column',
  }, // Cust Region
  {
    title: intl('control_owner.country'),
    className: 'align-left cust-country-column',
  }, // Cust Country
  {
    title: intl('control_owner.email'),
    className: 'align-left user-email-column',
  }, // User Email
];

export const CONTROL_OWNERS_ROW_TEMPLATES = {
  controlOwner: {
    cells: [
      {
        // Firm Id
        mapDataToProps: (data, firmId = _get(data, 'firm.id')) => ({
          showAttributeKeys: false,
          attributes: [{ value: firmId }],
          link: firmLink({ firmId }),
        }),
        cellTemplate: AttributeListCellTemplate,
      },
      {
        // Firm Name
        mapDataToProps: (data, firmId = _get(data, 'firm.id')) => ({
          showAttributeKeys: false,
          attributes: [{ value: _get(data, 'firm.attributes.firmName') }],
          link: firmLink({ firmId }),
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        // User Name
        mapDataToProps: (
          data,
          userAttrs = _get(data, 'user.attributes', {})
        ) => ({
          fullName: fullNameFromUserAttributes(userAttrs),
          uuid: userAttrs.uuid,
          isNameUnknown: userIsUnknown(userAttrs),
          isUserDeleted: userIsDeleted(userAttrs),
          isUserInactive: userIsInactive(userAttrs),
          link: userProfileLink({
            firmId: userAttrs.firmId,
            userIdType: 'u',
            userId: userAttrs.uuid,
          }),
        }),
        cellTemplate: UserInfoCellTemplate,
      },
      // { // User UUID
      //   mapDataToProps: (data) => ({ showAttributeKeys: false, attributes: [{ value: _get(data, 'user.id') }]}),
      //   cellTemplate: AttributeListCellTemplate,
      //   className: 'textAttributes'
      // },
      {
        // User Status
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [
            {
              value:
                _get(data, 'user.attributes.isInactive') === 'Y'
                  ? 'Inactive'
                  : 'Active',
            },
          ],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        // User Lic
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [{ value: _get(data, 'user.attributes.licenseType') }],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        // Jurisdiction Type
        mapDataToProps: (
          data,
          userAttrs = _get(data, 'user.attributes', {})
        ) => ({
          showAttributeKeys: false,
          attributes: [
            { value: _capitalize(_get(data, 'jurisdiction.attributes.scope')) },
          ],
          link: '/controlOwners/jurisdictions/' + userAttrs.uuid + '/show',
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        // Jursidiction Region
        mapDataToProps: (data) => {
          const regions = regionsAndCountriesForJurisdiction(
            data.jurisdiction
          )[0];
          return {
            showAttributeKeys: false,
            attributes: regions.length
              ? regions.map((v) => ({ value: v }))
              : [{ value: '--' }],
          };
        },
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        // Jursidiction Country
        mapDataToProps: (data) => {
          const countries = regionsAndCountriesForJurisdiction(
            data.jurisdiction
          )[1];
          return {
            showAttributeKeys: false,
            attributes: countries.length
              ? countries.map((v) => ({ value: v }))
              : [{ value: '--' }],
          };
        },
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        // Cust id
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [{ value: _get(data, 'cust.id') }],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        // Cust Region
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [{ value: _get(data, 'cust.attributes.region') }],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        // Cust Country
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [{ value: _get(data, 'cust.attributes.country') }],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        // User Email
        mapDataToProps: (data) => ({
          bbgEmail: [_get(data, 'user.attributes.bbgEmail')],
          corpEmail: [_get(data, 'user.attributes.corpEmail')],
        }),
        cellTemplate: EmailListCellTemplate,
      },
    ],
  },
};
export const CONTROL_OWNERS_LOADING_ROW_TEMPLATE = [
  {
    // Firm Id
    cellTemplate: AttributeListCellTemplateLoading,
  },
  {
    // Firm Name
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
  {
    // User Name
    cellTemplate: UserInfoCellTemplateLoading,
  },
  {
    // User UUID
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
  {
    // User Status
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
  {
    // User Lic
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
  {
    // Jurisdiction Type
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
  {
    // Jursidiction Region
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
  {
    // Jursidiction Country
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
  {
    // Cust id
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
  {
    // Cust Region
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
  {
    // Cust Country
    cellTemplate: AttributeListCellTemplateLoading,
    className: 'textAttributes',
  },
  {
    // User Email
    cellTemplate: EmailListCellTemplateLoading,
  },
];

export const ControlOwnersGrid = ({
  rows,
  className = '',
  pagingControl = {
    nextLink: '',
    loadingNext: false,
    previousLink: '',
    loadingPrevious: false,
  },
}) => (
  <div className={`controlOwnersGrid ${className}`}>
    <Grid
      preHeaders={CONTROL_OWNERS_PRE_HEADERS}
      columns={CONTROL_OWNERS_COLUMNS}
      rowtemplates={CONTROL_OWNERS_ROW_TEMPLATES}
      gridContent={(rowGenerator) => rows.map(rowGenerator)}
      pagingControl={pagingControl}
    />
  </div>
);
ControlOwnersGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  pagingControl: PropTypes.shape(PagingControlShape),
};
export const ControlOwnersGridLoading = ({ rowCount = 50 }) => (
  <div className="controlOwnersGrid">
    <GridLoading
      preHeaders={CONTROL_OWNERS_PRE_HEADERS}
      columns={CONTROL_OWNERS_COLUMNS}
      rowtemplate={CONTROL_OWNERS_LOADING_ROW_TEMPLATE}
      rowCount={rowCount}
    />
  </div>
);
ControlOwnersGridLoading.propTypes = {
  rowCount: PropTypes.number,
};
export const ControlOwnersGridNoResults = () => (
  <div className="controlOwnersGrid">
    <GridMessage
      preHeaders={CONTROL_OWNERS_PRE_HEADERS}
      columns={CONTROL_OWNERS_COLUMNS}
    >
      <h1>
        <FormattedMessage id="control_owner.no_employees_to_display" />
      </h1>
      <h2>
        <FormattedMessage id="control_owner.no_employees_match_criteria" />
      </h2>
    </GridMessage>
  </div>
);
export const ControlOwnersGridError = () => (
  <div className="controlOwnersGrid">
    <GridMessage
      preHeaders={CONTROL_OWNERS_PRE_HEADERS}
      columns={CONTROL_OWNERS_COLUMNS}
    >
      <Error />
    </GridMessage>
  </div>
);

export class ControlOwnersApp extends React.Component {
  constructor() {
    super();
    this.state = { filterPanelExpanded: true, generateReportModalInfo: null };
  }

  onClickFilterToggle = (e, state = this.state) => {
    this.setState({
      ...state,
      filterPanelExpanded: !state.filterPanelExpanded,
    });
  };

  onGenerateReport = (props = this.props, state = this.state) => {
    const { createReport } = props;

    createReport() &&
      this.setState({ ...state, generateReportModalInfo: null });
  };

  render() {
    const {
      headerNode,
      facetPanelNode,
      pagingNode,
      filtersApplied = 0,
      children,
      generateReportModalInfo,
      onCancelGenerateReportModal = () => {},
      onGenerateReport = () => {},
      className = 'controlOwnersApp',
    } = this.props;
    const { filterPanelExpanded } = this.state;
    return (
      <div className={className}>
        {generateReportModalInfo && (
          <GenerateReportModal
            reportTitle={generateReportModalInfo.reportTitle}
            scope={generateReportModalInfo.scope}
            bbgEmail={generateReportModalInfo.bbgEmail}
            corpEmail={generateReportModalInfo.corpEmail}
            isImpersonating={generateReportModalInfo.isImpersonating}
            onCancel={onCancelGenerateReportModal}
            onGenerate={onGenerateReport}
          />
        )}
        <div className="controlOwnersHeaderWrapper">
          <div className="row">{headerNode}</div>
        </div>
        <div className="controlOwnersPanel">
          <div className="row">
            <div className="facetControl">
              <span
                className="facetExpander"
                onClick={this.onClickFilterToggle}
              >
                <FontIcon
                  icon={
                    filterPanelExpanded
                      ? 'arrow-double-left'
                      : 'arrow-double-right'
                  }
                />
                <span className="facetExpanderLabel">
                  {intl('utility.filter')} ({filtersApplied})
                </span>
              </span>
              <span className="filler" />
              {pagingNode}
            </div>

            <div
              className={`gridArea ${
                filterPanelExpanded ? 'filters-expanded' : 'filters-hidden'
              }`}
            >
              <div className="facetContainer">{facetPanelNode}</div>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const ReportModalInfoShape = {
  reportTitle: PropTypes.string.isRequired,
  isImpersonating: PropTypes.bool,
  bbgEmail: PropTypes.string,
  corpEmail: PropTypes.string,
};
ControlOwnersApp.propTypes = {
  headerNode: PropTypes.node.isRequired,
  facetPanelNode: PropTypes.node.isRequired,
  pagingNode: PropTypes.node,
  filtersApplied: PropTypes.number,
  children: PropTypes.node.isRequired,
  generateReportModalInfo: PropTypes.shape(ReportModalInfoShape),
  onCancelGenerateReportModal: PropTypes.func.isRequired,
  onGenerateReport: PropTypes.func.isRequired,
};
