/* eslint-disable */
// External Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import debugMod from 'debug';

// Stylesheets
import FontIcon from './FontIcon';
import './styles/HeaderBar.css';
import { AppLogo } from '../AppLogo';
import Loader from './Loader';
import TabNav from './TabNav';
import ReportsManagerContainer from '../containers/ReportsManagerContainer';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line
const debug = debugMod('roster:HeaderBar');

export class AboutBadge extends Component {
  constructor() {
    super();

    this.state = { showMenu: false };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    if (!this.dropdownMenu.contains(event.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });
    }
  }

  render() {
    const { helpDocs } = this.props;
    const { showMenu } = this.state;
    return (
      <span className="about">
        {/* eslint-disable-next-line */}
        <a onClick={this.showMenu}>
          <FontIcon icon="circle-help" />
          <FormattedMessage id="header.learn_about_roster" />
        </a>
        {showMenu && (
          <div
            className="headerDropdown"
            ref={(element) => (this.dropdownMenu = element)}
          >
            <FormattedMessage id="header.download_user_guide_in" />
            <ul>
              {helpDocs.map((hd, i) => (
                <li key={i}>
                  <a target="_blank" href={hd.docUrl} rel="noopener noreferrer">
                    {hd.docName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </span>
    );
  }
}
export const helpDocsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    docName: PropTypes.string.isRequired,
    docUrl: PropTypes.string.isRequired,
  })
);
AboutBadge.propTypes = {
  helpDocs: helpDocsPropType.isRequired,
};

export class UserBadge extends Component {
  constructor() {
    super();

    this.state = { showMenu: false };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    if (!this.dropdownMenu.contains(event.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });
    }
  }

  render() {
    const {
      userName,
      jurisdictionDisplay,
      jurisdictionDatum,
      isImpersonating,
      logoutUrl,
    } = this.props;
    const { showMenu } = this.state;
    return (
      <span className={`userBadge${isImpersonating ? ' impersonating' : ''}`}>
        {/* eslint-disable-next-line */}
        <a onClick={this.showMenu}>
          <FontIcon icon="user_solid" />
          {userName}
        </a>
        <span className="jurisdictionDisplay">{jurisdictionDisplay}</span>
        {showMenu && (
          <div
            className="headerDropdown"
            ref={(element) => (this.dropdownMenu = element)}
          >
            <h1>
              <FormattedMessage id="header.jurisdiction" />
            </h1>
            {jurisdictionDatum &&
            jurisdictionDatum.name &&
            jurisdictionDatum.value ? (
              <div className="jurisdictionDetail">
                <h2>{jurisdictionDatum.name}:</h2>
                <span className="jurisdictionEntities">
                  {jurisdictionDatum.value}
                </span>
              </div>
            ) : (
              <div className="jurisdictionDetail loading" />
            )}
            <hr />
            <a className="button" href={logoutUrl}>
              <FormattedMessage id="header.log_out" />
            </a>
          </div>
        )}
      </span>
    );
  }
}

UserBadge.propTypes = {
  userName: PropTypes.string.isRequired,
  jurisdictionDisplay: PropTypes.string.isRequired,
  jurisdictionDatum: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  isImpersonating: PropTypes.bool,
  logoutUrl: PropTypes.string.isRequired,
};

export const HeaderBar = ({
  userName,
  jurisdictionDisplay,
  jurisdictionDatum,
  isImpersonating,
  tabs,
  selectedTab,
  logoutUrl,
  helpDocs,
}) => (
  <header className="headerBar">
    <Link to="/">
      <AppLogo />
    </Link>
    <span className="headerRight">
      <AboutBadge helpDocs={helpDocs} />
      {userName ? (
        <UserBadge
          userName={userName}
          jurisdictionDisplay={jurisdictionDisplay}
          jurisdictionDatum={jurisdictionDatum}
          isImpersonating={isImpersonating}
          logoutUrl={logoutUrl}
        />
      ) : (
        <Loader message="" />
      )}
    </span>
    <TabNav linkPrefix="" selectedTab={selectedTab} tabs={tabs} />
    <ReportsManagerContainer />
  </header>
);

HeaderBar.propTypes = {
  userName: PropTypes.string, // .isRequired,
  jurisdictionDisplay: PropTypes.string, // .isRequired,
  tabs: PropTypes.object.isRequired,
  selectedTab: PropTypes.string.isRequired,
  jurisdictionDatum: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  isImpersonating: PropTypes.bool,
  logoutUrl: PropTypes.string.isRequired,
  helpDocs: helpDocsPropType.isRequired,
};

export default HeaderBar;
/* eslint-enable */
