// External Dependencies
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

// Apps
import RosterApp from './RosterApp';
import configureStore from './store/configureStore';
import './styles/App.css';
import { initializeAnalyticsAndPageTracking } from './init/analytics';

const history = createBrowserHistory();
const store = configureStore(history);

history.listen(initializeAnalyticsAndPageTracking());

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <RosterApp history={history} />
      </Provider>
    );
  }
}

export default App;
