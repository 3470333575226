/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { getStatus } from 'redux-resource';
import {
  get as _get,
  reject as _reject,
  flatten as _flatten,
  uniq as _uniq,
} from 'lodash';
import debugMod from 'debug';
import {
  MONITORING_COUNT_TYPES,
  MONITORING_COUNT_LABEL_MAP,
  APP_SHORT_NAME,
  SUPPORTED_APPS,
  APP_FRIENDLY_NAME,
} from '../utils/Enums';

import {
  readUser,
  readUserFromCuid,
  readMonitoredByFromUuid,
  readMonitoredByFromCuid,
  readMonitoringFromUuid,
  readMonitoringFromCuid,
  readCusts,
  createReport,
} from '../store/apiActions';

import { DatumList, DatumListLoading } from '../components/Datum';

import {
  AdminMonitoringApp,
  AdminMonitoringGridLoading,
  AdminMonitoringGrid,
} from '../components/userProfile/AdminMonitoringApp';
import {
  createUrlUserInfo,
  userResourceAndStatus,
  monitoringResourceAndStatus,
  retrieveUser,
  retrieveMonitoring,
  fullNameFromUserAttributes,
} from '../utils/ProfileUtils';
import { SlideOut } from '../components/SlideOut';
import { queryStringParse } from '../utils/QueryStringParser';
import { safeAtob, userProfileLink } from '../utils/LinkUtils';

import { createAlert, ALERT_TYPES } from '../store/alertManager';
import { isImpersonating } from '../utils/JurisdictionUtils';
import {
  currentUserSelector,
  matchedFirmSelector,
  currentUserJurisdictionSelector,
} from '../store/selectors';
import { formatIfNumeral } from '../utils/FormatUtils';
import FontIcon from '../components/FontIcon';
import { Tooltip } from '../components/Tooltip';
import {
  enableCurrentViewReport,
  disableCurrentViewReport,
  enableSurveillanceReport,
  disableSurveillanceReport,
} from '../store/reportsManager';
import { retrieveRelationships } from '../store/stateMaterializers';
import { intl } from '../intl/IntlFormat';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line
const debug = debugMod('roster:AdminMonitoringAppContainer');

const mapStateToProps = (state, props) => {
  const {
    custs,
    JwtAuth: { csrftoken },
  } = state;
  const { match, location } = props;
  const { from: fromUrl } = queryStringParse(location.search);
  const matchParams = match.params;
  const { firmId, firmTab, subFirmTab, userIdType, app } = matchParams;
  const userId = parseInt(matchParams.userId, 10);

  const closeLink =
    safeAtob(fromUrl) ||
    userProfileLink({ firmId, firmTab, subFirmTab, userIdType, userId });

  const appName = APP_FRIENDLY_NAME[app];

  const urlUserInfo = createUrlUserInfo(matchParams);
  const [userResource, userStatus] = userResourceAndStatus(state, urlUserInfo);
  const [monitoringResource, monitoringStatus] = monitoringResourceAndStatus(
    state,
    urlUserInfo
  );

  const leisResources =
    monitoringResource && monitoringResource.data
      ? retrieveRelationships(state, monitoringResource.data).leis
      : [];

  const currentFirmResource = matchedFirmSelector(state, props);
  const currentUserAttrs = currentUserSelector(state);
  const jurisdictionAttrs = currentUserJurisdictionSelector(state);

  return {
    firmId,
    firmTab,
    subFirmTab,
    match,
    location,
    urlUserInfo,
    closeLink,
    app,
    appName,
    userResource,
    userStatus,
    monitoringResource,
    monitoringStatus,
    leisResources,
    custsStore: custs,
    currentFirmResource,
    currentUserAttrs,
    jurisdictionAttrs,
    csrftoken,
  };
};

const mapDispatchToProps = {
  readUser,
  readUserFromCuid,
  readMonitoredByFromUuid,
  readMonitoredByFromCuid,
  readMonitoringFromUuid,
  readMonitoringFromCuid,
  readCusts,
  createAlert,
  createReport,
  enableCurrentViewReport,
  disableCurrentViewReport,
  enableSurveillanceReport,
  disableSurveillanceReport,
};

// eslint-disable-next-line
const MEMBERSHIP_KEY_ORDER = Object.values(MONITORING_COUNT_LABEL_MAP);
const PAGE_SIZE = 50;
export class AdminMonitoringAppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 0, generateReportModalInfo: null };
  }

  componentDidMount() {
    this.readUserPromise = retrieveUser(this.props);
    this.readMonitoringPromise = retrieveMonitoring(this.props);
    this.readMonitoringCustsAndCaidsPromoise = this.readMonitoringCustsAndCaids(
      this.props
    );

    this.props.enableCurrentViewReport(this.onShowGenerateReportModal);
    this.props.enableSurveillanceReport(this.props.firmId);
  }

  componentWillUnmount() {
    this.props.disableCurrentViewReport();
    this.props.disableSurveillanceReport();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { monitoringResource, monitoringStatus } = this.props;
    const { page } = this.state;
    // eslint-disable-next-line
    if (
      (monitoringResource &&
        !prevProps.monitoringResource &&
        !monitoringStatus.pending) ||
      page != prevState.page
    ) {
      this.readMonitoringCustsAndCaids();
    }
  }

  adminMonitoredEmployees = (props = this.props) => {
    const { app, monitoringResource } = props;
    return _get(
      monitoringResource,
      'data.relationships.adminMonitoredEmployees.data',
      []
    ).filter(({ attributes: { membershipByApp } }) =>
      membershipByApp.find((mba) => mba.applicationName === app)
    );
  };

  adminMonitoredEmployeesSlice = (props = this.props, state = this.state) => {
    const { page } = state;
    const offset = page * PAGE_SIZE;
    const limit = offset + PAGE_SIZE;

    return this.adminMonitoredEmployees(props).slice(offset, limit);
  };

  adminMonitoredLeis = (props = this.props, state = this.state) => {
    const { leisResources } = props;
    return leisResources;
  };

  adminMonitoredLeisSlice = (props = this.props, state = this.state) => {
    const { page } = state;
    const offset = page * PAGE_SIZE;
    const limit = offset + PAGE_SIZE;

    return this.adminMonitoredLeis(props).slice(offset, limit);
  };

  rowCountInfo = (props = this.props, state = this.state) => {
    const { app } = props;
    const { page } = state;
    const offset = page * PAGE_SIZE;
    const limit = offset + PAGE_SIZE;
    const totalCount =
      SUPPORTED_APPS.TRADE === app
        ? this.adminMonitoredLeis(props).length
        : this.adminMonitoredEmployees(props).length;

    return {
      totalCount,
      firstRowNum: offset + 1,
      lastRowNum: Math.min(limit, totalCount),
    };
  };

  prevAndNextOnClicks = (props = this.props, state = this.state) => {
    const self = this;
    const { page } = state;
    const totalRows = this.rowCountInfo(props, state).totalCount;

    const onLoadPrevious =
      page !== undefined &&
      page > 0 &&
      ((e) => {
        e.preventDefault();
        self.setState({ page: page - 1 });
      });
    const onLoadNext =
      page !== undefined &&
      (page + 1) * PAGE_SIZE < totalRows &&
      ((e) => {
        e.preventDefault();
        self.setState({ page: page + 1 });
      });

    return [onLoadPrevious || undefined, onLoadNext || undefined];
  };

  monitoringResourceToGridRows = (props = this.props) => {
    const { app, custsStore } = props;

    if (app === SUPPORTED_APPS.TRADE) {
      return this.adminMonitoredLeisSlice(props).map(
        ({ attributes: { lei, name, country, region, caid } }) => {
          return {
            templateName: 'adminMonitoringLeis',
            lei,
            name,
            country,
            region,
            caid,
          };
        }
      );
    }
    return this.adminMonitoredEmployeesSlice(props).map(
      ({
        attributes: {
          uuid,
          cuid,
          isDeletedUser,
          isUnknownUser,
          name,
          status = '',
          membershipByApp,
        },
      }) => {
        const memberships = membershipByApp
          .find((mba) => mba.applicationName === app)
          .membership.filter(
            (m) => m.entityType !== MONITORING_COUNT_TYPES.FIRM
          )
          .map((m) => {
            let value = `ID: ${m.entityName}`;

            // eslint-disable-next-line
            if (m.entityType == [MONITORING_COUNT_TYPES.CUST]) {
              const cust = parseInt(m.entityName, 10);
              const custsResource = custsStore.resources[cust];
              const custsResourceStatus = getStatus(
                custsStore,
                `meta.${cust}.readStatus`,
                false
              );
              if (custsResource) {
                value = `${_get(
                  custsResource,
                  'attributes.name',
                  ''
                )} (ID: ${cust})`;
              } else if (!custsResourceStatus.failed) {
                value = (
                  <span>
                    <span className="loading" /> (ID: {m.entityName})
                  </span>
                );
              }
            }

            return { key: MONITORING_COUNT_LABEL_MAP[m.entityType], value };
          })
          .sort(
            (a, b) =>
              MEMBERSHIP_KEY_ORDER.indexOf(a.key) -
              MEMBERSHIP_KEY_ORDER.indexOf(b.key)
          );
        return {
          templateName: 'adminMonitoringApp',
          fullName: `${name.first} ${name.last}`,
          uuid,
          cuid,
          isDeletedUser,
          isUnknownUser,
          statuses: [status],
          memberships,
        };
      }
    );
  };

  readMonitoringCustsAndCaids = (props = this.props, state = this.state) => {
    const { app, readCusts, custsStore } = props;
    const appSlice = _flatten(
      this.adminMonitoredEmployeesSlice(props).map(
        ({ attributes: { membershipByApp: membership } }) =>
          membership.find((mba) => mba.applicationName === app).membership
      )
    ).reduce((acc, val) => {
      acc[val.entityType] = _uniq(
        (acc[val.entityType] ? acc[val.entityType] : []).concat([
          val.entityName,
        ])
      );
      return acc;
    }, {});

    const custs = appSlice[MONITORING_COUNT_TYPES.CUST];
    if (custs && custs.length) {
      const custsResourcesStatus = getStatus(
        custsStore,
        custs.map((c) => `meta.${c}.readStatus`),
        false
      );
      if (
        !custsResourcesStatus ||
        !(custsResourcesStatus.pending || custsResourcesStatus.failed)
      ) {
        this.readCustsPromise = readCusts({ custs });
      }
    }
  };

  onShowGenerateReportModal = (props = this.props) => {
    const {
      app,
      currentUserAttrs,
      jurisdictionAttrs,
      currentFirmResource,
    } = props; // Not translating the below pending some language-safe way to structure this
    const reportTitle = `Admin Monitoring - ${
      APP_SHORT_NAME[app]
    } Report (${new Date().toLocaleDateString('en-US')})`;
    this.setState({
      generateReportModalInfo: {
        firmName: _get(currentFirmResource, 'attributes.firmName', ''),
        reportTitle,
        scope: APP_SHORT_NAME[app],
        isImpersonating: isImpersonating(jurisdictionAttrs),
        bbgEmail: currentUserAttrs.bbgEmail,
        corpEmail: currentUserAttrs.corpEmail,
      },
    });
  };

  onCancelGenerateReportModal = (props = this.props) => {
    this.setState({ generateReportModalInfo: null });
  };

  onGenerateReport = (props = this.props, state = this.state) => {
    const {
      app,
      userResource,
      firmId,
      createReport,
      createAlert,
      csrftoken,
    } = props;
    createReport('adminMonitoring', csrftoken, {
      firmId,
      app,
      adminUuid: userResource.id,
      csrftoken,
    }).then(
      (v) => {
        createAlert(ALERT_TYPES.INFO, intl('report.report_delivered'), 5000);
      },
      (r) => {
        debug('REJECT', r);
        createAlert(ALERT_TYPES.ERROR, intl('report.error_generating_report'));
      }
    );

    this.setState({ generateReportModalInfo: null });
  };

  render() {
    const {
      closeLink,
      app,
      appName,
      userResource,
      monitoringStatus,
      monitoringResource,
    } = this.props;
    const { generateReportModalInfo } = this.state;

    const userName =
      userResource && fullNameFromUserAttributes(userResource.attributes);

    // Leaving untranslated since this sentence structure may not translate easily
    const outsideJurisdictionMessage = `${userName} is monitoring members that are not visible to you based on your Control Owner Jurisdiction.`;

    let statusDatum;
    let countsDatum;
    let outsideJurisdiction;
    if (
      monitoringResource &&
      monitoringResource.data &&
      monitoringResource.data.attributes &&
      monitoringResource.data.attributes.monitoringCountsByApp
    ) {
      const appCounts = _get(
        monitoringResource,
        'data.attributes.monitoringCountsByApp',
        {}
      )[app];
      const scopes = appCounts
        ? _reject(
            Object.keys(appCounts),
            (r) => r === MONITORING_COUNT_TYPES.EMPLOYEE
          ).map((s) => MONITORING_COUNT_LABEL_MAP[s])
        : [];
      const monitoredEmployees = appCounts
        ? appCounts[MONITORING_COUNT_TYPES.EMPLOYEE]
        : 0;
      outsideJurisdiction =
        _get(
          monitoringResource,
          'data.relationships.adminMonitoredEmployees.meta.outsideJurisdictionByApp',
          {}
        )[app] || 0;
      statusDatum = [
        { name: intl('firm.status'), value: appCounts ? ['On'] : ['Off'] },
        {
          name: intl('user.entitlement_type'),
          value: scopes.sort((a, b) => a < b),
        },
      ];
      if (SUPPORTED_APPS.TRADE !== app) {
        countsDatum = [
          {
            name: (
              <b>
                <FormattedMessage id="admin_monitoring.total_monitored_employees" />
              </b>
            ),
            value: <b>{formatIfNumeral(monitoredEmployees)}</b>,
          },
          {
            name: (
              <span>
                <FormattedMessage id="admin_monitoring.inside_your_jurisdiction" />
              </span>
            ),
            value: formatIfNumeral(monitoredEmployees - outsideJurisdiction),
            className: 'subDatum',
          },
          {
            name: (
              <span>
                <FormattedMessage id="admin_monitoring.outside_your_jurisdiction" />
                {outsideJurisdiction > 0 && (
                  <Tooltip content={outsideJurisdictionMessage}>
                    <FontIcon disabled={true} icon="circleinfo-filled" />
                  </Tooltip>
                )}
              </span>
            ),
            value: formatIfNumeral(outsideJurisdiction),
            className: 'subDatum',
          },
        ];
      } else {
        const leisCount = this.adminMonitoredLeis(this.props).length;
        countsDatum = [
          {
            name: (
              <b>
                <FormattedMessage id="admin_monitoring_total_monitored_leis" />
              </b>
            ),
            value: <b>{formatIfNumeral(leisCount)}</b>,
          },
        ];
      }
    }
    const datumList = (
      <div>
        {statusDatum ? (
          <DatumList data={statusDatum} />
        ) : (
          <DatumListLoading count={3} />
        )}
        {countsDatum ? (
          <DatumList data={countsDatum} />
        ) : (
          <DatumListLoading count={3} />
        )}
      </div>
    );

    const gridRows = this.monitoringResourceToGridRows();
    const [onLoadPrevious, onLoadNext] = this.prevAndNextOnClicks();

    const grid =
      gridRows.length > 0 || monitoringStatus.succeeded ? (
        <AdminMonitoringGrid
          app={app}
          rows={gridRows}
          onLoadPrevious={onLoadPrevious}
          onLoadNext={onLoadNext}
          gridFooter={
            outsideJurisdiction > 0 && (
              <span>
                <FontIcon disabled={true} icon="circleinfo-filled" />{' '}
                {outsideJurisdictionMessage}
              </span>
            )
          }
        />
      ) : (
        <AdminMonitoringGridLoading rowCount={PAGE_SIZE} />
      );

    return (
      <SlideOut>
        <AdminMonitoringApp
          userName={userName}
          app={app}
          appName={appName}
          datumList={datumList}
          closeLink={closeLink}
          showGenerateReport={gridRows.length > 0}
          generateReportModalInfo={generateReportModalInfo}
          rowCountInfo={this.rowCountInfo()}
          onGenerateReport={this.onGenerateReport}
          onShowGenerateReportModal={this.onShowGenerateReportModal}
          onCancelGenerateReportModal={this.onCancelGenerateReportModal}
        >
          {grid}
        </AdminMonitoringApp>
      </SlideOut>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminMonitoringAppContainer);
/* eslint-enable */
