import React from 'react';
import PropTypes from 'prop-types';
import { get as _get, keys as _keys } from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
  DatumHeaderHero,
  DatumHeaderHeroLoading,
  DatumPair,
  DatumSection,
  DatumSubSection,
  DatumSectionLoading,
  DatumColumn,
} from '../Datum';
import { intl } from '../../intl/IntlFormat';

import { formatIfNumeral, sortObjectOnValue } from '../../utils/FormatUtils';

import './styles/ControlOwnersHeader.css';
import FontIcon from '../FontIcon';

export const CONTROL_OWNERS_HEADER_COLUMNS = {
  'coverage-1': {
    displayName: intl('control_owner.jurisdiction_coverage'),
    section: [
      {
        type: 'pair',
        displayName: intl('control_owner.total_active_control'),
        value: 'activeControlOwnersCount',
        primary: true,
      },
      {
        type: 'subSection',
        displayName: intl('control_owner.region_only'),
        value: 'regionBasedTotal',
        subSection: [
          {
            type: 'pair',
            displayName: intl('control_owner.global'),
            value: 'allRegionsCount',
          },
          {
            type: 'pair',
            displayName: intl('control_owner.americas'),
            value: 'americasRegionCount',
          },
          {
            type: 'pair',
            displayName: intl('control_owner.asia_pac'),
            value: 'apacRegionCount',
          },
          {
            type: 'pair',
            displayName: intl('control_owner.europe_africa'),
            value: 'emeaRegionCount',
          },
          {
            type: 'pair',
            displayName: intl('control_owner.combination'),
            value: 'comboRegionCount',
          },
        ],
      },
    ],
  },
  'coverage-2': {
    displayName: '',
    section: [
      {
        type: 'keyValueSubSection',
        displayName: intl('control_owner.country_only'),
        value: 'countryBasedTotal',
        subSection: [
          {
            type: 'pair',
            displayName: intl('control_owner.combination'),
            value: 'comboCountryCount',
          },
        ],
      },
    ],
  },
  'coverage-3': {
    displayName: '',
    section: [
      {
        type: 'subSection',
        displayName: intl('control_owner.entity_only'),
        value: 'entityBasedTotal',
        subSection: [
          {
            type: 'pair',
            displayName: intl('control_owner.caid'),
            value: 'caidEntityCount',
          },
          {
            type: 'pair',
            displayName: intl('control_owner.cust_acct'),
            value: 'custEntityCount',
          },
          {
            type: 'pair',
            displayName: intl('control_owner.combination'),
            value: 'comboEntityCount',
          },
        ],
      },
    ],
  },
  'coverage-4': {
    displayName: '',
    section: [
      {
        type: 'pair',
        displayName: intl('control_owner.custom'),
        value: 'customJurisdictionCount',
      },
    ],
  },
  'membership-1': {
    displayName: intl('control_owner.memberships'),
    section: [
      {
        type: 'pair',
        displayName: intl('control_owner.total_active_control'),
        value: 'activeControlOwnersCount',
        primary: true,
      },
      {
        type: 'pair',
        displayName: intl('control_owner.americas'),
        value: 'Americas',
      },
      {
        type: 'pair',
        displayName: intl('control_owner.europe_africa'),
        value: 'Europe/Africa',
      },
      {
        type: 'pair',
        displayName: intl('control_owner.asia_pac'),
        value: 'Asia Pac',
      },
    ],
  },
};

const headerSummary = (responseMeta) => {
  return responseMeta && responseMeta.summary && responseMeta.onBoarded
    ? {
        primary: [
          {
            name: intl('control_owner.control_owners'),
            value: formatIfNumeral(
              _get(responseMeta, 'summary.controlOwnersCount', '--')
            ),
          },
        ],
        secondary: [
          {
            name: intl('control_owner.active'),
            value: formatIfNumeral(
              _get(responseMeta, 'onBoarded.activeControlOwnersCount', '--')
            ),
          },
          {
            name: intl('control_owner.deleted'),
            value: formatIfNumeral(
              _get(responseMeta, 'onBoarded.deleted', '--')
            ),
          },
          {
            name: intl('firm.firms'),
            value: formatIfNumeral(
              _get(responseMeta, 'summary.firmsCount', '--')
            ),
          },
        ],
      }
    : { primary: [], secondary: [] };
};

const trimHeaderColumnKey = (key) => {
  return key.substr(0, key.indexOf('-'));
};

const headerColumns = (
  responseMeta,
  expandCountryList,
  countryListLimit,
  onShowMoreCountries
) => {
  return _keys(CONTROL_OWNERS_HEADER_COLUMNS)
    .filter((k) => responseMeta && responseMeta[trimHeaderColumnKey(k)])
    .map((k, ii) => {
      const columnDef = CONTROL_OWNERS_HEADER_COLUMNS[k];

      function datumPairFromDef(d, i) {
        const v = formatIfNumeral(
          _get(responseMeta, `${trimHeaderColumnKey(k)}.${d.value}`)
        );
        return (
          v && (
            <DatumPair
              key={i}
              datum={{
                name: d.primary ? <b>{d.displayName}</b> : d.displayName,
                value: d.primary ? <b>{v}</b> : v,
              }}
            />
          )
        );
      }

      function extractCountryCountMap(keyBase) {
        const countryCountMap = sortObjectOnValue(
          formatIfNumeral(
            JSON.parse(_get(responseMeta, `coverage.countryBasedCount`))
          )
        );
        const comboCountryCount = formatIfNumeral(
          _get(responseMeta, `coverage.comboCountryCount`)
        );
        const m = [];
        Object.keys(countryCountMap).forEach((d, i) => {
          if (expandCountryList || m.length < countryListLimit) {
            m.push(
              <DatumPair
                key={keyBase + '-' + i}
                datum={{
                  name: d,
                  value: countryCountMap[d],
                }}
              />
            );
          }
        });
        m.push(
          <DatumPair
            key={keyBase}
            datum={{
              name: intl('control_owner.combination'),
              value: comboCountryCount,
            }}
          />
        );
        return m;
      }

      function countryJuriShowMoreToggle() {
        const countryCountMap = formatIfNumeral(
          JSON.parse(_get(responseMeta, `coverage.countryBasedCount`))
        );
        let showMoreToggle;
        if (Object.keys(countryCountMap).length > countryListLimit) {
          showMoreToggle = (
            <div className="toggleShowMore" onClick={onShowMoreCountries}>
              {expandCountryList ? (
                <span>
                  <FormattedMessage id="utility.show_less" />
                  <FontIcon icon="arrow-up disabled" />
                </span>
              ) : (
                <span>
                  <FormattedMessage id="utility.show_more" />
                  <FontIcon icon="arrow-down disabled" />
                </span>
              )}
            </div>
          );
        } else {
          showMoreToggle = <div />;
        }
        return showMoreToggle;
      }

      return (
        <DatumColumn key={ii}>
          <DatumSection sectionTitle={columnDef.displayName}>
            {columnDef.section.map((d, i) => {
              switch (d.type) {
                case 'pair':
                  return datumPairFromDef(d, i);
                case 'subSection':
                  return (
                    <DatumSubSection
                      key={i}
                      subSectionDatum={datumPairFromDef(d)}
                    >
                      {d.subSection.map((d, j) => datumPairFromDef(d, j))}
                    </DatumSubSection>
                  );
                case 'keyValueSubSection':
                  return (
                    <DatumSubSection
                      key={i}
                      subSectionDatum={datumPairFromDef(d)}
                    >
                      {extractCountryCountMap()}
                      {countryJuriShowMoreToggle()}
                    </DatumSubSection>
                  );
                default:
                  return '';
              }
            })}
          </DatumSection>
        </DatumColumn>
      );
    });
};

export class ControlOwnersHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expandCountryList: false };
    this.onShowMoreCountries = this.onShowMoreCountries.bind(this);
  }

  onShowMoreCountries() {
    this.setState((prevState) => ({
      expandCountryList: !prevState.expandCountryList,
    }));
  }

  render() {
    const {
      title = intl('control_owner.control_owners'),
      headerData = {},
      className = 'controlOwnersHeader',
      countryListLimit = 3,
    } = this.props;
    const { expandCountryList } = this.state;

    return (
      <div className={className}>
        <DatumHeaderHero
          title={title}
          primaryData={headerSummary(headerData).primary}
          secondaryData={headerSummary(headerData).secondary}
        />
        <span className="controlOwnersHeaderColumns">
          {headerColumns(
            headerData,
            expandCountryList,
            countryListLimit,
            this.onShowMoreCountries
          )}
        </span>
      </div>
    );
  }
}

ControlOwnersHeader.propTypes = {
  className: PropTypes.element,
  headerData: PropTypes.object.isRequired,
};

export const ControlOwnersHeaderLoading = ({
  title = intl('control_owner.control_owners'),
  headerDataCount = 2,
  columnsCount = 5,
  className = 'controlOwnersHeader loading',
}) => (
  <div className={className}>
    <DatumHeaderHeroLoading title={title} count={headerDataCount} />
    <span className="controlOwnersHeaderColumns">
      {Array.from(Array(columnsCount)).map((e, i) => (
        <DatumColumn key={i}>
          <DatumSectionLoading rowCount={7} />
        </DatumColumn>
      ))}
    </span>
  </div>
);
