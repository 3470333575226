/* eslint-disable */
import debugMod from 'debug';
// eslint-disable-next-line
const debug = debugMod('roster:ReportsManager');

const ACTIONTYPES = {
  ENABLE_CURRENT_VIEW: 'ENABLE_CURRENT_VIEW',
  DISABLE_CURRENT_VIEW: 'DISABLE_CURRENT_VIEW',
  ENABLE_SURVEILLANCE: 'ENABLE_SURVEILLANCE',
  DISABLE_SURVEILLANCE: 'DISABLE_SURVEILLANCE',
};
const INITIAL_STATE = { currentView: {}, surveillance: {} };

function enableCurrentViewProcessor(state, action) {
  return {
    ...state,
    currentView: {
      callback: action.callback,
    },
  };
}
function disableCurrentViewProcessor(state, action) {
  return {
    ...state,
    currentView: {},
  };
}

function enableSurveillanceProcessor(state, action) {
  return {
    ...state,
    surveillance: {
      firmId: action.firmId,
    },
  };
}
function disableSurveillanceProcessor(state, action) {
  return {
    ...state,
    surveillance: {},
  };
}

export function reportsManagerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIONTYPES.ENABLE_CURRENT_VIEW:
      return enableCurrentViewProcessor(state, action);
    case ACTIONTYPES.DISABLE_CURRENT_VIEW:
      return disableCurrentViewProcessor(state, action);
    case ACTIONTYPES.ENABLE_SURVEILLANCE:
      return enableSurveillanceProcessor(state, action);
    case ACTIONTYPES.DISABLE_SURVEILLANCE:
      return disableSurveillanceProcessor(state, action);
    default:
      return state;
  }
}

export function enableCurrentViewReport(generateCurrentViewReportCB) {
  return {
    type: ACTIONTYPES.ENABLE_CURRENT_VIEW,
    callback: generateCurrentViewReportCB,
  };
}
export function disableCurrentViewReport() {
  return { type: ACTIONTYPES.DISABLE_CURRENT_VIEW };
}

export function enableSurveillanceReport(firmId) {
  return {
    type: ACTIONTYPES.ENABLE_SURVEILLANCE,
    firmId,
  };
}
export function disableSurveillanceReport() {
  return { type: ACTIONTYPES.DISABLE_SURVEILLANCE };
}
/* eslint-enable */
