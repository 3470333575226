import React from 'react';
import PropTypes from 'prop-types';

import './styles/Profile.css';

export const MemberProfile = ({ children, className = 'memberProfile' }) => (
  <div className={className}>{children}</div>
);

MemberProfile.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
};
