/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import './styles/FooterBar.css';
import { intl } from '../intl/IntlFormat';

const COPYRIGHT_YEAR = Math.max(2018, new Date().getFullYear());
const TERMS_OF_SERVICE_LINK = 'https://www.bloomberg.com/notices/tos/';
const TRADEMARKS_LINK = 'https://www.bloomberg.com/notices/trademarks/';
const PRIVACY_POLICY_LINK = 'https://www.bloomberg.com/notices/privacy/';

class FooterBar extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`footerBar${className ? ` ${className}` : ''}`}>
        <span className="copyRight">
          &copy; {COPYRIGHT_YEAR} {intl('footer.bloomberg_all_rights_reserved')}
        </span>
        <span className="disclaimerLinks">
          <a href={TERMS_OF_SERVICE_LINK} className="disclaimerLink">
            {intl('footer.terms_of_service')}
          </a>{' '}
          |{' '}
          <a href={TRADEMARKS_LINK} className="disclaimerLink">
            {intl('footer.trademarks')}
          </a>{' '}
          |{' '}
          <a href={PRIVACY_POLICY_LINK} className="disclaimerLink">
            {intl('footer.privacy_policy')}
          </a>
        </span>
      </div>
    );
  }
}

FooterBar.propTypes = {
  className: PropTypes.string,
};

export default FooterBar;
/* eslint-enable */
