import React from 'react';
import PropTypes from 'prop-types';

import { formatPaginationCount } from '../../utils/FormatUtils';

import './styles/PagingFilterInfo.css';

export const PagingFilterInfo = ({ firstRowNum, lastRowNum, totalCount }) => {
  return (
    <span className="pagingFilterInfo">
      <span className="pagingInfo">
        {formatPaginationCount(firstRowNum, lastRowNum, totalCount)}
      </span>
    </span>
  );
};

PagingFilterInfo.propTypes = {
  firstRowNum: PropTypes.number,
  lastRowNum: PropTypes.number,
  totalCount: PropTypes.number,
};

export default PagingFilterInfo;
