/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { DatumHeroLoading } from './Datum';
import { TabsShape } from '../PropTypes';
import Error from './Error';
import './styles/DatumHeroNav.css';

export const HeroNav = ({ children, selectedIndex, className }) => {
  className = className || '';
  return <ul className={`heroNav ${className}`}>{children}</ul>;
};

const DatumHeroNav = ({ tabs, selectedIndex, className }) => (
  <HeroNav selectedIndex={selectedIndex} className={className}>
    {tabs.map((heroNavData, i) => (
      <Link to={heroNavData.link} key={i}>
        <li key={i} className={i === selectedIndex ? 'selected' : ''}>
          {heroNavData.datumHero}
        </li>
      </Link>
    ))}
  </HeroNav>
);

DatumHeroNav.propTypes = {
  tabs: PropTypes.arrayOf(TabsShape).isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

export const DatumHeroNavLoading = ({ tabsCount, datumCount = 4 }) => (
  <HeroNav className="loading">
    {[...Array(tabsCount)].map((_, i) => (
      <li key={i} className="selected">
        <DatumHeroLoading count={datumCount} />
      </li>
    ))}
  </HeroNav>
);
DatumHeroNavLoading.propTypes = {
  tabsCount: PropTypes.number.isRequired,
  datumCount: PropTypes.number,
};

export const HeroNavError = ({ tabsCount }) => (
  <HeroNav className="error">
    {[...Array(tabsCount)].map((_, i) => (
      <li key={i} className="selected">
        <Error />
      </li>
    ))}
  </HeroNav>
);
HeroNavError.propTypes = {
  tabsCount: PropTypes.number.isRequired,
};

export default DatumHeroNav;
/* eslint-enable */
