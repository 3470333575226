/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import {
  DatumPairType,
  DatumPairWithTooltipType,
  DatumListType,
} from '../PropTypes';
import { FormattedMessage } from 'react-intl';

import './styles/Datum.css';
import Tooltip from './Tooltip';

export const ExtendedDatumValue = ({ name, value }) => (
  <div className="extendedDatum">
    <em>{name}</em>
    <span>{value}</span>
  </div>
);
ExtendedDatumValue.propTypes = {
  name: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

// To handle overflow, we will count the number of values
// Display some of the values and then ALL the values in a tooltip (flexing)
// User will be able to see all the values onHover of tooltip
export const ExtendedDatumValueWithOverflow = ({
  name,
  value,
  overflow = 5,
}) => {
  const valueList = value.split(',');
  const valueUl = (
    <ul className="jurisdictionNames">
      {valueList.map((jurisdictionName) => (
        <li key={jurisdictionName}>{jurisdictionName}</li>
      ))}
    </ul>
  );
  let valueSpan = (
    <Tooltip className="jurisdictionTooltip" content={valueUl}>
      {valueList.length}
    </Tooltip>
  );
  let valueEm = (
    <em>
      {name}
      {valueSpan}
    </em>
  );

  switch (name) {
    case 'Region Only':
      valueEm = (
        <em>
          <FormattedMessage id="control_owner.regions" />
          {valueSpan}
        </em>
      );
      break;
    case 'Country Only':
      valueEm = (
        <em>
          <FormattedMessage id="firm.countries" />
          {valueSpan}
        </em>
      );
      break;
    case 'Entities Only':
      valueSpan = '';
      valueEm = '';
      break;
    case 'Region & Country':
      valueEm = (
        <em>
          <FormattedMessage id="control_owner.regions_and_countries" />
          {valueSpan}
        </em>
      );
      break;
    case 'Country & Entities':
      valueEm = (
        <em>
          <FormattedMessage id="firm.countries" />
          {valueSpan}
        </em>
      );
      break;
    case 'Region & Entities':
      valueEm = (
        <em>
          <FormattedMessage id="control_owner.regions" />
          {valueSpan}
        </em>
      );
      break;
    case 'Region, Country & Entities':
      valueEm = (
        <em>
          <FormattedMessage id="control_owner.regions_and_countries" />
          {valueSpan}
        </em>
      );
      break;
    default:
      break;
  }

  return (
    <div className="extendedDatum">
      <em>
        <FormattedMessage id="control_owner.type" />
        <span>{name}</span>
      </em>
      {valueEm}
    </div>
  );
};
ExtendedDatumValueWithOverflow.propTypes = {
  name: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

export const TabSummary = ({ value, unit }) => (
  <span className="tabSummary">
    <span className="summaryValue">{value}</span>
    <span className="summaryUnit">{unit}</span>
  </span>
);
TabSummary.propTypes = {
  value: PropTypes.node.isRequired,
  unit: PropTypes.node.isRequired,
};

export const Datum = ({ name, children, className = 'datumPair' }) => (
  <dl className={className + (name || children ? '' : ' loading')}>
    <dt>{name}</dt>
    <dd>{children}</dd>
  </dl>
);

export const DatumWithTooltip = ({
  name,
  children,
  dtContent,
  className = 'datumPairWithTooltip',
}) => (
  <dl className={className + (name || children ? '' : ' loading')}>
    <Tooltip content={dtContent} position="right">
      <dt>{name}</dt>
    </Tooltip>
    <dd>{children}</dd>
  </dl>
);

const ValueList = ({ list }) => (
  <ul>
    {list.map((v, i) => (
      <li key={i}>{v}</li>
    ))}
  </ul>
);
export const DatumPair = ({ datum = {} }) => (
  <Datum
    name={datum.name}
    className={`datumPair ${datum.className ? datum.className : ''}${
      datum && datum.value && datum.value.length > 10 ? ' wide-value' : ''
    }`}
  >
    {Array.isArray(datum.value) ? (
      <ValueList list={datum.value} />
    ) : (
      datum.value
    )}
  </Datum>
);

DatumPair.propTypes = {
  datum: PropTypes.shape(DatumPairType),
};

export const DatumPairWithTooltip = ({ datum = {} }) => (
  <DatumWithTooltip
    name={datum.name}
    dtContent={datum.dtContent}
    className={`datumPair ${datum.className ? datum.className : ''}${
      datum && datum.value && datum.value.length > 10 ? ' wide-value' : ''
    }`}
  >
    {Array.isArray(datum.value) ? (
      <ValueList list={datum.value} />
    ) : (
      datum.value
    )}
  </DatumWithTooltip>
);

DatumPairWithTooltip.propTypes = {
  datum: PropTypes.shape(DatumPairWithTooltipType),
};

export const DatumList = ({ className, data }) => (
  <ul className={`datumList ${className || ''}`}>
    {data.map((pair, i) => (
      <DatumPair key={i} datum={pair} />
    ))}
  </ul>
);
DatumList.propTypes = {
  data: DatumListType,
};

export const DatumElementList = ({ elements }) => (
  <ul className="datumList">{elements}</ul>
);
DatumElementList.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export const DatumListLoading = ({ className, count }) => (
  <ul className={`datumList loading ${className || ''}`}>
    {Array.from(Array(count)).map((_, i) => (
      <DatumPair key={i} />
    ))}
  </ul>
);

export const DatumHero = ({ data }) => (
  <div className="datumHero">
    <span className="primary">
      <DatumPair datum={data[0]} />
    </span>
    <span className="secondary">
      {data.slice(1).map((datum, i) => (
        <DatumPair key={i} datum={datum} />
      ))}
    </span>
  </div>
);

DatumHero.propTypes = {
  data: DatumListType,
};

export const DatumHeroLoading = ({ count }) => (
  <div className="datumHero">
    <span className="primary">
      <DatumPair />
    </span>
    <span className="secondary">
      {[...Array(count - 1)].map((_, i) => (
        <DatumPair key={i} />
      ))}
    </span>
  </div>
);

DatumHeroLoading.propTypes = {
  count: PropTypes.number.isRequired,
};

export const DatumHeaderHero = ({ title, primaryData, secondaryData }) => (
  <div className="datumHeaderHero">
    <h1>{title}</h1>
    <span className="primary">
      {primaryData.map((datum, i) => (
        <DatumPair key={i} datum={datum} />
      ))}
    </span>
    <div className="secondary">
      {secondaryData.map((datum, i) => (
        <DatumPair key={i} datum={datum} />
      ))}
    </div>
  </div>
);

DatumHeaderHero.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  primaryData: DatumListType,
  secondaryData: DatumListType,
};

export const DatumHeaderHeroLoading = ({ title, count }) => (
  <div className="datumHeaderHero">
    <h1>{title}</h1>
    <span className="primary">
      {[...Array(count)].map((_, i) => (
        <DatumPair key={i} />
      ))}
    </span>
  </div>
);

DatumHeaderHeroLoading.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  count: PropTypes.number.isRequired,
};

export const DatumSection = ({ sectionTitle, children }) => (
  <div className="datumSection">
    {sectionTitle && <h1>{sectionTitle}</h1>}
    <div className="datumSectionContent">{children}</div>
  </div>
);

DatumSection.propTypes = {
  sectionTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

/* eslint jsx-a11y/heading-has-content: "off" */
export const DatumSectionLoading = ({ rowCount }) => (
  <div className="datumSection loading">
    <h1 />
    <div className="datumSectionContent">
      {[...Array(rowCount)].map((_, i) => (
        <DatumPair key={i} />
      ))}
    </div>
  </div>
);
DatumSectionLoading.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

export const DatumSubSection = ({ subSectionDatum, children }) => (
  <div className="datumSubSection">
    {subSectionDatum}
    <div className="datumSubSectionContent">{children}</div>
  </div>
);

export const DatumColumn = ({ children, className = 'datumColumn' }) => (
  <div className={className}>{children}</div>
);

DatumColumn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
/* eslint-enable */
