import {
  initialize,
  createBrowserHistoryPageTracker,
  MatomoAnalyticsProvider,
  createEventTracker,
} from '@bbnpm/bb-analytics';

function getProviderConfigurationFromDomain({ hostname }) {
  if (/roster\.bloomberg\.com/i.test(hostname)) {
    return [
      Number(process.env.REACT_APP_MATOMO_SITE_ID_PRODUCTION),
      process.env.REACT_APP_MATOMO_HOST_PRODUCTION,
    ];
  }
  if (/roster-(alpha|beta)\.bloomberg\.com/i.test(hostname)) {
    return [
      Number(process.env.REACT_APP_MATOMO_SITE_ID_BETA),
      process.env.REACT_APP_MATOMO_HOST_BETA,
    ];
  }
  return [
    Number(process.env.REACT_APP_MATOMO_SITE_ID_DEVELOPMENT),
    process.env.REACT_APP_MATOMO_HOST_DEVELOPMENT,
  ];
}

// Matomo Event Tracker Factory Function
export function addEventTracker(func, { category, action, label } = {}) {
  const onTrackEvent = createEventTracker({ category, action, label });
  return (...args) => {
    onTrackEvent();
    return func(...args);
  };
}

/* eslint-disable import/prefer-default-export */
export function initializeAnalyticsAndPageTracking() {
  initialize({
    provider: new MatomoAnalyticsProvider(
      ...getProviderConfigurationFromDomain(window.location)
    ),
  });
  return createBrowserHistoryPageTracker();
}
/* eslint-enable import/prefer-default-export */
