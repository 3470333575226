/* eslint-disable */
import React from 'react';
import { Route, Redirect } from 'react-router';
import PropTypes from 'prop-types';

import Loader from './Loader';
import { intl } from '../intl/IntlFormat';

const PrivateRoute = ({
  isInitialized,
  isAuthenticated,
  component: Component,
  ...props
}) => (
  <Route
    {...props}
    render={(props) =>
      isInitialized ? (
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      ) : (
        <Loader
          message={intl('utility.initializing')}
          className="privateRoute"
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default PrivateRoute;
/* eslint-enable */
