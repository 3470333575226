import PropTypes from 'prop-types';

export const SCOPE_TYPE = {
  CUST: 'CUST',
  CAID: 'CAID',
};

export const DatumPairType = {
  name: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

export const DatumPairWithTooltipType = {
  name: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
  dtContent: PropTypes.string.isRequired,
};

export const DatumListType = PropTypes.arrayOf(PropTypes.shape(DatumPairType));

export const FirmType = {
  firmId: PropTypes.node.isRequired,
  firmName: PropTypes.string.isRequired,
};

export const ScopeType = {
  scopeType: PropTypes.oneOf(Object.values(SCOPE_TYPE)).isRequired,
  scopeId: PropTypes.number.isRequired,
  scopeName: PropTypes.string.isRequired,
};

export const SearchResultType = {
  id: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  meta: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  clickInfo: PropTypes.object,
  onClickResult: PropTypes.func,
};

export const SearchResultsSectionType = {
  sectionName: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape(SearchResultType)).isRequired,
  runningIndex: PropTypes.number,
  selectedResult: PropTypes.number,
  onClickResult: PropTypes.func,
};

export const ReactRouterLinkType = {
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  state: PropTypes.object,
};

export const TabsShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(ReactRouterLinkType),
  ]).isRequired,
  datumHero: PropTypes.element.isRequired,
});
