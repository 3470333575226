// Util for retrieving locale from the browser and parsing into two-letter locale format
export const browserLanguage = () => {
  let language;
  if (window.navigator.languages && window.navigator.languages.length > 0) {
    [language] = window.navigator.languages;
  } else {
    language = window.navigator.userLanguage || window.navigator.language;
  }

  if (language === 'zh-HK' || language === 'zh-TW') {
    language = 'zt';
  } else {
    [language] = language.split('-'); // "en-US" -> "en"
  }

  return language;
};
