import React from 'react';
import PropTypes from 'prop-types';

import Grid, { GridError, GridLoading } from '../grid/Grid';
import ApplicationStatusCellTemplate, {
  ApplicationStatusCellTemplateLoading,
} from '../grid/ApplicationStatusCellTemplate';
import AttributeListCellTemplate, {
  AttributeListCellTemplateLoading,
} from '../grid/AttributeListCellTemplate';
import debugMod from 'debug';
import { intl } from '../../intl/IntlFormat';

import './styles/UserProfileGrid.css';

// eslint-disable-next-line
const debug = debugMod('roster:UserProfileGrid');

const USER_PROFILE_TAB = {
  MONITORED_BY: 'monitoredBy',
  MONITORING: 'monitoring',
};

const GRID_CONFIG = {
  [USER_PROFILE_TAB.MONITORED_BY]: {
    columnSpec: [
      { title: intl('user.application'), className: 'align-left' },
      { title: intl('user.entitlement_type'), className: 'align-left' },
      { title: intl('user.entitlement_name_id'), className: 'align-left' },
      { title: intl('firm.admin_reviewer'), className: 'align-left' },
      { title: intl('user.admin_reviewer_uuid'), className: 'align-right' },
    ],
    rowTemplates: {
      memberDetailsMonitoredBy: {
        cells: [
          {
            mapDataToProps: (data) => ({
              name: data.application,
              alert: data.alert,
            }),
            cellTemplate: ApplicationStatusCellTemplate,
          },
          {
            mapDataToProps: (data) => ({
              showAttributeKeys: false,
              attributes: data.setupTypes.map((setupType) => ({
                value: setupType,
              })),
            }),
            cellTemplate: AttributeListCellTemplate,
            className: 'textAttributes',
          },
          {
            mapDataToProps: (data) => ({
              showAttributeKeys: false,
              attributes: data.groupNames.map((groupName) => ({
                value: groupName,
              })),
            }),
            cellTemplate: AttributeListCellTemplate,
            className: 'textAttributes',
          },
          {
            mapDataToProps: (data) => ({
              showAttributeKeys: false,
              attributes: data.reviewers.map((reviewer) => ({
                value: reviewer,
              })),
            }),
            cellTemplate: AttributeListCellTemplate,
            className: 'textAttributes',
          },
          {
            mapDataToProps: (data) => ({
              showAttributeKeys: false,
              attributes: data.reviewerUuids.map((reviewerUuid) => ({
                value: reviewerUuid,
              })),
            }),
            cellTemplate: AttributeListCellTemplate,
            className: 'numericAttributes',
          },
        ],
      },
    },
    loadingRowTemplate: [
      {
        cellTemplate: ApplicationStatusCellTemplateLoading,
      },
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'textAttributes',
      },
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'textAttributes',
      },
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'textAttributes',
      },
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'numericAttributes',
      },
    ],
  },
  [USER_PROFILE_TAB.MONITORING]: {
    columnSpec: [
      { title: intl('user.application'), className: 'align-left' },
      { title: intl('user.entitlement_type'), className: 'align-left' },
      { title: intl('firm.coverage'), className: 'align-left coverage-column' },
    ],
    rowTemplates: {
      memberDetailsMonitoring: {
        cells: [
          {
            mapDataToProps: (data) => ({
              name: data.application,
              alert: data.alert,
            }),
            cellTemplate: ApplicationStatusCellTemplate,
          },
          {
            mapDataToProps: (data) => ({
              showAttributeKeys: false,
              attributes: data.reviewScopes.map((reviewScope) => ({
                value: reviewScope,
              })),
            }),
            cellTemplate: AttributeListCellTemplate,
            className: 'textAttributes',
          },
          {
            mapDataToProps: (data) => ({
              showAttributeKeys: true,
              numericAttributes: true,
              attributes: data.monitoring.map((monitoring) => ({
                key: monitoring.type,
                value: monitoring.count,
              })),
            }),
            cellTemplate: AttributeListCellTemplate,
            className: 'numericAttributes',
          },
        ],
      },
    },
    loadingRowTemplate: [
      {
        cellTemplate: ApplicationStatusCellTemplateLoading,
      },
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'textAttributes',
      },
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'numericAttributes',
      },
    ],
  },
};

class UserProfileGrid extends React.Component {
  render() {
    const {
      tab,
      rows = [],
      templateToOnRowClick = {},
      gridFooter,
    } = this.props;

    var tmpl = GRID_CONFIG[tab].rowTemplates;
    Object.keys(templateToOnRowClick).forEach((t) => {
      tmpl[t]['onRowClick'] = templateToOnRowClick[t];
    });

    return (
      <div className="userProfileMonitoredBy">
        <Grid
          columns={GRID_CONFIG[tab].columnSpec}
          rowtemplates={tmpl}
          gridContent={(rowGenerator) => rows.map(rowGenerator)}
          gridFooter={gridFooter}
        />
      </div>
    );
  }
}

UserProfileGrid.propTypes = {
  tab: PropTypes.oneOf(Object.values(USER_PROFILE_TAB)).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  templateToOnRowClick: PropTypes.object,
};

export default UserProfileGrid;

export const UserProfileGridLoading = ({ tab }) => (
  <div>
    <GridLoading
      columns={GRID_CONFIG[tab].columnSpec}
      rowtemplate={GRID_CONFIG[tab].loadingRowTemplate}
      rowCount={5}
    />
  </div>
);

UserProfileGridLoading.propTypes = {
  tab: PropTypes.oneOf(Object.values(USER_PROFILE_TAB)).isRequired,
};

export const UserProfileGridError = ({ tab }) => (
  <div>
    <GridError columns={GRID_CONFIG[tab].columnSpec} rowCount={5} />
  </div>
);

UserProfileGridError.propTypes = {
  tab: PropTypes.oneOf(Object.values(USER_PROFILE_TAB)).isRequired,
};
