import React from 'react';
import PropTypes from 'prop-types';

import './styles/Checkbox.css';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: props.isSelected,
    };
    this.onChange = props.onChange || ((name, value, isSelected) => {});
  }

  handleOnChange = (e) => {
    const { name } = this.props;
    const isSelected = !this.state.isSelected ? true : false;
    this.setState({ isSelected });
    this.onChange(name, e.target.value, isSelected);
  };

  render() {
    const { label, name, value } = this.props;
    const { isSelected } = this.state;

    return (
      <div className="checkbox">
        <input
          type="checkbox"
          className="checkbox"
          checked={isSelected ? 'checked' : ''}
          onChange={this.handleOnChange}
          name={name}
          value={value}
          id={name + value}
        />
        <label htmlFor={name + value} className="checkboxLabel">
          {label}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
};

export class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentValue: props.currentValue || [] };
    this.onValueChange = props.onValueChange || (() => {});
  }

  handleOnChange = (name, value, isSelected) => {
    const { currentValue = [] } = this.state;
    var newValue;
    if (isSelected && !currentValue.includes(value)) {
      newValue = (
        Array.isArray(currentValue) ? currentValue : [currentValue]
      ).concat([value]);
    } else if (!isSelected && currentValue.includes(value)) {
      newValue = (
        Array.isArray(currentValue) ? currentValue : [currentValue]
      ).filter((v) => v !== value);
    }
    if (newValue) {
      this.setState({ currentValue: newValue });
      this.onValueChange(newValue);
    }
  };

  render() {
    const { name, items, currentValue = [] } = this.props;

    return (
      <div className="checkboxGroup">
        {items.map(({ label, value }, i) => (
          <Checkbox
            key={i}
            name={name}
            label={label}
            value={value}
            isSelected={
              Array.isArray(currentValue)
                ? currentValue.includes(value)
                : currentValue === value
            }
            onChange={this.handleOnChange}
          />
        ))}
      </div>
    );
  }
}
CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({ ...Checkbox.propTypes, name: PropTypes.string })
  ),
  currentValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onValueChange: PropTypes.func,
};
export default Checkbox;
