/* eslint-disable */
import debugMod from 'debug';
import { get as _get } from 'lodash';
import { getStatus } from 'redux-resource';

/*
import {readUser,
        readUserFromCuid,
        readMonitoredByFromUuid,
        readMonitoredByFromCuid,
        readMonitoringFromUuid,
        readMonitoringFromCuid} from '../store/apiActions'
        */

// eslint-disable-next-line
const debug = debugMod('roster:ProfileUtils');

export const PATH_USER_INFO_TYPE = {
  u: 'uuid',
  c: 'cuid',
};

export const createUrlUserInfo = ({ userIdType, userId }) => {
  return (
    userIdType &&
    userId && {
      type: userIdType,
      typeName: PATH_USER_INFO_TYPE[userIdType],
      id: parseInt(userId, 10),
    }
  );
};

export const userResourceAndStatus = (state, urlUserInfo) => {
  const { users, usersFromCuid } = state;
  const { typeName, id: userId } = urlUserInfo;
  let userResource;
  let userStatus;
  if (PATH_USER_INFO_TYPE.u === typeName) {
    userResource = users.resources[`${userId}`];
    userStatus = getStatus(state, `users.meta.${userId}.readStatus`, false);
  } else if (PATH_USER_INFO_TYPE.c === typeName) {
    userResource = usersFromCuid.resources[`${userId}`];
    userStatus = getStatus(
      state,
      `usersFromCuid.meta.${userId}.readStatus`,
      false
    );
  }

  return [userResource, userStatus];
};
export const jurisdictionResourceAndStatus = (state, urlUserInfo) => {
  const { usersFromCuid, jurisdictions } = state;
  const { typeName, id: userId } = urlUserInfo;
  let jurisdictionResource;
  let jurisdictionStatus;
  if (PATH_USER_INFO_TYPE.u === typeName) {
    jurisdictionResource = _get(jurisdictions, `resources[${userId}]`);
    jurisdictionStatus = getStatus(
      state,
      `jurisdictions.meta.${userId}.readStatus`,
      false
    );
  } else if (PATH_USER_INFO_TYPE.c === typeName) {
    const uuid = _get(
      _get(usersFromCuid, `resources[${userId}]`, {}),
      'attributes.uuid'
    );
    if (uuid) {
      jurisdictionResource = _get(jurisdictions, `resources[${uuid}]`);
      jurisdictionStatus = getStatus(
        state,
        `jurisdictions.meta.${uuid}.readStatus`,
        false
      );
    }
  }

  return [jurisdictionResource, jurisdictionStatus];
};
export const monitoredByResourceAndStatus = (state, urlUserInfo) => {
  const { monitoredByFromUuid, monitoredByFromCuid } = state;
  const { typeName, id: userId } = urlUserInfo;
  let monitoredByResource;
  let monitoredByStatus;
  if (PATH_USER_INFO_TYPE.u === typeName) {
    monitoredByResource = monitoredByFromUuid.resources[`${userId}`];
    monitoredByStatus = getStatus(
      state,
      `monitoredByFromUuid.meta.${userId}.readStatus`,
      false
    );
  } else if (PATH_USER_INFO_TYPE.c === typeName) {
    monitoredByResource = monitoredByFromCuid.resources[`${userId}`];
    monitoredByStatus = getStatus(
      state,
      `monitoredByFromCuid.meta.${userId}.readStatus`,
      false
    );
  }

  return [monitoredByResource, monitoredByStatus];
};
export const monitoringResourceAndStatus = (state, urlUserInfo) => {
  const { monitoringFromUuid, monitoringFromCuid } = state;
  const { typeName, id: userId } = urlUserInfo;
  let monitoringResource;
  let monitoringStatus;
  if (PATH_USER_INFO_TYPE.u === typeName) {
    monitoringResource = monitoringFromUuid.resources[`${userId}`];
    monitoringStatus = getStatus(
      state,
      `monitoringFromUuid.meta.${userId}.readStatus`,
      false
    );
  } else if (PATH_USER_INFO_TYPE.c === typeName) {
    monitoringResource = monitoringFromCuid.resources[`${userId}`];
    monitoringStatus = getStatus(
      state,
      `monitoringFromCuid.meta.${userId}.readStatus`,
      false
    );
  }

  return [monitoringResource, monitoringStatus];
};

export const retrieveUser = ({
  urlUserInfo,
  userStatus,
  readUser,
  readUserFromCuid,
}) => {
  debug('Handling readUser from', urlUserInfo);

  // Read only if userResource fetch not pending or failed
  if (!(userStatus.pending || userStatus.failed)) {
    if (PATH_USER_INFO_TYPE.u === urlUserInfo.typeName) {
      debug('Reading from uuid', urlUserInfo.id);
      return readUser(urlUserInfo.id);
    }
    if (PATH_USER_INFO_TYPE.c === urlUserInfo.typeName) {
      debug('Reading from cuid', urlUserInfo.id);
      return readUserFromCuid({ cuid: urlUserInfo.id });
    }
  }
};
export const retrieveMonitoredBy = ({
  firmId,
  urlUserInfo,
  monitoredByResource,
  monitoredByStatus,
  readMonitoredByFromUuid,
  readMonitoredByFromCuid,
}) => {
  debug('Handling readMonitoredBy from', urlUserInfo);

  // Read only if it
  // does not exist and
  // not pending or failed
  if (
    !monitoredByResource &&
    !(monitoredByStatus.pending || monitoredByStatus.failed)
  ) {
    if (PATH_USER_INFO_TYPE.u === urlUserInfo.typeName) {
      debug('Reading from uuid', urlUserInfo.id);
      return readMonitoredByFromUuid({ firmId, uuid: urlUserInfo.id });
    }

    if (PATH_USER_INFO_TYPE.c === urlUserInfo.typeName) {
      debug('Reading from cuid', urlUserInfo.id);
      return readMonitoredByFromCuid({ firmId, cuid: urlUserInfo.id });
    }
  }
};
export const retrieveMonitoring = ({
  firmId,
  urlUserInfo,
  monitoringResource,
  monitoringStatus,
  readMonitoringFromUuid,
  readMonitoringFromCuid,
}) => {
  debug('Handling readMonitoring from', urlUserInfo);

  // Read only if it
  // does not exist and
  // not pending or failed
  if (
    !monitoringResource &&
    !(monitoringStatus.pending || monitoringStatus.failed)
  ) {
    if (PATH_USER_INFO_TYPE.u === urlUserInfo.typeName) {
      debug('Reading from uuid', urlUserInfo.id);
      return readMonitoringFromUuid({ firmId, uuid: urlUserInfo.id });
    }

    if (PATH_USER_INFO_TYPE.c === urlUserInfo.typeName) {
      debug('Reading from cuid', urlUserInfo.id);
      return readMonitoringFromCuid({ firmId, cuid: urlUserInfo.id });
    }
  }
};

export const retrieveJurisdiction = ({
  urlUserInfo,
  jurisdictionResource,
  jurisdictionStatus = {},
  readJurisdiction,
  userResource = {},
}) => {
  debug('Handling readJurisdiction from', urlUserInfo);

  // Read only if it
  // does not exist and
  // not pending or failed
  if (
    !jurisdictionResource &&
    !(jurisdictionStatus.pending || jurisdictionStatus.failed)
  ) {
    if (PATH_USER_INFO_TYPE.u === urlUserInfo.typeName) {
      debug('Reading from uuid', urlUserInfo.id);
      return readJurisdiction(urlUserInfo.id);
    }
    if (PATH_USER_INFO_TYPE.c === urlUserInfo.typeName && userResource.uuid) {
      debug('Reading from cuid', urlUserInfo.id, userResource);
      return readJurisdiction(userResource.uuid);
    }
  }
};

export const fullNameFromUserAttributes = (user) => {
  const { firstName, lastName } = user || {};
  return firstName && lastName && `${firstName} ${lastName}`;
};

export const UNKNOWN_NAME_PATTERN = new RegExp(/.*\(U\)$/);
export const userIsUnknown = (userAttrs) => {
  const { firstName, lastName } = userAttrs || {};
  return nameIsUnknown({ first: firstName, last: lastName });
};
export const nameIsUnknown = ({ first, last }) => {
  return !!last.match(UNKNOWN_NAME_PATTERN);
};

export const DELETED_USER_PATTERN = new RegExp(/.*\(D\)$/);
export const userIsDeleted = (userAttrs) => {
  const { firstName, lastName } = userAttrs || {};
  return nameIsDeleted({ first: firstName, last: lastName });
};
export const nameIsDeleted = ({ first, last }) => {
  return !!last.match(DELETED_USER_PATTERN);
};

export const userIsInactive = (userAttrs) => userAttrs.isInactive !== 'N';
/* eslint-enable */
