import React from 'react';

import FontIcon from './FontIcon';

import './styles/Loader.css';
import { intl } from '../intl/IntlFormat';

const LoadingAnimation = ({ isLoading }) => (
  <div className="loadingAnimation">
    <span className={`dot${isLoading ? ' one' : ''}`}>
      <FontIcon icon="circle_solid" />
    </span>
    <span className={`dot${isLoading ? ' two' : ''}`}>
      <FontIcon icon="circle_solid" />
    </span>
    <span className={`dot${isLoading ? ' three' : ''}`}>
      <FontIcon icon="circle_solid" />
    </span>
  </div>
);

const Loader = ({ message = intl('utility.loading'), className }) => (
  <div className={`loader ${className || ''}`}>
    <LoadingAnimation isLoading={true} />
    <h1>{message}</h1>
  </div>
);

export default Loader;

export { LoadingAnimation };
