import React from 'react';
import PropTypes from 'prop-types';

import RadioButton from './RadioButton';

import './styles/RadioButton.css';

class RadioButtonGroup extends React.Component {
  render() {
    const { items, currentValue, onValueChange = () => {} } = this.props;

    return (
      <div className="radioButtonGroup">
        {items.map(({ label, value }, index) => (
          <RadioButton
            label={label}
            value={value}
            isSelected={currentValue === value}
            key={index}
            onClick={() => {
              onValueChange(value);
            }}
          />
        ))}
      </div>
    );
  }
}

const RadioButtonShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

RadioButtonGroup.propTypes = {
  items: PropTypes.arrayOf(RadioButtonShape),
  currentValue: PropTypes.string.isRequired,
  onValueChange: PropTypes.func,
};

export default RadioButtonGroup;
