/* eslint-disable */
import debugMod from 'debug';

const debug = debugMod('roster:AlertManager');

const ACTIONTYPES = {
  CREATE_ALERT: 'CREATE_ALERT',
  DISMISS_ALERT: 'DISMISS_ALERT',
};

export const ALERT_TYPES = {
  INFO: 'INFO',
  ERROR: 'ERROR',
};

let nextKey = 0;

function createAlertProcessor(state, action) {
  const key = `${nextKey++}`;

  const newAlert = {
    alertKey: key,
    type: action.alertType,
    message: action.alertMessage,
    duration: action.duration,
  };

  debug(`Creating alert with key ${key}`);
  debug(newAlert);

  return {
    alerts: {
      ...state.alerts,
      [key]: newAlert,
    },
  };
}

function dismissAlertProcessor(state, action) {
  const { [action.key]: dismissedAlert, ...alerts } = state.alerts;

  debug(`Dismissing alert with key ${action.key}`);
  debug(dismissedAlert);

  return {
    alerts,
  };
}

export function alertManagerReducer(state = { alerts: {} }, action) {
  switch (action.type) {
    case ACTIONTYPES.CREATE_ALERT:
      return createAlertProcessor(state, action);
    case ACTIONTYPES.DISMISS_ALERT:
      return dismissAlertProcessor(state, action);
    default:
      return state;
  }
}

export function createAlert(
  alertType = ALERT_TYPES.INFO,
  alertMessage = '',
  duration = -1
) {
  return {
    type: ACTIONTYPES.CREATE_ALERT,
    alertType,
    alertMessage,
    duration,
  };
}

export function dismissAlert(key) {
  return {
    type: ACTIONTYPES.DISMISS_ALERT,
    key,
  };
}
/* eslint-enable */
