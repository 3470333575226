/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import './styles/SlideOut.css';

export const SlideOut = ({ children }) => (
  <div className="slideOutWrapper">
    <div className="slideOutContent">{children}</div>
  </div>
);

SlideOut.propTypes = {
  children: PropTypes.element.isRequired,
};
/* eslint-enable */
