import React from 'react';
import PropTypes from 'prop-types';

import FontIcon from '../FontIcon';

import './styles/RadioButton.css';

class RadioButton extends React.Component {
  render() {
    const { label, value, isSelected, onClick = () => {} } = this.props;

    return (
      <div
        className="radioButton"
        onClick={(event) => {
          event.preventDefault();
          onClick(value);
        }}
      >
        <FontIcon icon={isSelected ? 'radio-selected' : 'radio-unselected'} />
        <span className="radioButtonLabel">{label}</span>
      </div>
    );
  }
}

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default RadioButton;
