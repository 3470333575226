/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { ALERT_TYPES } from '../store/alertManager';
import FontIcon from './FontIcon';

import './styles/Alerts.css';

const ALERT_TYPE_SPEC = {
  [ALERT_TYPES.INFO]: {
    icon: 'circle-check',
    className: 'alert info',
  },
  [ALERT_TYPES.ERROR]: {
    icon: 'circle-close-filled',
    className: 'alert error',
  },
};

class Alert extends React.Component {
  componentDidMount() {
    const { alertKey, duration = -1, onDismiss = () => {} } = this.props;

    if (duration > 0) {
      setTimeout(() => {
        onDismiss(alertKey);
      }, duration);
    }
  }

  render() {
    const { alertKey, type, message, onDismiss = () => {} } = this.props;

    const { className, icon } = ALERT_TYPE_SPEC[type];

    return (
      <div className={className}>
        <span className="alertIcon">
          <FontIcon icon={icon} />
        </span>
        <span className="message">{message}</span>
        <span
          className="dismissIcon"
          onClick={(event) => {
            event.preventDefault();
            onDismiss(alertKey);
          }}
        >
          <FontIcon icon="close" />
        </span>
      </div>
    );
  }
}

const AlertShape = {
  alertKey: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(ALERT_TYPES)).isRequired,
  message: PropTypes.string.isRequired,
  duration: PropTypes.number,
  onDismiss: PropTypes.func,
};

Alert.propTypes = AlertShape;

class Alerts extends React.Component {
  render() {
    const { alerts, dismissAlert = () => {} } = this.props;
    return (
      <div className="alertsOverlay">
        {Object.values(alerts).map((alert) => (
          <Alert
            alertKey={alert.alertKey}
            type={alert.type}
            message={alert.message}
            duration={alert.duration}
            key={alert.alertKey}
            onDismiss={dismissAlert}
          />
        ))}
      </div>
    );
  }
}

Alerts.propTypes = {
  alerts: PropTypes.objectOf(PropTypes.shape(AlertShape)),
  dismissAlert: PropTypes.func,
};

export { Alert };

export default Alerts;
/* eslint-enable */
