import React from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ControlOwnersJurisdictionContainer from './ControlOwnersJurisdictionContainer';
import { Route, Switch } from 'react-router';

export class ControlOwnersJurisdictionRouter extends React.Component {
  render() {
    const timeout = { enter: 1000, exit: 1000 };

    return (
      <TransitionGroup component="main" className="page-main">
        <CSSTransition key={'/'} timeout={timeout} classNames="slide" appear>
          <Switch>
            <Route
              path="/controlOwners/jurisdictions/:uuid/show"
              component={ControlOwnersJurisdictionContainer}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default connect()(ControlOwnersJurisdictionRouter);
