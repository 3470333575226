/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import FontIcon from './FontIcon';
import { intl } from '../intl/IntlFormat';

import './styles/MonitoredEmployeesAppStatusIcon.css';

const MONITORED_EMPLOYEES_APP_STATUS_TYPES = {
  MONITORED: 'Monitored',
  NOT_MONITORED: 'NotMonitored',
  SELF_MONITORING: 'SelfMonitoring',
  NOT_APPLICABLE: 'NotApplicable',
};

const STATUS_INDICATOR_MAP = {
  // Admin Tab Statuses
  [MONITORED_EMPLOYEES_APP_STATUS_TYPES.MONITORED]: {
    icon: 'ok',
    className: 'monitored',
    label: intl('firm.enabled_and_configured'),
  },
  [MONITORED_EMPLOYEES_APP_STATUS_TYPES.NOT_MONITORED]: {
    svgIcon: (
      <svg height="10" width="10">
        <path d="M5 0 L0 5 L5 10 L10 5 Z" stroke="yellow" fill="yellow" />
      </svg>
    ),
    className: 'notMonitored',
    label: intl('firm.enabled_and_not_configured'),
  },
  [MONITORED_EMPLOYEES_APP_STATUS_TYPES.SELF_MONITORING]: {
    icon: 'circle-alert',
    className: 'selfMonitoring',
    label: intl('firm.self_monitoring'),
  },
  [MONITORED_EMPLOYEES_APP_STATUS_TYPES.NOT_APPLICABLE]: {
    icon: 'circle_solid',
    className: 'notApplicable',
    label: intl('firm.not_enabled'),
  },
};

const MonitoredEmployeesAppStatusIcon = ({ status, displayLabel = false }) => (
  <span
    className={`monitoredEmployeesAppStatusIcon ${STATUS_INDICATOR_MAP[status].className}`}
  >
    {STATUS_INDICATOR_MAP[status].icon && (
      <FontIcon icon={STATUS_INDICATOR_MAP[status].icon} />
    )}
    {STATUS_INDICATOR_MAP[status].svgIcon && (
      <span className="svgIcon">{STATUS_INDICATOR_MAP[status].svgIcon}</span>
    )}
    {displayLabel && (
      <span className="monitoredEmployeesAppStatusLabel">
        {STATUS_INDICATOR_MAP[status].label}
      </span>
    )}
  </span>
);

MonitoredEmployeesAppStatusIcon.propTypes = {
  status: PropTypes.oneOf(Object.values(MONITORED_EMPLOYEES_APP_STATUS_TYPES))
    .isRequired,
  displayLabel: PropTypes.bool,
};

export default MonitoredEmployeesAppStatusIcon;
export { MONITORED_EMPLOYEES_APP_STATUS_TYPES };
/* eslint-enable */
