/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modals';
import { DatumSection, DatumPair } from './Datum';

import './styles/GenerateReportModal.css';
import { DatumListType } from '../PropTypes';
import { FormattedMessage } from 'react-intl';
import { intl } from '../intl/IntlFormat';

class GenerateReportModal extends React.Component {
  render() {
    const {
      firmName = '',
      scope = '',
      reportTitle = '',
      bbgEmail = '',
      corpEmail = '',
      isImpersonating = false,
      additionalDatum = [],
      description,
      onCancel = () => {},
      onGenerate = () => {},
    } = this.props;

    return (
      <Modal className="generateReport">
        <span className="modalTitle">
          <FormattedMessage id="report.generate_report" />
        </span>
        <DatumSection sectionTitle={intl('report.name')}>
          <span className="reportTitle">{reportTitle}</span>
        </DatumSection>
        {description ? <span className="desc">{description}</span> : ''}
        <DatumSection sectionTitle={intl('report.details')}>
          {firmName && (
            <DatumPair
              datum={{ name: intl('control_owner.firm'), value: firmName }}
            />
          )}
          {scope && (
            <DatumPair datum={{ name: intl('report.scope'), value: scope }} />
          )}
          <DatumPair
            datum={{ name: intl('report.export_format'), value: '.CSV' }}
          />
          {additionalDatum.map((d, i) => (
            <DatumPair key={i} datum={d} />
          ))}
        </DatumSection>
        {isImpersonating ? (
          <span className="impersonating">
            <h1>
              <FormattedMessage id="report.impersonating" />
            </h1>
            <h3>
              <FormattedMessage id="report.mail_will_be_sent_impersonator" />
            </h3>
          </span>
        ) : (
          // else
          <DatumSection sectionTitle={intl('report.destination')}>
            {bbgEmail && (
              <DatumPair
                datum={{
                  name: intl('report.send_to_bbg_email'),
                  value: bbgEmail,
                }}
              />
            )}
            {corpEmail && (
              <DatumPair
                datum={{
                  name: intl('report.send_to_corp_email'),
                  value: corpEmail,
                }}
              />
            )}
          </DatumSection>
        )}
        <span className="footNote">
          <FormattedMessage id="report.eta_message" />
        </span>
        <div className="controls">
          {/* eslint-disable-next-line */}
          <a
            className="cancelButton"
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
          >
            <FormattedMessage id="utility.cancel" />
          </a>
          {/* eslint-disable-next-line */}
          <a
            className="generateButton"
            onClick={(e) => {
              e.preventDefault();
              onGenerate();
            }}
          >
            <FormattedMessage id="report.generate_and_send" />
          </a>
        </div>
      </Modal>
    );
  }
}

GenerateReportModal.propTypes = {
  firmName: PropTypes.string,
  reportTitle: PropTypes.string.isRequired,
  description: PropTypes.node,
  additionalDatum: DatumListType,
  scope: PropTypes.string,
  bbgEmail: PropTypes.string.isRequired,
  corpEmail: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onGenerate: PropTypes.func.isRequired,
};

export default GenerateReportModal;
/* eslint-enable */
