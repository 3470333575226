import { createSelector } from 'reselect';
import { get } from 'lodash';
import { getStatus } from 'redux-resource';
import debugMod from 'debug';

// eslint-disable-next-line
const debug = debugMod('roster:selectors');

const routerMatchParamsSelector = (state, props) =>
  get(props, 'match.params', {});

/// /////////////////
// Firms Resource //
/// /////////////////
const firmsResourcesSelector = (state) => state.firms.resources;
export const matchedFirmSelector = createSelector(
  [firmsResourcesSelector, routerMatchParamsSelector],
  (firms, matchParams) => firms[`${matchParams.firmId}`]
);

/// /////////////////
// Users Resource //
/// /////////////////
const usersResourcesSelector = (state) => state.users.resources;
const usersMetaSelector = (state) => state.users.meta;
export const currentUserSelector = createSelector(
  [usersResourcesSelector],
  (users) => get(users.currentUser, 'attributes')
);
export const currentUserAndStatusSelector = createSelector(
  [currentUserSelector, usersMetaSelector],
  (currentUser, meta) => [
    currentUser,
    getStatus(meta, 'currentUser.readStatus', true),
  ]
);
export const uuidUserSelector = createSelector(
  [usersResourcesSelector, routerMatchParamsSelector],
  (users, matchParams) => get(users[`${matchParams.uuid}`], 'attributes')
);

/// /////////////////////////
// Jurisdictions Resource //
/// /////////////////////////
/* FIXME Retrieve Jurisdictions from store
const jurisdictionsMetaSelector = state => state.jurisdictions.meta
export const currentUserJurisdictionSelector = createSelector(
  [currentUserSelector, jurisdictionsResourcesSelector],
  (currentUser, jurisdictions) => currentUser && get(jurisdictions[`${currentUser.uuid}`], 'attributes')
  )
  export const currentUserJurisdictionAndStatusSelector = createSelector(
    [currentUserJurisdictionSelector, currentUserSelector, jurisdictionsMetaSelector],
    (currentUserJurisdiction, currentUser, meta) => [
      currentUserJurisdiction, 
      currentUser ? getStatus(meta, `${currentUser.uuid}.readStatus`, true) : undefined
    ]
    )
*/

const jurisdictionsResourcesSelector = (state) => state.jurisdictions.resources;
export const currentUserJurisdictionSelector = createSelector(
  [currentUserSelector],
  (currentUser) => currentUser && get(currentUser, 'jurisdiction')
);
export const currentUserJurisdictionAndStatusSelector = createSelector(
  [currentUserAndStatusSelector],
  ([currentUser, currentUserStatus]) => [
    currentUser && get(currentUser, 'jurisdiction'),
    currentUserStatus,
  ]
);
export const uuidJurisdictionSelector = createSelector(
  [jurisdictionsResourcesSelector, routerMatchParamsSelector],
  (jurisdictions, matchParams) => {
    return (
      jurisdictions && get(jurisdictions[`${matchParams.uuid}`], 'attributes')
    );
  }
);

/// //////////////////////////
// Control Owners Resource //
/// //////////////////////////
const controlOwnersResourcesSelector = (state) => state.controlOwners.resources;
// const controlOwnersMetaSelector = state => state.controlOwners.meta
const controlOwnersRequestsSelector = (state) => state.controlOwners.requests;
export const lastControlOwnersRequestSelector = createSelector(
  [controlOwnersRequestsSelector],
  (requests) => requests.readControlOwners
);
export const lastControlOwnersRequestStatusSelector = createSelector(
  [lastControlOwnersRequestSelector],
  (request) => (request ? getStatus(request, 'status', true) : undefined)
);
export const lastControlOwnersRequestAndStatusSelector = createSelector(
  [lastControlOwnersRequestSelector, lastControlOwnersRequestStatusSelector],
  (request, status) => [request, status]
);
export const controlOwnersSelector = createSelector(
  [lastControlOwnersRequestSelector, controlOwnersResourcesSelector],
  (request, resources) =>
    request && request.ids ? request.ids.map((id) => resources[id]) : []
);
