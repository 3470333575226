import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { jwtAuthReducer } from '../utils/JwtAuth';
import { alertManagerReducer } from './alertManager';
import { reportsManagerReducer } from './reportsManager';
import {
  firmsReducer,
  usersReducer,
  jurisdictionsReducer,
  contractsReducer,
  adminRosterReducer,
  monitoredEmployeesRosterReducer,
  firmFromCaidReducer,
  firmFromCustReducer,
  usersFromCuidReducer,
  monitoredByFromUuidReducer,
  monitoredByFromCuidReducer,
  monitoringFromUuidReducer,
  monitoringFromCuidReducer,
  adminMonitoredEmployeesReducer,
  custsReducer,
  caidsReducer,
  leisReducer,
  controlOwnersReducer,
} from './apiActions';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default (history) => {
  const middlewareRouter = routerMiddleware(history);
  return createStore(
    combineReducers({
      firms: firmsReducer,
      contracts: contractsReducer,
      users: usersReducer,
      jurisdictions: jurisdictionsReducer,
      controlOwners: controlOwnersReducer,
      adminRoster: adminRosterReducer,
      monitoredEmployeesRoster: monitoredEmployeesRosterReducer,
      custs: custsReducer,
      caids: caidsReducer,
      leis: leisReducer,
      router: routerReducer,
      JwtAuth: jwtAuthReducer,
      firmFromCaid: firmFromCaidReducer,
      firmFromCust: firmFromCustReducer,
      alertManager: alertManagerReducer,
      reportsManager: reportsManagerReducer,
      usersFromCuid: usersFromCuidReducer,
      monitoredByFromUuid: monitoredByFromUuidReducer,
      monitoredByFromCuid: monitoredByFromCuidReducer,
      monitoringFromUuid: monitoringFromUuidReducer,
      monitoringFromCuid: monitoringFromCuidReducer,
      adminMonitoredEmployees: adminMonitoredEmployeesReducer,
    }),
    composeEnhancers(applyMiddleware(thunkMiddleware, middlewareRouter))
  );
};
