/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { getStatus } from 'redux-resource';
import { Route, Redirect, Switch, matchPath } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { get as _get } from 'lodash';
import debugMod from 'debug';

import {
  readUser,
  readUserFromCuid,
  readMonitoredByFromUuid,
  readMonitoredByFromCuid,
  readMonitoringFromUuid,
  readMonitoringFromCuid,
  readJurisdiction,
} from '../store/apiActions';
import UserProfile from '../components/userProfile/UserProfile';
import UserProfileMemberDetails, {
  UserProfileMemberDetailsLoading,
  UserProfileMemberDetailsError,
} from '../components/userProfile/UserProfileMemberDetails';
import UserProfileGrid, {
  UserProfileGridLoading,
  UserProfileGridError,
} from '../components/userProfile/UserProfileGrid';
import { ADMIN_APP_STATUS_TYPES } from '../components/AdminAppStatusIcon';

import { formatIfNumeral } from '../utils/FormatUtils';
import { queryStringParse } from '../utils/QueryStringParser';
import {
  APP_ORDER,
  APP_FULL_NAME,
  MONITORING_COUNT_TYPES,
  MONITORING_COUNT_LABEL_MAP,
  ADMIN_ROLE_TYPES,
  setupTypeLabels,
  isAppSupported,
  SUPPORTED_APPS,
} from '../utils/Enums';
import { DatumHero, DatumHeroLoading, TabSummary } from '../components/Datum';
import {
  userProfileMonitoringAppLink,
  btoaFromUrl,
  safeAtob,
} from '../utils/LinkUtils';
import {
  createUrlUserInfo,
  userResourceAndStatus,
  retrieveUser,
  monitoredByResourceAndStatus,
  monitoringResourceAndStatus,
  retrieveMonitoredBy,
  retrieveMonitoring,
  jurisdictionResourceAndStatus,
  retrieveJurisdiction,
} from '../utils/ProfileUtils';
import AdminMonitoringAppContainer from './AdminMonitoringAppContainer';
import FontIcon from '../components/FontIcon';
import { Tooltip } from '../components/Tooltip';
import { matchedFirmSelector } from '../store/selectors';
import {
  disableSurveillanceReport,
  enableSurveillanceReport,
} from '../store/reportsManager';
import { intl } from '../intl/IntlFormat';

// eslint-disable-next-line
const debug = debugMod('roster:UserProfileContainer');

export const USER_PROFILE_TABS = {
  MONITORED_BY: 'monitoredBy',
  MONITORING: 'monitoring',
  MEMBER_DETAILS: 'memberDetails',
};

const DEFAULT_TAB = USER_PROFILE_TABS.MONITORED_BY;

const mapStateToProps = (state, props) => {
  const { match, location } = props;
  const matchParams = match.params;
  const { from: fromUrl } = queryStringParse(location.search);

  const { firmId, firmTab, subFirmTab } = matchParams;

  const urlUserInfo = createUrlUserInfo(matchParams);

  const tabMatch = matchPath(location.pathname, {
    path: `${match.path}/:userProfileTab(monitoredBy|monitoring|memberDetails)?`,
  });
  const userProfileTab =
    tabMatch && tabMatch.params && tabMatch.params.userProfileTab;

  const firmResource = matchedFirmSelector(state, props);
  const firmStatus = getStatus(state, `firms.meta.${firmId}.readStatus`, false);

  const [userResource, userStatus] = userResourceAndStatus(state, urlUserInfo);
  const [monitoredByResource, monitoredByStatus] = monitoredByResourceAndStatus(
    state,
    urlUserInfo
  );
  const [monitoringResource, monitoringStatus] = monitoringResourceAndStatus(
    state,
    urlUserInfo
  );

  const [
    jurisdictionResource,
    jurisdictionStatus,
  ] = jurisdictionResourceAndStatus(state, urlUserInfo);

  return {
    fromUrl,
    baseUrl: match.url,
    firmId,
    firmTab,
    subFirmTab,
    urlUserInfo,
    userProfileTab,
    isUserInfoLoading: userStatus.pending,
    isUserInfoError: userStatus.failed,
    backButtonUrl: `/firm/${firmId}/${firmTab}/${subFirmTab}`,
    firmResource,
    firmStatus,
    userResource,
    userStatus,
    monitoredByResource,
    monitoredByStatus,
    monitoringResource,
    monitoringStatus,
    jurisdictionResource,
    jurisdictionStatus,
  };
};

const mapDispatchToProps = {
  readUser,
  readUserFromCuid,
  readMonitoredByFromUuid,
  readMonitoredByFromCuid,
  readMonitoringFromUuid,
  readMonitoringFromCuid,
  enableSurveillanceReport,
  disableSurveillanceReport,
  readJurisdiction,
};

export class UserProfileContainer extends React.Component {
  componentDidMount() {
    this.readUserPromise = retrieveUser(this.props);
    this.readMonitoredByPromise = retrieveMonitoredBy(this.props);
    this.readMonitoringPromise = retrieveMonitoring(this.props);
    this.readJurisdictionPromise = retrieveJurisdiction(this.props);

    this.props.enableSurveillanceReport(this.props.firmId);
  }

  componentWillUnmount() {
    this.props.disableSurveillanceReport();
  }

  headerDataRetriever = (props = this.props) => {
    const { userResource } = props;

    if (userResource) {
      const { uuid, cuid, firstName, lastName } = userResource.attributes;

      return {
        userInfo: {
          fullName: `${firstName} ${lastName}`,
          userIds: {
            uuid,
            cuid,
          },
        },
      };
    }
    return {
      userInfo: {
        fullName: '',
        userIds: {},
      },
    };
  };

  dataRetrievers = (props = this.props) => {
    const self = this;
    const {
      firmResource,
      userResource,
      userStatus,
      monitoredByResource,
      monitoredByStatus,
      monitoringResource,
      monitoringStatus,
      jurisdictionResource,
    } = props;

    return {
      [USER_PROFILE_TABS.MONITORED_BY]: {
        summaryHero() {
          if (monitoredByResource) {
            const admins = parseInt(
              monitoredByResource.meta.datum[0].MonitoredBy,
              10
            );

            const summary = [
              {
                name: intl('user.monitored_by'),
                value: (
                  <TabSummary
                    value={formatIfNumeral(admins)}
                    unit={`Admin${admins > 1 ? 's' : ''}`}
                  />
                ),
              },
            ];
            return <DatumHero data={summary} />;
          }
          return <DatumHeroLoading count={1} />;
        },

        renderer() {
          if (monitoredByResource && monitoredByResource.data) {
            const rows = monitoredByResource.data
              .filter(({ attributes: { application } }) =>
                isAppSupported(application)
              )
              .map(({ type, attributes: { application, admins } }) => {
                const groupNames = [];
                const setupTypes = [];
                const reviewers = [];
                const reviewerUuids = [];

                admins.forEach(([adminUuid, admin]) => {
                  admin.groupNameToSetupType.forEach(
                    ({ groupName, setupType }) => {
                      groupNames.push(groupName);
                      setupTypes.push(setupType);
                    }
                  );
                  reviewers.push(`${admin.name.first} ${admin.name.last}`);
                  reviewerUuids.push(adminUuid);
                });

                return {
                  templateName: type,
                  application: APP_FULL_NAME[application],
                  alert: false, // until alert is ready from backend
                  groupNames: Array.from(new Set(groupNames)),
                  setupTypes: setupTypeLabels(application, setupTypes),
                  reviewers,
                  reviewerUuids,
                };
              });
            return (
              <UserProfileGrid
                tab={USER_PROFILE_TABS.MONITORED_BY}
                rows={rows}
              />
            );
          }
          if (monitoredByStatus.pending) {
            return (
              <UserProfileGridLoading tab={USER_PROFILE_TABS.MONITORED_BY} />
            );
          }
          if (monitoredByStatus.failed) {
            return (
              <UserProfileGridError tab={USER_PROFILE_TABS.MONITORED_BY} />
            );
          }
          return <div />;
        },
      },
      [USER_PROFILE_TABS.MONITORING]: {
        summaryHero() {
          if (monitoringResource && monitoringResource.data) {
            const adminMonitoredEmployeesMeta = _get(
              monitoringResource,
              'data.relationships.adminMonitoredEmployees.meta',
              {}
            );
            const leisCount = _get(
              monitoringResource,
              'data.attributes.monitoringCounts.LEI',
              0
            );
            const {
              total: monitoredEmployees,
              totalOutsideJurisdiction: outsideJurisdiction,
            } = adminMonitoredEmployeesMeta;
            const summary = [
              {
                name: intl('user.monitoring'),
                value: (
                  <TabSummary
                    value={formatIfNumeral(monitoredEmployees)}
                    unit={`Member${monitoredEmployees > 1 ? 's' : ''}`}
                  />
                ),
              },
            ].concat(
              outsideJurisdiction > 0
                ? [
                    {
                      name: intl('user.inside_jurisdiction'),
                      value: formatIfNumeral(
                        monitoredEmployees - outsideJurisdiction
                      ),
                    },
                    {
                      name: intl('user.outside_jurisdiction'),
                      value: formatIfNumeral(outsideJurisdiction),
                    },
                    { name: 'LEIs', value: formatIfNumeral(leisCount) },
                  ]
                : [{ name: 'LEIs', value: formatIfNumeral(leisCount) }]
            );
            return <DatumHero data={summary} />;
          }
          return <DatumHeroLoading count={1} />;
        },

        renderer() {
          if (monitoringResource) {
            const rows = [];
            let outsideJurisdiction = 0;

            // It is possible that a user is not an admin and
            // the lookup fails
            const { userInfo } = self.headerDataRetriever(self.props);
            const adminName = userInfo.fullName
              ? userInfo.fullName
              : 'This Admin'; // Leaving untranslated for now since this structure is not directly translatable
            const outsideJurisdictionMessage = `${adminName} is monitoring members that are not visible to you based on your Control Owner Jurisdiction.`;
            if (monitoringResource.data) {
              const monitoringAttrs = _get(
                monitoringResource,
                'data.attributes',
                {}
              );
              const {
                applicationStatuses: appStatuses,
                monitoringCountsByApp: monitoringCounts,
              } = monitoringAttrs;
              const adminMonitoredEmployeesMeta = _get(
                monitoringResource,
                'data.relationships.adminMonitoredEmployees.meta',
                {}
              );
              const {
                outsideJurisdictionByApp: outsideByApp,
              } = adminMonitoredEmployeesMeta;
              outsideJurisdiction =
                adminMonitoredEmployeesMeta.totalOutsideJurisdiction;

              APP_ORDER.forEach((app) => {
                if (ADMIN_APP_STATUS_TYPES.NOT_ADMIN !== appStatuses[app]) {
                  const monitoringCount = monitoringCounts[app] || {};
                  rows.push({
                    templateName: 'memberDetailsMonitoring',
                    app,
                    application: APP_FULL_NAME[app],
                    alert: false,
                    reviewScopes: [
                      MONITORING_COUNT_TYPES.FIRM,
                      MONITORING_COUNT_TYPES.CAID,
                      MONITORING_COUNT_TYPES.CUST,
                      MONITORING_COUNT_TYPES.UUID,
                      MONITORING_COUNT_TYPES.CUID,
                      MONITORING_COUNT_TYPES.CUSTOM_GROUP,
                    ]
                      .filter((key) => undefined !== monitoringCount[key])
                      .map((key) => MONITORING_COUNT_LABEL_MAP[key]),
                    monitoring: [
                      MONITORING_COUNT_TYPES.EMPLOYEE,
                      MONITORING_COUNT_TYPES.CAID,
                      MONITORING_COUNT_TYPES.CUST,
                      MONITORING_COUNT_TYPES.LEI,
                      MONITORING_COUNT_TYPES.CUSTOM_GROUP,
                    ]
                      .filter((key) => undefined !== monitoringCount[key])
                      .filter(
                        (key) =>
                          !(
                            SUPPORTED_APPS.TRADE === app &&
                            MONITORING_COUNT_TYPES.EMPLOYEE === key
                          )
                      )
                      .map((key) => ({
                        type:
                          outsideByApp &&
                          outsideByApp[app] &&
                          key === MONITORING_COUNT_TYPES.EMPLOYEE ? (
                            <span>
                              {MONITORING_COUNT_LABEL_MAP[key]}{' '}
                              <Tooltip content={outsideJurisdictionMessage}>
                                <FontIcon
                                  disabled={true}
                                  icon="circleinfo-filled"
                                />
                              </Tooltip>
                            </span>
                          ) : (
                            MONITORING_COUNT_LABEL_MAP[key]
                          ),
                        count: monitoringCount[key],
                      })),
                  });
                }
              });
            }

            return (
              <UserProfileGrid
                tab={USER_PROFILE_TABS.MONITORING}
                rows={rows}
                templateToOnRowClick={{
                  memberDetailsMonitoring: self.onMonitoringRowClick,
                }}
                gridFooter={
                  outsideJurisdiction > 0 ? (
                    <span>
                      <FontIcon disabled={true} icon="circleinfo-filled" />{' '}
                      {outsideJurisdictionMessage}
                    </span>
                  ) : (
                    ''
                  )
                }
              />
            );
          }
          if (monitoringStatus.pending) {
            return (
              <UserProfileGridLoading tab={USER_PROFILE_TABS.MONITORING} />
            );
          }
          if (monitoringStatus.failed) {
            return <UserProfileGridError tab={USER_PROFILE_TABS.MONITORING} />;
          }
          return <div />;
        },
      },
      [USER_PROFILE_TABS.MEMBER_DETAILS]: {
        summaryHero() {
          return (
            <DatumHero
              data={[{ name: intl('user.member_details'), value: '' }]}
            />
          );
        },

        renderer() {
          if (userResource && monitoringResource) {
            const {
              uuid,
              cuid,
              caid,
              firmId,
              bbgEmail,
              cust,
              firstName,
              lastName,
              isInactive,
              licenseType,
              corpEmail,
              compCheck,
              isBillable,
              dataHolds,
            } = userResource.attributes;
            const jurisdiction = _get(jurisdictionResource, 'attributes');

            const { roles = [] } =
              (monitoringResource.data && monitoringResource.data.attributes) ||
              {};

            const controlOwnerInfo = jurisdiction
              ? {
                  jurisdictionType: `${jurisdiction.scope}`,
                  jurisdiction:
                    jurisdiction.scopedEntities.length > 2
                      ? `${jurisdiction.scopedEntities.join(', ')}`
                      : `${jurisdiction.scopedEntities.join(' & ')}`,
                }
              : {};

            const userInfo = {
              firstName,
              lastName,
              bbgEmail,
              bbgLogin:
                /^(.)+@bloomberg\.(net|com)$/gi.test(bbgEmail) &&
                bbgEmail.split('@')[0],
              corpEmail,
              uuid,
              cuid,
              licenseTypeInfo: {
                isInactive: isInactive === 'Y',
                type: licenseType || '--',
              },
              caid,
              cust,
              firm: firmId,
              firmName:
                (firmResource && firmResource.attributes.firmName) || '',
              controlOwnerInfo,
              isReviewer: roles.includes(ADMIN_ROLE_TYPES.REVIEW),
              isPolicyAdmin: roles.includes(ADMIN_ROLE_TYPES.POLICY_ADMIN),
              isControlOwner:
                userResource.attributes.jurisdiction.isControlOwner,
              compCheck: compCheck === 'Y',
              isBillable: isBillable === 'Y',
              dataHolds,
            };
            debug(userInfo);

            return <UserProfileMemberDetails userInfo={userInfo} />;
          }
          if (userStatus.pending || monitoringStatus.pending) {
            return <UserProfileMemberDetailsLoading />;
          }
          if (userStatus.failed || monitoringStatus.failed) {
            return <UserProfileMemberDetailsError />;
          }
          return <div />;
        },
      },
    };
  };

  tabsInfo = (props = this.props) => {
    const { monitoringResource, monitoringStatus, location, baseUrl } = props;

    const dataRetrievers = this.dataRetrievers();

    return [
      {
        name: USER_PROFILE_TABS.MONITORED_BY,
        link: {
          ...location,
          pathname: `${baseUrl}/${USER_PROFILE_TABS.MONITORED_BY}`,
        },
        datumHero: dataRetrievers[USER_PROFILE_TABS.MONITORED_BY].summaryHero(),
      },
      ...(monitoringStatus.pending ||
      (monitoringResource && monitoringResource.data)
        ? [
            {
              // Only display tab if we have data or are loading it
              name: USER_PROFILE_TABS.MONITORING,
              link: {
                ...location,
                pathname: `${baseUrl}/${USER_PROFILE_TABS.MONITORING}`,
              },
              datumHero: dataRetrievers[
                USER_PROFILE_TABS.MONITORING
              ].summaryHero(),
            },
          ]
        : []),
      {
        name: USER_PROFILE_TABS.MEMBER_DETAILS,
        link: {
          ...location,
          pathname: `${baseUrl}/${USER_PROFILE_TABS.MEMBER_DETAILS}`,
        },
        datumHero: dataRetrievers[
          USER_PROFILE_TABS.MEMBER_DETAILS
        ].summaryHero(),
      },
    ];
  };

  onBackButtonClick = (event) => {
    event.preventDefault();

    const { firmId, firmTab, subFirmTab, history, fromUrl } = this.props;

    const fromUrlDecoded = safeAtob(fromUrl);

    if (fromUrlDecoded) {
      history.push(fromUrlDecoded);
    } else {
      history.push(`/firm/${firmId}/${firmTab}/${subFirmTab}`);
    }
  };

  getMonitoringAppLink = ({ row, fromUrl }) => {
    const { firmId, firmTab, subFirmTab, urlUserInfo } = this.props;

    return userProfileMonitoringAppLink({
      firmId,
      firmTab,
      subFirmTab,
      app: row.app,
      userIdType: urlUserInfo.type,
      userId: urlUserInfo.id,
      fromUrl,
    });
  };

  onMonitoringRowClick = (row) => {
    const { history, location } = this.props;

    history.push(
      this.getMonitoringAppLink({ row, fromUrl: btoaFromUrl(location) })
    );
  };

  render() {
    const {
      baseUrl,
      urlUserInfo,
      userProfileTab,
      isUserInfoLoading,
      isUserInfoError,
      location,
    } = this.props;

    if (!urlUserInfo) {
      return <Redirect to="/" />;
    }

    if (!userProfileTab) {
      return (
        <Redirect to={{ ...location, pathname: `${baseUrl}/${DEFAULT_TAB}` }} />
      );
    }

    const dataRetriever = this.dataRetrievers()[userProfileTab];

    const currentKey = location ? location.key || '/' : '/';
    const timeout = { enter: 1000, exit: 1000 };

    return (
      <UserProfile
        userInfo={this.headerDataRetriever().userInfo}
        isUserInfoLoading={isUserInfoLoading}
        isUserInfoError={isUserInfoError}
        tab={userProfileTab}
        onMonitoringRowClick={this.onMonitoringRowClick}
        tabsInfo={this.tabsInfo()}
        onBackButtonClick={this.onBackButtonClick}
      >
        {dataRetriever.renderer()}
        <TransitionGroup component="main" className="page-main">
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames="slide"
            appear
          >
            <Switch location={location}>
              <Route
                path="/firm/:firmId/:firmTab(roster)/:subFirmTab/userProfile/:userIdType(u|c)/:userId(\d+)/monitoring/:app"
                component={AdminMonitoringAppContainer}
              />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </UserProfile>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileContainer);
/* eslint-enable */
