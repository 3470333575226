/* eslint-disable */
import { USER_PROFILE_TABS } from '../containers/UserProfileContainer';
import { queryStringStringify } from './QueryStringParser';

export const firmLink = ({
  firmId,
  firmTab = 'roster',
  subFirmTab = 'monitoredEmployees',
  fromUrl,
}) => {
  let result = `/firm/${firmId}/${firmTab}/${subFirmTab}`;
  if (fromUrl) {
    result += `?from=${fromUrl}`;
  }
  return result;
};

export const userProfileLink = ({
  firmId,
  firmTab = 'roster',
  subFirmTab = 'monitoredEmployees',
  userIdType,
  userId,
  fromUrl,
}) => {
  let result = `/firm/${firmId}/${firmTab}/${subFirmTab}/userProfile/${userIdType}/${userId}`;
  if (fromUrl) {
    result += `?from=${fromUrl}`;
  }
  return result;
};

export const userProfileMonitoringAppLink = ({
  firmId,
  firmTab,
  subFirmTab,
  app,
  userIdType,
  userId,
  fromUrl,
  page,
}) => {
  let result = `${userProfileLink({
    firmId,
    firmTab,
    subFirmTab,
    userIdType,
    userId,
  })}/${USER_PROFILE_TABS.MONITORING}/${app}`;
  if (fromUrl || page) {
    result += queryStringStringify({ from: fromUrl, page });
  }
  return result;
};

export const btoaFromUrl = ({ pathname, search }) =>
  btoa(`${pathname}${search}`);

export const safeAtob = (a) => {
  let result;
  try {
    result = atob(a);
  } catch (e) {}
  return result;
};

export const helpDocs = [
  {
    docName: 'English',
    docUrl: '/RSTR-EN.pdf',
  },
  {
    docName: '日本語',
    docUrl: '/RSTR-JP.pdf',
  },
  {
    docName: 'Français',
    docUrl: '/RSTR-FR.pdf',
  },
  {
    docName: 'Deutsch',
    docUrl: '/RSTR-DE.pdf',
  },
  {
    docName: 'Español',
    docUrl: '/RSTR-ES.pdf',
  },
  {
    docName: 'Português',
    docUrl: '/RSTR-PT.pdf',
  },
  {
    docName: 'Italiano',
    docUrl: '/RSTR-IT.pdf',
  },
  {
    docName: '繁體中文',
    docUrl: '/RSTR-ZT.pdf',
  },
  {
    docName: '한국어',
    docUrl: '/RSTR-KO.pdf',
  },
  {
    docName: '简体中文',
    docUrl: '/RSTR-ZH.pdf',
  },
];
/* eslint-enable */
