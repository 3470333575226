/* eslint-disable */
import React from 'react';

import PropTypes from 'prop-types';

import { capitalize } from 'lodash';

import './styles/Roster.css';
import debugMod from 'debug';
import DatumHeroNav from './DatumHeroNav';
import { addEventTracker } from '../init/analytics';

import {
  SUPPORTED_APPS,
  APP_FULL_NAME,
  APP_SHORT_NAME,
  ROSTER_TABS,
  ADMIN_STATUS_RADIO_GROUP,
} from '../utils/Enums';

// Facet
import RosterFacetPanel from './RosterFacetPanel';
import { SearchInput } from './Search';
import RadioButtonGroup from './filters/RadioButtonGroup';
import DropdownBox from './DropdownBox';
import AdminAppStatusIcon, {
  ADMIN_APP_STATUS_TYPES,
} from './AdminAppStatusIcon';
import MonitoredEmployeesAppStatusIcon, {
  MONITORED_EMPLOYEES_APP_STATUS_TYPES,
} from './MonitoredEmployeesAppStatusIcon';
import FontIcon from './FontIcon';
import { CheckboxGroup } from './filters/Checkbox';

// Grid components
import Grid from './grid/Grid';
import UserInfoCellTemplate, {
  UserInfoCellTemplateLoading,
} from './grid/UserInfoCellTemplate';
import AttributeListCellTemplate, {
  AttributeListCellTemplateLoading,
} from './grid/AttributeListCellTemplate';
import AppStatusCellTemplate, {
  AppStatusCellTemplateLoading,
} from './grid/AppStatusCellTemplate';

// Generate Report Modal
import GenerateReportModal from './GenerateReportModal';
import { FormattedMessage } from 'react-intl';
import { intl } from '../intl/IntlFormat';
import { formatPaginationCount } from '../utils/FormatUtils';

// eslint-disable-next-line
const debug = debugMod('roster:Roster');

const ME_GRID_SUPPORTED_APPS = [
  { columnTitle: APP_SHORT_NAME[SUPPORTED_APPS.MFCA], resourceName: 'mfca' },
  {
    columnTitle: APP_SHORT_NAME[SUPPORTED_APPS.SEARCH_AND_EXPORT],
    resourceName: 'searchAndExport',
  },
  {
    columnTitle: APP_SHORT_NAME[SUPPORTED_APPS.SURVEILLANCE],
    resourceName: 'surveillance',
  },
  { columnTitle: APP_SHORT_NAME[SUPPORTED_APPS.PCHL], resourceName: 'pchl' },
];
const ADMIN_GRID_SUPPORTED_APPS = [
  { columnTitle: APP_SHORT_NAME[SUPPORTED_APPS.MFCA], resourceName: 'mfca' },
  {
    columnTitle: APP_SHORT_NAME[SUPPORTED_APPS.SEARCH_AND_EXPORT],
    resourceName: 'searchAndExport',
  },
  {
    columnTitle: APP_SHORT_NAME[SUPPORTED_APPS.SURVEILLANCE],
    resourceName: 'surveillance',
  },
  { columnTitle: APP_SHORT_NAME[SUPPORTED_APPS.PCHL], resourceName: 'pchl' },
  { columnTitle: APP_SHORT_NAME[SUPPORTED_APPS.TRADE], resourceName: 'trade' },
];

const RosterMonitoredEmployeesLegend = () => (
  <div className="rosterLegend">
    <div className="legendTitle">
      <FormattedMessage id="firm.monitoring_status" />
    </div>
    <span className="legendItem">
      <MonitoredEmployeesAppStatusIcon
        status={MONITORED_EMPLOYEES_APP_STATUS_TYPES.MONITORED}
        displayLabel={true}
      />
    </span>
    <span className="legendItem">
      <MonitoredEmployeesAppStatusIcon
        status={MONITORED_EMPLOYEES_APP_STATUS_TYPES.NOT_MONITORED}
        displayLabel={true}
      />
    </span>
    <span className="legendItem">
      <MonitoredEmployeesAppStatusIcon
        status={MONITORED_EMPLOYEES_APP_STATUS_TYPES.NOT_APPLICABLE}
        displayLabel={true}
      />
    </span>
  </div>
);

const RosterAdminsLegend = () => (
  <div className="rosterLegend">
    <div className="legendTitle">
      <FormattedMessage id="firm.monitoring_status" />
    </div>
    <span className="legendItem">
      <AdminAppStatusIcon
        status={ADMIN_APP_STATUS_TYPES.MONITORING}
        displayLabel={true}
      />
    </span>
    <span className="legendItem">
      <AdminAppStatusIcon
        status={ADMIN_APP_STATUS_TYPES.NOT_MONITORING}
        displayLabel={true}
      />
    </span>
    <span className="legendItem">
      <AdminAppStatusIcon
        status={ADMIN_APP_STATUS_TYPES.NOT_ADMIN}
        displayLabel={true}
      />
    </span>
  </div>
);

const DROPDOWNBOX_APP_STATUS_RENDERER = (IconRenderer) => ({
  label,
  value,
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <IconRenderer status={value} displayLabel={true} />
  </div>
);

const DropdownBoxRenderer = (dropdownItems) => ({
  filterState,
  onFilterChange,
}) => (
  <DropdownBox
    items={dropdownItems}
    selectedIndex={dropdownItems.findIndex((element) => {
      return element.value === filterState;
    })}
    onChange={(selectedItem, selectedIndex) => {
      onFilterChange(selectedItem.value);
    }}
  />
);

const DROPDOWNBOX_ADMIN_APP_STATUS_RENDERER = DROPDOWNBOX_APP_STATUS_RENDERER(
  AdminAppStatusIcon
);
const AdminAppStatusCellTemplate = AppStatusCellTemplate(
  AdminAppStatusIcon,
  ADMIN_APP_STATUS_TYPES
);

const DROPDOWNBOX_ME_APP_STATUS_RENDERER = DROPDOWNBOX_APP_STATUS_RENDERER(
  MonitoredEmployeesAppStatusIcon
);
const MonitoredEmployeesAppStatusCellTemplate = AppStatusCellTemplate(
  MonitoredEmployeesAppStatusIcon,
  MONITORED_EMPLOYEES_APP_STATUS_TYPES
);

const appendSupportedApps = (config, supportedApps, mapper) => {
  return config.concat(supportedApps.map(mapper));
};

const appendDataHolds = (roles, dataHolds) => {
  if (dataHolds) {
    roles.push({ value: intl('firm.data_holds') });
  }
  return roles;
};

const FACET_PANEL_DEFINITIONS = {
  [ROSTER_TABS.ADMINS]: {
    appStatusDropDownItems: [
      {
        label: intl('firm.all_status'),
        value: '',
      },
      {
        label: intl('firm.monitoring_status'),
        value: ADMIN_APP_STATUS_TYPES.MONITORING,
        renderer: DROPDOWNBOX_ADMIN_APP_STATUS_RENDERER,
      },
      {
        label: intl('firm.not_monitoring'),
        value: ADMIN_APP_STATUS_TYPES.NOT_MONITORING,
        renderer: DROPDOWNBOX_ADMIN_APP_STATUS_RENDERER,
      },
      {
        label: intl('firm.not_admin'),
        value: ADMIN_APP_STATUS_TYPES.NOT_ADMIN,
        renderer: DROPDOWNBOX_ADMIN_APP_STATUS_RENDERER,
      },
    ],

    adminStatusRadioGroup: ADMIN_STATUS_RADIO_GROUP,
    adminRolesCheckboxGroup: [
      {
        label: intl('control_owner.control_owner'),
        value: 'Control Owner',
      },
      {
        label: intl('firm.admin_reviewer'),
        value: 'Admin/Reviewer',
      },
      {
        label: intl('firm.firm_wide_master_admin'),
        value: 'Firm-Wide/Master Admin',
      },
      {
        label: intl('firm.manage_admin'),
        value: 'Manage Admin',
      },
      {
        label: intl('firm.policy_admin'),
        value: 'Policy Admin',
      },
      {
        label: intl('firm.owner'),
        value: 'Owner',
      },
    ],
  },

  [ROSTER_TABS.MONITORED_EMPLOYEES]: {
    appStatusDropDownItems: [
      {
        label: intl('firm.all_status'),
        value: '',
      },
      {
        label: intl('firm.monitored'),
        value: MONITORED_EMPLOYEES_APP_STATUS_TYPES.MONITORED,
        renderer: DROPDOWNBOX_ME_APP_STATUS_RENDERER,
      },
      {
        label: intl('firm.not_monitored'),
        value: MONITORED_EMPLOYEES_APP_STATUS_TYPES.NOT_MONITORED,
        renderer: DROPDOWNBOX_ME_APP_STATUS_RENDERER,
      },
      {
        label: intl('firm.not_applicable'),
        value: MONITORED_EMPLOYEES_APP_STATUS_TYPES.NOT_APPLICABLE,
        renderer: DROPDOWNBOX_ME_APP_STATUS_RENDERER,
      },
    ],

    meStatusRadioGroup: [
      {
        label: intl('firm.status_all'),
        value: '',
      },
      {
        label: intl('firm.status_active'),
        value: 'Active',
      },
      {
        label: intl('firm.status_inactive'),
        value: 'Inactive',
      },
    ],

    meRolesCheckboxGroup: [
      {
        label: intl('firm.employee'),
        value: 'Employee',
      },
      {
        label: intl('firm.admin_reviewer'),
        value: 'Admin/Reviewer',
      },
      {
        label: intl('control_owner.control_owner'),
        value: 'Control Owner',
      },
    ],

    meDataHoldsCheckboxGroup: [
      {
        label: intl('firm.show_data_hold_only'),
        value: 'Data Holds',
      },
    ],
  },
};

const ROSTER_CONFIG = {
  /// /////////////
  // Admins tab //
  /// /////////////
  [ROSTER_TABS.ADMINS]: {
    allowGenerateReport: true,
    columnSpec: appendSupportedApps(
      [
        {
          title: intl('firm.admin_reviewer'),
          className: 'align-left admins-column',
        },
        { title: intl('firm.roles'), className: 'align-left roles-column' },
        { title: intl('firm.status'), className: 'align-left status-column' },
        {
          title: intl('firm.aggregated_entitlements'),
          className: 'align-right monitoring-column',
        },
      ],
      ADMIN_GRID_SUPPORTED_APPS,
      (app) => ({
        title: app.columnTitle,
        className: 'align-center app-status',
      })
    ),

    rowTemplateCells: appendSupportedApps(
      [
        {
          mapDataToProps: (data) => ({
            fullName: data.fullName,
            uuid: data.uuid,
            cuid: data.cuid,
            isNameUnknown: data.isNameUnknown,
            isUserDeleted: data.isUserDeleted,
            isUserInactive: data.isUserInactive,
          }),
          cellTemplate: UserInfoCellTemplate,
        },
        {
          mapDataToProps: (data) => ({
            showAttributeKeys: false,
            attributes: data.roles.map((role) => ({ value: role })),
            isUserDeleted: data.isUserDeleted,
          }),
          cellTemplate: AttributeListCellTemplate,
          className: 'textAttributes',
        },
        {
          mapDataToProps: (data) => ({
            showAttributeKeys: false,
            attributes: data.statuses.map((status) => ({
              value: capitalize(status),
            })),
            isUserDeleted: data.isUserDeleted,
          }),
          cellTemplate: AttributeListCellTemplate,
          className: 'textAttributes',
        },
        {
          mapDataToProps: (data) => ({
            numericAttributes: true,
            showAttributeKeys: true,
            attributes: data.monitoringCounts,
          }),
          cellTemplate: AttributeListCellTemplate,
          className: 'numericAttributes',
        },
      ],
      ADMIN_GRID_SUPPORTED_APPS,
      (app) => ({
        mapDataToProps: (data) => {
          return { status: data[app.resourceName] };
        },
        cellTemplate: AdminAppStatusCellTemplate,
      })
    ),

    loadingRowTemplate: appendSupportedApps(
      [
        {
          cellTemplate: UserInfoCellTemplateLoading,
        },
        {
          cellTemplate: AttributeListCellTemplateLoading,
          className: 'textAttributes',
        },
        {
          cellTemplate: AttributeListCellTemplateLoading,
          className: 'textAttributes',
        },
        {
          cellTemplate: AttributeListCellTemplateLoading,
          className: 'numericAttributes',
        },
      ],
      ADMIN_GRID_SUPPORTED_APPS,
      (app) => ({ cellTemplate: AppStatusCellTemplateLoading })
    ),

    facetPanelSpec: {
      sections: [
        {
          filters: [
            {
              label: intl('search.search'),
              filterKey: 'nameOrId',
              defaultValue: '',
              spec: {
                renderer: ({ filterState, onFilterChange }) => {
                  return (
                    <FormattedMessage id="firm.enter_admin_name_id">
                      {(message) => (
                        <SearchInput
                          placeholder={message}
                          value={filterState}
                          onChange={onFilterChange}
                        />
                      )}
                    </FormattedMessage>
                  );
                },
              },
            },
          ],
        },
        {
          label: intl('firm.coverage'),
          filters: [
            {
              label: APP_FULL_NAME[SUPPORTED_APPS.MFCA],
              filterKey: 'mfca',
              defaultValue: '',
              spec: {
                renderer: DropdownBoxRenderer(
                  FACET_PANEL_DEFINITIONS[ROSTER_TABS.ADMINS]
                    .appStatusDropDownItems
                ),
              },
            },
            {
              label: APP_FULL_NAME[SUPPORTED_APPS.SEARCH_AND_EXPORT],
              filterKey: 'searchAndExport',
              defaultValue: '',
              spec: {
                renderer: DropdownBoxRenderer(
                  FACET_PANEL_DEFINITIONS[ROSTER_TABS.ADMINS]
                    .appStatusDropDownItems
                ),
              },
            },
            {
              label: APP_FULL_NAME[SUPPORTED_APPS.SURVEILLANCE],
              filterKey: 'surveillance',
              defaultValue: '',
              spec: {
                renderer: DropdownBoxRenderer(
                  FACET_PANEL_DEFINITIONS[ROSTER_TABS.ADMINS]
                    .appStatusDropDownItems
                ),
              },
            },
            {
              label: APP_FULL_NAME[SUPPORTED_APPS.PCHL],
              filterKey: 'pchl',
              defaultValue: '',
              spec: {
                renderer: DropdownBoxRenderer(
                  FACET_PANEL_DEFINITIONS[ROSTER_TABS.ADMINS]
                    .appStatusDropDownItems
                ),
              },
            },
            {
              label: APP_FULL_NAME[SUPPORTED_APPS.TRADE],
              filterKey: 'trade',
              defaultValue: '',
              spec: {
                renderer: DropdownBoxRenderer(
                  FACET_PANEL_DEFINITIONS[ROSTER_TABS.ADMINS]
                    .appStatusDropDownItems
                ),
              },
            },
          ],
        },
        {
          filters: [
            {
              label: intl('firm.status'),
              filterKey: 'status',
              defaultValue: '',
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <RadioButtonGroup
                    items={
                      FACET_PANEL_DEFINITIONS[ROSTER_TABS.ADMINS]
                        .adminStatusRadioGroup
                    }
                    currentValue={filterState}
                    onValueChange={onFilterChange}
                  />
                ),
              },
            },
          ],
        },
        {
          filters: [
            {
              label: intl('firm.roles'),
              filterKey: 'roles',
              defaultValue: [],
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <CheckboxGroup
                    name="roles"
                    items={
                      FACET_PANEL_DEFINITIONS[ROSTER_TABS.ADMINS]
                        .adminRolesCheckboxGroup
                    }
                    currentValue={filterState}
                    onValueChange={onFilterChange}
                  />
                ),
              },
            },
          ],
        },
      ],
    },

    legend: RosterAdminsLegend,
  },

  /// //////////////////////////
  // Monitored Employees Tab //
  /// //////////////////////////
  [ROSTER_TABS.MONITORED_EMPLOYEES]: {
    allowGenerateReport: true,
    columnSpec: appendSupportedApps(
      [
        {
          title: intl('firm.monitored_employee'),
          className: 'align-left monitored-column',
        },
        { title: intl('firm.roles'), className: 'align-left roles-column' },
        { title: intl('firm.status'), className: 'align-left status-column' },
      ],
      ME_GRID_SUPPORTED_APPS,
      (app) => ({
        title: app.columnTitle,
        className: 'align-center app-status',
      })
    ),

    rowTemplateCells: appendSupportedApps(
      [
        {
          mapDataToProps: (data) => ({
            fullName: data.fullName,
            uuid: data.uuid,
            cuid: data.cuid,
            isNameUnknown: data.isNameUnknown,
            isUserDeleted: data.isUserDeleted,
            isUserInactive: data.isUserInactive,
          }),
          cellTemplate: UserInfoCellTemplate,
        },
        {
          mapDataToProps: (data) => ({
            showAttributeKeys: false,
            attributes: appendDataHolds(
              data.roles.map((role) => ({ value: role })),
              data.dataHolds
            ),
            isUserDeleted: data.isUserDeleted,
          }),
          cellTemplate: AttributeListCellTemplate,
          className: 'textAttributes',
        },
        {
          mapDataToProps: (data) => ({
            showAttributeKeys: false,
            attributes: data.statuses.map((status) => ({
              value: capitalize(status),
            })),
            isUserDeleted: data.isUserDeleted,
          }),
          cellTemplate: AttributeListCellTemplate,
          className: 'textAttributes',
        },
      ],
      ME_GRID_SUPPORTED_APPS,
      (app) => ({
        mapDataToProps: (data) => ({ status: data[app.resourceName] }),
        cellTemplate: MonitoredEmployeesAppStatusCellTemplate,
      })
    ),

    loadingRowTemplate: appendSupportedApps(
      [
        {
          cellTemplate: UserInfoCellTemplateLoading,
        },
        {
          cellTemplate: AttributeListCellTemplateLoading,
          className: 'textAttributes',
        },
        {
          cellTemplate: AttributeListCellTemplateLoading,
          className: 'textAttributes',
        },
      ],
      ME_GRID_SUPPORTED_APPS,
      (app) => ({ cellTemplate: AppStatusCellTemplateLoading })
    ),

    facetPanelSpec: {
      sections: [
        {
          filters: [
            {
              label: intl('search.search'),
              filterKey: 'nameOrId',
              defaultValue: '',
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <FormattedMessage id="firm.enter_employee_name_id">
                    {(message) => (
                      <SearchInput
                        placeholder={message}
                        value={filterState}
                        onChange={addEventTracker(onFilterChange, {
                          category: 'facetPanel',
                          action: 'employeeSearch',
                          label: 'nameID',
                        })}
                      />
                    )}
                  </FormattedMessage>
                ),
              },
            },
          ],
        },
        {
          label: intl('firm.coverage'),
          filters: [
            {
              label: APP_FULL_NAME[SUPPORTED_APPS.MFCA],
              filterKey: 'mfca',
              defaultValue: '',
              spec: {
                renderer: DropdownBoxRenderer(
                  FACET_PANEL_DEFINITIONS[ROSTER_TABS.MONITORED_EMPLOYEES]
                    .appStatusDropDownItems
                ),
              },
            },
            {
              label: APP_FULL_NAME[SUPPORTED_APPS.SEARCH_AND_EXPORT],
              filterKey: 'searchAndExport',
              defaultValue: '',
              spec: {
                renderer: DropdownBoxRenderer(
                  FACET_PANEL_DEFINITIONS[ROSTER_TABS.MONITORED_EMPLOYEES]
                    .appStatusDropDownItems
                ),
              },
            },
            {
              label: APP_FULL_NAME[SUPPORTED_APPS.SURVEILLANCE],
              filterKey: 'surveillance',
              defaultValue: '',
              spec: {
                renderer: DropdownBoxRenderer(
                  FACET_PANEL_DEFINITIONS[ROSTER_TABS.MONITORED_EMPLOYEES]
                    .appStatusDropDownItems
                ),
              },
            },
            {
              label: APP_FULL_NAME[SUPPORTED_APPS.PCHL],
              filterKey: 'pchl',
              defaultValue: '',
              spec: {
                renderer: DropdownBoxRenderer(
                  FACET_PANEL_DEFINITIONS[ROSTER_TABS.MONITORED_EMPLOYEES]
                    .appStatusDropDownItems
                ),
              },
            },
          ],
        },
        {
          filters: [
            {
              label: intl('firm.status'),
              filterKey: 'status',
              defaultValue: '',
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <RadioButtonGroup
                    items={
                      FACET_PANEL_DEFINITIONS[ROSTER_TABS.MONITORED_EMPLOYEES]
                        .meStatusRadioGroup
                    }
                    currentValue={filterState}
                    onValueChange={addEventTracker(onFilterChange, {
                      category: 'facetPanel',
                      action: 'filterChange',
                      label: 'status',
                    })}
                  />
                ),
              },
            },
          ],
        },
        {
          filters: [
            {
              label: intl('firm.roles'),
              filterKey: 'roles',
              defaultValue: '',
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <CheckboxGroup
                    name="roles"
                    items={
                      FACET_PANEL_DEFINITIONS[ROSTER_TABS.MONITORED_EMPLOYEES]
                        .meRolesCheckboxGroup
                    }
                    currentValue={filterState}
                    onValueChange={addEventTracker(onFilterChange, {
                      category: 'facetPanel',
                      action: 'checkboxChange',
                      label: 'roles',
                    })}
                  />
                ),
              },
            },
          ],
        },
        {
          filters: [
            {
              label: intl('firm.data_holds'),
              filterKey: 'dataHolds',
              defaultValue: '',
              spec: {
                renderer: ({ filterState, onFilterChange }) => (
                  <CheckboxGroup
                    name="dataHolds"
                    items={
                      FACET_PANEL_DEFINITIONS[ROSTER_TABS.MONITORED_EMPLOYEES]
                        .meDataHoldsCheckboxGroup
                    }
                    currentValue={filterState}
                    onValueChange={addEventTracker(onFilterChange, {
                      category: 'facetPanel',
                      action: 'checkboxChange',
                      label: 'dataHolds',
                    })}
                  />
                ),
              },
            },
          ],
        },
      ],
    },

    legend: RosterMonitoredEmployeesLegend,
  },

  /// ///////////////////
  // Jurisdiction Tab //
  /// ///////////////////
  [ROSTER_TABS.JURISDICTION]: {
    allowGenerateReport: true,
    columnSpec: [
      {
        title: (
          <span>
            <FormattedMessage id="firm.cust_acct_name_in_jurisdiction" />
          </span>
        ),
        className: 'align-left cust-acct-name-column',
      },
      {
        title: intl('firm.cust_acct_id'),
        className: 'align-left cust-acct-id-column',
      },
      {
        title: intl('control_owner.region'),
        className: 'align-left region-column',
      },
      {
        title: intl('control_owner.country'),
        className: 'align-left country-column',
      },
      {
        title: intl('firm.associated_caid'),
        className: 'align-left caid-column',
      },
      {
        title: intl('firm.caid_in_jurisdiction'),
        className: 'align-left caid-in-jurisdiction-column',
      },
    ],

    rowTemplateCells: [
      {
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [{ value: data.custName, canOverflow: true }],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [{ value: data.id }],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [{ value: data.region }],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [{ value: data.country }],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [{ value: data.caid }],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
      {
        mapDataToProps: (data) => ({
          showAttributeKeys: false,
          attributes: [
            {
              value: data.caidInJurisdiction
                ? intl('user.yes')
                : intl('user.no'),
            },
          ],
        }),
        cellTemplate: AttributeListCellTemplate,
        className: 'textAttributes',
      },
    ],

    loadingRowTemplate: [
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'textAttributes',
      },
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'textAttributes',
      },
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'textAttributes',
      },
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'textAttributes',
      },
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'textAttributes',
      },
      {
        cellTemplate: AttributeListCellTemplateLoading,
        className: 'textAttributes',
      },
    ],

    legend: () => <div />,
  },
};

class Roster extends React.Component {
  constructor() {
    super();
    this.state = {
      filterPanelExpanded: true,
    };
  }

  render() {
    const {
      firmId,
      tab,
      gridContent,
      tabSummaries,
      isLoadingPrevious,
      isLoadingNext,
      rowCountInfo,
      onLoadPrevious,
      onLoadNext,
      onRowClick,
      onCommitFilter,
      appliedFilters,
      pathname,
      queryString = '',
      queryParams = {},
      navClassName = '',
      generateReportModalInfo,
      onCancelGenerateReportModal = () => {},
      onGenerateReport = () => {},
    } = this.props;

    const { filterPanelExpanded } = this.state;

    const tabData = [
      {
        name: ROSTER_TABS.MONITORED_EMPLOYEES,
        link: `/firm/${firmId}/roster/${ROSTER_TABS.MONITORED_EMPLOYEES}${queryString}`,
        datumHero: tabSummaries[ROSTER_TABS.MONITORED_EMPLOYEES],
      },
      {
        name: ROSTER_TABS.ADMINS,
        link: `/firm/${firmId}/roster/${ROSTER_TABS.ADMINS}${queryString}`,
        datumHero: tabSummaries[ROSTER_TABS.ADMINS],
      },
      {
        name: ROSTER_TABS.JURISDICTION,
        link: `/firm/${firmId}/roster/${ROSTER_TABS.JURISDICTION}${queryString}`,
        datumHero: tabSummaries[ROSTER_TABS.JURISDICTION],
      },
    ];

    const RosterConfig = ROSTER_CONFIG[tab];

    const RosterRowTemplates = {
      rosterAdmin: {
        cells: ROSTER_CONFIG[ROSTER_TABS.ADMINS].rowTemplateCells,
        onRowClick,
      },
      rosterAdminLoading: {
        cells: ROSTER_CONFIG[ROSTER_TABS.ADMINS].loadingRowTemplate,
      },
      monitoredEmployees: {
        cells: ROSTER_CONFIG[ROSTER_TABS.MONITORED_EMPLOYEES].rowTemplateCells,
        onRowClick,
      },
      monitoredEmployeesLoading: {
        cells:
          ROSTER_CONFIG[ROSTER_TABS.MONITORED_EMPLOYEES].loadingRowTemplate,
      },
      jurisdiction: {
        cells: ROSTER_CONFIG[ROSTER_TABS.JURISDICTION].rowTemplateCells,
      },
      jurisdictionLoading: {
        cells: ROSTER_CONFIG[ROSTER_TABS.JURISDICTION].loadingRowTemplate,
      },
    };

    return (
      <div className="roster-wrapper">
        {generateReportModalInfo && (
          <GenerateReportModal
            firmName={generateReportModalInfo.firmName}
            reportTitle={generateReportModalInfo.reportTitle}
            scope={generateReportModalInfo.scope}
            bbgEmail={generateReportModalInfo.bbgEmail}
            corpEmail={generateReportModalInfo.corpEmail}
            isImpersonating={generateReportModalInfo.isImpersonating}
            onCancel={onCancelGenerateReportModal}
            onGenerate={onGenerateReport}
          />
        )}
        <div className="row nav-row">
          <DatumHeroNav
            tabs={tabData}
            selectedIndex={tabData.findIndex((el) => el.name === tab)}
            className={navClassName}
          />
        </div>
        <div className="roster">
          <div className="row content-row">
            <div className="facetControl">
              {RosterConfig.facetPanelSpec && (
                <span
                  className="facetExpander"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      filterPanelExpanded: !filterPanelExpanded,
                    });
                  }}
                >
                  <FontIcon
                    icon={
                      filterPanelExpanded
                        ? 'arrow-double-left'
                        : 'arrow-double-right'
                    }
                  />
                  <span className="facetExpanderLabel">
                    <FormattedMessage id="utility.filter" />
                  </span>
                </span>
              )}
              <span className="filler" />
              <RosterConfig.legend />
              {rowCountInfo && !(isLoadingPrevious || isLoadingNext) && (
                <span className="pageCount">
                  {formatPaginationCount(
                    rowCountInfo.firstRowNum,
                    rowCountInfo.lastRowNum,
                    rowCountInfo.totalCount
                  )}
                </span>
              )}
            </div>

            <div className="gridArea">
              {RosterConfig.facetPanelSpec && (
                <div className={filterPanelExpanded ? '' : 'hidden'}>
                  <RosterFacetPanel
                    spec={RosterConfig.facetPanelSpec}
                    appliedFilters={appliedFilters}
                    pathname={pathname}
                    tab={tab}
                    onCommitFilter={onCommitFilter}
                    queryString={queryString}
                    queryParams={queryParams}
                    // The key is necessary here to trigger a re-construct on appliedFilters
                    // change. If we don't add this here it will cause trouble when switching
                    // subtabs with subtab specific filter types set.
                    // This is one of the officially recommended ways. Please see
                    // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
                    key={JSON.stringify(appliedFilters)}
                  />
                </div>
              )}
              <Grid
                className={`tab_${tab}${
                  filterPanelExpanded ? ' filters-expanded' : ' filters-hidden'
                }`}
                columns={RosterConfig.columnSpec}
                rowtemplates={RosterRowTemplates}
                gridContent={gridContent}
                pagingControl={
                  rowCountInfo
                    ? {
                        nextLink: rowCountInfo.nextLink,
                        previousLink: rowCountInfo.previousLink,
                        isLoadingPrevious,
                        isLoadingNext,
                        onLoadPrevious: rowCountInfo.previousLink
                          ? onLoadPrevious
                          : undefined,
                        onLoadNext: rowCountInfo.nextLink
                          ? onLoadNext
                          : undefined,
                      }
                    : {}
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const TabSummariesShape = PropTypes.shape({
  [ROSTER_TABS.ADMINS]: PropTypes.element.isRequired,
  [ROSTER_TABS.MONITORED_EMPLOYEES]: PropTypes.element.isRequired,
  [ROSTER_TABS.JURISDICTION]: PropTypes.element.isRequired,
});
const RowCountInfoShape = PropTypes.shape({
  totalCount: PropTypes.number.isRequired,
  firstRowNum: PropTypes.number.isRequired,
  lastRowNum: PropTypes.number.isRequired,
  nextLink: PropTypes.string,
  previousLink: PropTypes.string,
});
Roster.propTypes = {
  firmId: PropTypes.number.isRequired,
  tab: PropTypes.oneOf([
    ROSTER_TABS.ADMINS,
    ROSTER_TABS.MONITORED_EMPLOYEES,
    ROSTER_TABS.JURISDICTION,
  ]).isRequired,
  gridContent: PropTypes.func.isRequired,
  tabSummaries: TabSummariesShape.isRequired,
  rowCountInfo: RowCountInfoShape,
  isLoadingPrevious: PropTypes.bool.isRequired,
  isLoadingNext: PropTypes.bool.isRequired,
  onLoadPrevious: PropTypes.func,
  onLoadNext: PropTypes.func,
  onCommitFilter: PropTypes.func,
  appliedFilters: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  queryString: PropTypes.string,
  queryParams: PropTypes.object,
  navClassName: PropTypes.string,
};

export default Roster;

export { RosterMonitoredEmployeesLegend, RosterAdminsLegend };
/* eslint-enable */
