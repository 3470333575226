/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { getStatus, getResources } from 'redux-resource';
import { get, merge, chunk } from 'lodash';
import debugMod from 'debug';

import Roster from '../components/Roster';
import {
  readAdminRosterRequestKey,
  readAdminRoster,
  readMonitoredEmployeesRosterRequestKey,
  readMonitoredEmployeesRoster,
  readCurrentUser,
  readCusts,
  createReport,
  readJurisdiction,
} from '../store/apiActions';
import { ALERT_TYPES, createAlert } from '../store/alertManager';

import { formatIfNumeral } from '../utils/FormatUtils';
import {
  queryStringParse,
  queryStringStringify,
} from '../utils/QueryStringParser';

import { ADMIN_APP_STATUS_TYPES } from '../components/AdminAppStatusIcon';
import { MONITORED_EMPLOYEES_APP_STATUS_TYPES } from '../components/MonitoredEmployeesAppStatusIcon';

import {
  MONITORING_COUNT_TYPES,
  MONITORING_COUNT_LABEL_MAP,
  ROSTER_TABS,
  rolesLabels,
  statusIsInactive,
} from '../utils/Enums';
import {
  DatumHero,
  DatumHeroLoading,
  ExtendedDatumValueWithOverflow,
} from '../components/Datum';
import Error from '../components/Error';
import {
  expandCountryCode,
  jurisdictionDescriptionDatum,
  isImpersonating,
} from '../utils/JurisdictionUtils';

import { userProfileLink, btoaFromUrl } from '../utils/LinkUtils';
import {
  currentUserAndStatusSelector,
  currentUserJurisdictionAndStatusSelector,
  matchedFirmSelector,
} from '../store/selectors';
import {
  enableCurrentViewReport,
  disableCurrentViewReport,
  enableSurveillanceReport,
  disableSurveillanceReport,
} from '../store/reportsManager';
import { nameIsUnknown, nameIsDeleted } from '../utils/ProfileUtils';
import { FormattedMessage } from 'react-intl';
import { intl } from '../intl/IntlFormat';

// eslint-disable-next-line
const debug = debugMod('roster:RosterContainer');

const ADMIN_APP_STATUS_TO_GRID_STATUS_MAP = {
  NoConflict: ADMIN_APP_STATUS_TYPES.MONITORING,
  Conflict: ADMIN_APP_STATUS_TYPES.SELF_MONITORING,
};

const ADMIN_SUMMARY_FIELDS = {
  ADMINS: 'Admins',
  DIST_AVG: 'ReviewerToMe',
  INACTIVE: 'Inactive',
  NOT_MONITORING: 'NotMonitoring',
};

const ADMIN_SUMMARY_LABEL_MAP = {
  [ADMIN_SUMMARY_FIELDS.ADMINS]: intl('firm.admins_reviewers'),
  [ADMIN_SUMMARY_FIELDS.DIST_AVG]: intl('firm.reviewer_me'),
  [ADMIN_SUMMARY_FIELDS.INACTIVE]: intl('firm.status_inactive'),
  [ADMIN_SUMMARY_FIELDS.NOT_MONITORING]: intl('firm.not_monitoring'),
};

const ME_SUMMARY_FIELDS = {
  MONITORED_EMPLOYEES: 'MonitoredEmployees',
  NOT_MONITORED: 'NotMonitored',
  ACTIVE: 'Active',
  DATA_HOLDS: 'DataHolds',
};

const ME_SUMMARY_LABEL_MAP = {
  [ME_SUMMARY_FIELDS.MONITORED_EMPLOYEES]: intl('firm.monitored_employees'),
  [ME_SUMMARY_FIELDS.NOT_MONITORED]: intl('firm.not_monitored'),
  [ME_SUMMARY_FIELDS.ACTIVE]: intl('firm.status_active'),
  [ME_SUMMARY_FIELDS.DATA_HOLDS]: intl('firm.data_holds'),
};

const ROSTER_REPORT_TITLES = {
  [ROSTER_TABS.ADMINS]: <FormattedMessage id="report.admin_reviewer_report" />,
  [ROSTER_TABS.MONITORED_EMPLOYEES]: (
    <FormattedMessage id="report.monitored_employees_report" />
  ),
  [ROSTER_TABS.JURISDICTION]: (
    <FormattedMessage id="report.jurisdiction_report" />
  ),
};

const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT || 300;

const JURISDICTION_PAGE_LIMIT = 100;

const TALL_SCOPE_STRING = 'REGION_AND_COUNTRY_AND_ENTITY';

const mapStateToProps = (state, props) => {
  const {
    adminRoster,
    monitoredEmployeesRoster,
    custs,
    JwtAuth: { csrftoken },
  } = state;
  const {
    match: {
      params: { firmId, firmTab, subFirmTab },
    },
    location: { search },
  } = props;

  const currentFirmResource = matchedFirmSelector(state, props);

  // const pathname = location.pathname
  const queryParam = queryStringParse(search);
  const {
    pagelimit = PAGE_LIMIT,
    tabStates: qpTabStates = {},
    cust,
    caid,
  } = queryParam;

  const tabStates = {
    [ROSTER_TABS.MONITORED_EMPLOYEES]: parseTabStates(
      qpTabStates[ROSTER_TABS.MONITORED_EMPLOYEES]
    ),
    [ROSTER_TABS.ADMINS]: parseTabStates(qpTabStates[ROSTER_TABS.ADMINS]),
    [ROSTER_TABS.JURISDICTION]: parseTabStates(
      qpTabStates[ROSTER_TABS.JURISDICTION]
    ),
  };

  const adminRosterRequestKey = readAdminRosterRequestKey(
    getReadRosterInput({
      firmId,
      pagelimit,
      tabState: tabStates[ROSTER_TABS.ADMINS],
      cust,
      caid,
    })
  );
  const adminRosterResource = adminRoster.resources[`${adminRosterRequestKey}`];
  const adminRosterStatus = getStatus(
    state,
    `adminRoster.meta.${adminRosterRequestKey}.readStatus`,
    false
  );

  const monitoredEmployeesRosterRequestKey = readMonitoredEmployeesRosterRequestKey(
    getReadRosterInput({
      firmId,
      pagelimit,
      tabState: tabStates[ROSTER_TABS.MONITORED_EMPLOYEES],
      cust,
      caid,
    })
  );
  const monitoredEmployeesRosterResource =
    monitoredEmployeesRoster.resources[`${monitoredEmployeesRosterRequestKey}`];
  const monitoredEmployeesRosterStatus = getStatus(
    state,
    `monitoredEmployeesRoster.meta.${monitoredEmployeesRosterRequestKey}.readStatus`,
    false
  );

  const [
    currentUserAttrs,
    currentUserResourceStatus,
  ] = currentUserAndStatusSelector(state);
  const [
    jurisdictionAttrs,
    jurisdictionStatus,
  ] = currentUserJurisdictionAndStatusSelector(state);

  return {
    firmId,
    firmTab,
    subFirmTab,
    adminRosterResource,
    adminRosterStatus,
    monitoredEmployeesRosterResource,
    monitoredEmployeesRosterStatus,
    currentUserAttrs,
    currentUserResourceStatus,
    jurisdictionAttrs,
    jurisdictionStatus,
    custsStore: custs,
    queryParam,
    tabStates,
    pagelimit,
    currentFirmResource,
    csrftoken,
  };
};
const mapDispatchToProps = {
  readAdminRoster,
  readMonitoredEmployeesRoster,
  createAlert,
  readCurrentUser,
  readJurisdiction,
  readCusts,
  createReport,
  enableCurrentViewReport,
  disableCurrentViewReport,
  enableSurveillanceReport,
  disableSurveillanceReport,
};

const parseTabStates = (tabState) => {
  const { page = '1', filters = {} } = tabState || {};
  return {
    // If page is not parsible as int, default to 1. If page < 1, default to 1
    page: Math.max(parseInt(page, 10) || 1, 1),
    filters,
  };
};

const mapAdminAppStatusToGridStatus = (appStatus) =>
  (appStatus &&
    (ADMIN_APP_STATUS_TO_GRID_STATUS_MAP[appStatus] || appStatus)) ||
  ADMIN_APP_STATUS_TYPES.NOT_ADMIN;
const mapMonitoredEmployeesAppStatusToGridStatus = (appStatus) =>
  appStatus || MONITORED_EMPLOYEES_APP_STATUS_TYPES.NOT_MONITORED;

const setQueryStringPage = (queryParam, subFirmTab, page) => {
  return queryStringStringify(
    merge({}, queryParam, {
      tabStates: {
        [subFirmTab]: {
          page,
        },
      },
    })
  );
};
const getRowCountInfo = (
  totalCount,
  currentPage,
  pagelimit,
  pathname,
  subFirmTab,
  queryParam
) => {
  const firstRowNum = totalCount > 0 ? (currentPage - 1) * pagelimit + 1 : 0;
  const lastRowNum =
    totalCount > 0 ? Math.min(totalCount, currentPage * pagelimit) : 0;
  const previousLink =
    currentPage > 1
      ? `${pathname}${setQueryStringPage(
          queryParam,
          subFirmTab,
          currentPage - 1
        )}`
      : '';
  const nextLink =
    totalCount - firstRowNum + 1 > pagelimit
      ? `${pathname}${setQueryStringPage(
          queryParam,
          subFirmTab,
          currentPage + 1
        )}`
      : '';

  return {
    firstRowNum,
    lastRowNum,
    nextLink,
    previousLink,
  };
};

export const getReadRosterInput = ({
  firmId,
  pagelimit,
  tabState,
  cust,
  caid,
}) => {
  const { page, filters } = tabState;

  const {
    nameOrId: filterNameOrId,
    searchAndExport: filterSearchAndExport,
    mfca: filterMfca,
    surveillance: filterSurveillance,
    pchl: filterPchl,
    trade: filterTrade,
    status: filterStatus,
    roles: filterRoles,
  } = filters;

  const filterApplicationStatus = [];

  if (filterSearchAndExport) {
    filterApplicationStatus.push(`searchAndExport${filterSearchAndExport}`);
  }
  if (filterMfca) {
    filterApplicationStatus.push(`mfca${filterMfca}`);
  }
  if (filterSurveillance) {
    filterApplicationStatus.push(`surveillance${filterSurveillance}`);
  }
  if (filterPchl) {
    filterApplicationStatus.push(`pchl${filterPchl}`);
  }
  if (filterTrade) {
    filterApplicationStatus.push(`trade${filterTrade}`);
  }

  return {
    firmId,
    offset: (page - 1) * pagelimit,
    limit: pagelimit,
    filterNameOrId,
    filterStatus,
    filterApplicationStatus,
    filterRoles,
    customerIds: cust ? [cust] : [],
    caids: caid ? [caid] : [],
  };
};

const getJurisdictionCusts = ({
  controlOwnerJurisdiction,
  pagelimit,
  tabState,
}) => {
  // Get jurisdiction custs
  const pagedJurisdictionCusts = chunk(
    controlOwnerJurisdiction.custs,
    pagelimit
  );

  // Get current page
  const pageCount = pagedJurisdictionCusts.length;
  const currentPage = Math.min(Math.max(tabState.page, 1), pageCount);
  return {
    totalCount: controlOwnerJurisdiction.custs.length,
    currentPage,
    pageCount,
    jurisdictionCusts: pagedJurisdictionCusts[currentPage - 1] || [],
  };
};

export class RosterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingPrevious: false,
      isLoadingNext: false,
      generateReportModalInfo: null,
    };
  }

  componentDidMount() {
    this.readAdminRoster();
    this.readMonitoredEmployeesRoster();
    this.readJurisdictionCusts();
    this.resetState();

    this.props.enableCurrentViewReport(this.onShowGenerateReportModal);
    this.props.enableSurveillanceReport(this.props.firmId);
  }

  componentWillUnmount() {
    this.props.disableCurrentViewReport();
    this.props.disableSurveillanceReport();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      firmId,
      subFirmTab,
      location: currLocation,
      adminRosterResource,
      monitoredEmployeesRosterResource,
      queryParam: { cust, caid },
    } = this.props;

    const {
      firmId: prevFirmId,
      location: prevLocation,
      queryParam: { cust: prevCust, caid: prevCaid },
    } = prevProps;

    // When firm changed or scope changed, refresh both
    // admin and monitored employees roster
    if (firmId !== prevFirmId || cust !== prevCust || caid !== prevCaid) {
      this.readAdminRoster();
      this.readMonitoredEmployeesRoster();
      this.resetState();
    } else {
      // When in admins tab
      if (subFirmTab === 'admins') {
        // Read from adminRosterResource if
        // 1. adminRosterResource is undefined or
        // 2. Query parameter changed
        if (
          !adminRosterResource ||
          currLocation.search !== prevLocation.search
        ) {
          this.readAdminRoster();
        }
        // When in monitored employees tab
      } else if (ROSTER_TABS.MONITORED_EMPLOYEES === subFirmTab) {
        // Read from monitoredEmployeesRosterResource if
        // 1. monitoredEmployeesRosterResource is undefined or
        // 2. Query parameter changed
        if (
          !monitoredEmployeesRosterResource ||
          currLocation.search !== prevLocation.search
        ) {
          this.readMonitoredEmployeesRoster();
        }
        // When in jurisdiction tab
      } else if (subFirmTab === 'jurisdiction') {
        // Consider sending request if
        // 1. Query parameter changed
        if (currLocation.search !== prevLocation.search) {
          this.readJurisdictionCusts();
        }
      }
    }

    // Handle loading visual
    if (currLocation.pathname !== prevLocation.pathname) {
      this.resetState();
    }
  }

  getUserProfileLink = ({ row, fromUrl }) => {
    const { firmId, firmTab, subFirmTab } = this.props;

    const { uuid, cuid } = row;
    const [userIdType, userId] = uuid ? ['u', uuid] : ['c', cuid];

    return userProfileLink({
      firmId,
      firmTab,
      subFirmTab,
      userIdType,
      userId,
      fromUrl,
    });
  };

  resetState = () => {
    this.setState({ isLoadingPrevious: false, isLoadingNext: false });
  };

  onLoadPrevious = () => {
    this.setState({ isLoadingPrevious: true, isLoadingNext: false });
  };

  onLoadNext = () => {
    this.setState({ isLoadingPrevious: false, isLoadingNext: true });
  };

  onRosterRowClick = (row) => {
    const { history, createAlert, location } = this.props;

    if (!row.uuid && !row.cuid) {
      createAlert(
        ALERT_TYPES.ERROR,
        intl('error.selected_entity_no_uuid_cuid'),
        5000
      );
      return;
    }

    history.push(
      this.getUserProfileLink({ row, fromUrl: btoaFromUrl(location) })
    );
  };

  onCommitFilter = () => {
    this.resetState();
  };

  readAdminRoster = (props = this.props) => {
    const {
      adminRosterStatus,
      readAdminRoster,
      firmId,
      pagelimit,
      tabStates,
      queryParam: { cust, caid },
    } = props;

    // Don't read roster if it's already pending or failed
    if (
      !adminRosterStatus ||
      !(adminRosterStatus.pending || adminRosterStatus.failed)
    ) {
      this.readAdminRosterPromise = readAdminRoster(
        getReadRosterInput({
          firmId,
          pagelimit,
          tabState: tabStates[ROSTER_TABS.ADMINS],
          cust,
          caid,
        })
      );
    }
  };

  readMonitoredEmployeesRoster = (props = this.props) => {
    const {
      monitoredEmployeesRosterStatus,
      readMonitoredEmployeesRoster,
      firmId,
      pagelimit,
      tabStates,
      queryParam: { cust, caid },
    } = props;

    // Don't read roster if it's already pending or failed
    if (
      !monitoredEmployeesRosterStatus ||
      !(
        monitoredEmployeesRosterStatus.pending ||
        monitoredEmployeesRosterStatus.failed
      )
    ) {
      this.readMonitoredEmployeesRosterPromise = readMonitoredEmployeesRoster(
        getReadRosterInput({
          firmId,
          pagelimit,
          tabState: tabStates[ROSTER_TABS.MONITORED_EMPLOYEES],
          cust,
          caid,
        })
      );
    }
  };

  readJurisdictionCusts = (props = this.props) => {
    const {
      currentUserAttrs,
      currentUserResourceStatus,
      jurisdictionAttrs,
      jurisdictionStatus,
      tabStates,
      readCurrentUser,
      custsStore,
      readCusts,
      readJurisdiction,
    } = props;

    if (jurisdictionAttrs && jurisdictionAttrs.controlOwnerJurisdiction) {
      const { jurisdictionCusts } = getJurisdictionCusts({
        controlOwnerJurisdiction: jurisdictionAttrs.controlOwnerJurisdiction,
        pagelimit: JURISDICTION_PAGE_LIMIT,
        tabState: tabStates[ROSTER_TABS.JURISDICTION],
      });
      const custsResources = getResources(custsStore, jurisdictionCusts || []);
      if (!custsResources || custsResources.length < jurisdictionCusts.length) {
        const custsResourcesStatus = getStatus(
          custsStore,
          jurisdictionCusts.map((c) => `meta.${c}.readStatus`),
          false
        );
        if (
          !custsResourcesStatus ||
          !(custsResourcesStatus.pending || custsResourcesStatus.failed)
        ) {
          this.readCustsPromise = readCusts({ custs: jurisdictionCusts });
        }
      }
    } else if (
      currentUserAttrs &&
      !jurisdictionAttrs &&
      (!jurisdictionStatus ||
        !(jurisdictionStatus.pending || jurisdictionStatus.failed))
    ) {
      this.readJurisdictionPromise = readJurisdiction(currentUserAttrs.uuid);
    } else if (
      !currentUserResourceStatus ||
      !(currentUserResourceStatus.pending || currentUserResourceStatus.failed)
    ) {
      this.readCurrentUserPromise = readCurrentUser();
    }
  };

  dataRetriever = (props = this.props, state = this.state) => {
    const {
      subFirmTab,
      adminRosterResource,
      adminRosterStatus,
      monitoredEmployeesRosterResource,
      monitoredEmployeesRosterStatus,
      jurisdictionAttrs,
      jurisdictionStatus,
      custsStore,
      tabStates,
      pagelimit,
      queryParam,
      location: { pathname },
    } = props;

    const loadingRows = (count, templateName, rowGenerator) => {
      return Array.from(Array(count))
        .map(() => {
          return { templateName };
        })
        .map(rowGenerator);
    };

    return {
      /// /////////
      // admins //
      /// /////////
      admins: {
        resourceStatus() {
          return adminRosterStatus;
        },
        gridContent(rowGenerator) {
          if (adminRosterStatus.failed) {
            return (
              <tr>
                <td>
                  <Error />
                </td>
              </tr>
            );
          }
          if (
            adminRosterResource &&
            adminRosterResource.data &&
            !adminRosterStatus.pending
          ) {
            return adminRosterResource.data
              .map(
                ({
                  type,
                  attributes: {
                    name,
                    uuid,
                    cuid,
                    isDeletedUser,
                    isUnknownUser,
                    status = '',
                    roles = [],
                    monitoringCounts = {},
                    applicationStatuses = {},
                  },
                }) => {
                  return {
                    templateName: type,
                    fullName: `${name.first} ${name.last}`,
                    uuid,
                    cuid,
                    roles: rolesLabels(roles),
                    statuses: [status],
                    monitoringCounts: [
                      MONITORING_COUNT_TYPES.EMPLOYEE,
                      MONITORING_COUNT_TYPES.CAID,
                      MONITORING_COUNT_TYPES.CUST,
                      MONITORING_COUNT_TYPES.CUSTOM_GROUP,
                    ]
                      .filter((key) => undefined !== monitoringCounts[key])
                      .map((key) => ({
                        key: MONITORING_COUNT_LABEL_MAP[key],
                        value: monitoringCounts[key],
                      })),
                    searchAndExport: mapAdminAppStatusToGridStatus(
                      applicationStatuses.searchAndExport
                    ),
                    mfca: mapAdminAppStatusToGridStatus(
                      applicationStatuses.mfca
                    ),
                    surveillance: mapAdminAppStatusToGridStatus(
                      applicationStatuses.surveillance
                    ),
                    pchl: mapAdminAppStatusToGridStatus(
                      applicationStatuses.pchl
                    ),
                    trade: mapAdminAppStatusToGridStatus(
                      applicationStatuses.trade
                    ),
                    isNameUnknown: isUnknownUser,
                    isUserDeleted: isDeletedUser,
                    isUserInactive: statusIsInactive(status),
                  };
                }
              )
              .map(rowGenerator);
          }
          return loadingRows(30, 'rosterAdminLoading', rowGenerator);
        },
        summaryHero() {
          const summaryFields = [
            ADMIN_SUMMARY_FIELDS.ADMINS,
            ADMIN_SUMMARY_FIELDS.NOT_MONITORING,
            ADMIN_SUMMARY_FIELDS.INACTIVE,
            ADMIN_SUMMARY_FIELDS.DIST_AVG,
          ];
          if (
            adminRosterResource &&
            adminRosterResource.meta &&
            adminRosterResource.meta.datum
          ) {
            // This is needed to guarantee existence of field and field ordering
            const summary = [];
            let summaryMap = {};

            adminRosterResource.meta.datum.forEach((datum) => {
              summaryMap = { ...summaryMap, ...datum };
            });

            summaryFields.forEach((summaryField) => {
              summary.push({
                name: ADMIN_SUMMARY_LABEL_MAP[summaryField],
                value: formatIfNumeral(summaryMap[summaryField]) || '--',
              });
            });

            return <DatumHero data={summary} />;
          }
          return <DatumHeroLoading count={summaryFields.length} />;
        },
        rowCountInfo() {
          if (adminRosterResource) {
            const totalCount =
              (adminRosterResource &&
                adminRosterResource.meta &&
                adminRosterResource.meta.totalResults) ||
              0;

            if (totalCount > 0) {
              return {
                totalCount,
                ...getRowCountInfo(
                  totalCount,
                  tabStates[ROSTER_TABS.ADMINS].page,
                  pagelimit,
                  pathname,
                  subFirmTab,
                  queryParam
                ),
              };
            }
          }

          // Catch all case
          return {
            totalCount: 0,
            firstRowNum: 0,
            lastRowNum: 0,
            nextLink: '',
            previousLink: '',
          };
        },
      },

      /// /////////////////////
      // monitoredEmployees //
      /// /////////////////////
      monitoredEmployees: {
        resourceStatus() {
          return monitoredEmployeesRosterStatus;
        },
        gridContent(rowGenerator) {
          if (monitoredEmployeesRosterStatus.failed) {
            return (
              <tr>
                <td>
                  <Error />
                </td>
              </tr>
            );
          }
          if (
            monitoredEmployeesRosterResource &&
            monitoredEmployeesRosterResource.data &&
            !monitoredEmployeesRosterStatus.pending
          ) {
            return monitoredEmployeesRosterResource.data
              .map(
                ({
                  type,
                  attributes: {
                    name,
                    uuid,
                    cuid,
                    status = '',
                    roles = [],
                    dataHolds = false,
                    applicationStatuses = {},
                  },
                }) => {
                  return {
                    templateName: type,
                    fullName: `${name.first} ${name.last}`,
                    uuid,
                    cuid,
                    statuses: [status],
                    roles: rolesLabels(roles),
                    dataHolds,
                    searchAndExport: mapMonitoredEmployeesAppStatusToGridStatus(
                      applicationStatuses.searchAndExport
                    ),
                    mfca: mapMonitoredEmployeesAppStatusToGridStatus(
                      applicationStatuses.mfca
                    ),
                    surveillance: mapMonitoredEmployeesAppStatusToGridStatus(
                      applicationStatuses.surveillance
                    ),
                    pchl: mapMonitoredEmployeesAppStatusToGridStatus(
                      applicationStatuses.pchl
                    ),
                    isNameUnknown: nameIsUnknown(name),
                    isUserDeleted: nameIsDeleted(name),
                    isUserInactive: statusIsInactive(status),
                  };
                }
              )
              .map(rowGenerator);
          }
          return loadingRows(30, 'monitoredEmployeesLoading', rowGenerator);
        },
        summaryHero() {
          const summaryFields = [
            ME_SUMMARY_FIELDS.MONITORED_EMPLOYEES,
            ME_SUMMARY_FIELDS.NOT_MONITORED,
            ME_SUMMARY_FIELDS.ACTIVE,
            ME_SUMMARY_FIELDS.DATA_HOLDS,
          ];
          if (
            monitoredEmployeesRosterResource &&
            monitoredEmployeesRosterResource.meta &&
            monitoredEmployeesRosterResource.meta.datum
          ) {
            // This is needed to guarantee existence of field and field ordering
            let summaryMap = {};
            const summary = [];
            monitoredEmployeesRosterResource.meta.datum.forEach((datum) => {
              summaryMap = { ...summaryMap, ...datum };
            });

            summaryFields.forEach((summaryField) => {
              summary.push({
                name: ME_SUMMARY_LABEL_MAP[summaryField],
                value: formatIfNumeral(summaryMap[summaryField]) || '--',
              });
            });

            return <DatumHero data={summary} />;
          }
          return <DatumHeroLoading count={summaryFields.length} />;
        },
        rowCountInfo() {
          if (monitoredEmployeesRosterResource) {
            const totalCount =
              (monitoredEmployeesRosterResource &&
                monitoredEmployeesRosterResource.meta &&
                monitoredEmployeesRosterResource.meta.totalResults) ||
              0;

            if (totalCount > 0) {
              return {
                totalCount,
                ...getRowCountInfo(
                  totalCount,
                  tabStates[ROSTER_TABS.MONITORED_EMPLOYEES].page,
                  pagelimit,
                  pathname,
                  subFirmTab,
                  queryParam
                ),
              };
            }
          }

          // Catch all case
          return {
            totalCount: 0,
            firstRowNum: 0,
            lastRowNum: 0,
            nextLink: '',
            previousLink: '',
          };
        },
      },

      /// ///////////////
      // jurisdiction //
      /// ///////////////
      jurisdiction: {
        resourceStatus() {
          return jurisdictionStatus;
        },
        gridContent(rowGenerator) {
          if (jurisdictionAttrs) {
            if (jurisdictionAttrs.isSuperUser) {
              return (
                <tr>
                  <td colSpan="6">
                    <center>
                      <h1>
                        <FormattedMessage id="firm.super_user" />
                      </h1>
                    </center>
                  </td>
                </tr>
              );
            }
            const results = [];

            const { controlOwnerJurisdiction } = jurisdictionAttrs;
            const jurisdictionPage = getJurisdictionCusts({
              controlOwnerJurisdiction:
                jurisdictionAttrs.controlOwnerJurisdiction,
              pagelimit: JURISDICTION_PAGE_LIMIT,
              tabState: tabStates[ROSTER_TABS.JURISDICTION],
            });

            const custsResources = getResources(
              custsStore,
              jurisdictionPage.jurisdictionCusts
            );
            if (
              custsResources &&
              custsResources.length ===
                jurisdictionPage.jurisdictionCusts.length
            ) {
              custsResources.forEach((c) => {
                const caids =
                  c.attributes.caids && c.attributes.caids.length
                    ? c.attributes.caids
                    : [' '];
                caids.forEach((caid, i) => {
                  results.push({
                    templateName: 'jurisdiction',
                    id: i === 0 ? c.id : ' ',
                    custName: i === 0 ? c.attributes.name : ' ',
                    region: i === 0 ? c.attributes.region : ' ',
                    caid,
                    country:
                      i === 0 ? expandCountryCode(c.attributes.country) : ' ',
                    caidInJurisdiction: controlOwnerJurisdiction.caids.includes(
                      caid
                    ),
                  });
                });
              });
            } else {
              controlOwnerJurisdiction.custs.forEach((c) => {
                results.push({
                  templateName: 'jurisdiction',
                  id: c,
                });
              });
            }

            if (results.length > 0) {
              return results.map(rowGenerator);
            }
            return loadingRows(30, 'jurisdictionLoading', rowGenerator);
          }
          return (
            <h1>
              <FormattedMessage id="control_owner.jurisdiction_unavailable" />
            </h1>
          );
        },
        summaryHero() {
          if (jurisdictionAttrs) {
            const summary = [];
            const jurisdiction = jurisdictionAttrs;
            const datum = jurisdictionDescriptionDatum(jurisdiction);
            summary.push({
              name: intl('control_owner.jurisdiction_coverage'),
              value: (
                <ExtendedDatumValueWithOverflow
                  name={datum.name}
                  value={datum.value}
                />
              ),
            });
            summary.push({
              name: intl('firm.cust_accts'),
              value: `${
                jurisdiction.controlOwnerJurisdiction.custs
                  ? jurisdiction.controlOwnerJurisdiction.custs.length
                  : 0
              }`,
            });
            summary.push({
              name: intl('firm.caids'),
              value: `${
                jurisdiction.controlOwnerJurisdiction.caids
                  ? jurisdiction.controlOwnerJurisdiction.caids.length
                  : 0
              }`,
            });

            return <DatumHero data={summary} />;
          }
          return <DatumHeroLoading count={3} />;
        },
        rowCountInfo() {
          if (jurisdictionAttrs) {
            if (!jurisdictionAttrs.isSuperUser) {
              const jurisdictionPage = getJurisdictionCusts({
                controlOwnerJurisdiction:
                  jurisdictionAttrs.controlOwnerJurisdiction,
                pagelimit: JURISDICTION_PAGE_LIMIT,
                tabState: tabStates[ROSTER_TABS.JURISDICTION],
              });

              const { totalCount } = jurisdictionPage;

              if (totalCount > 0) {
                return {
                  totalCount,
                  ...getRowCountInfo(
                    totalCount,
                    jurisdictionPage.currentPage,
                    JURISDICTION_PAGE_LIMIT,
                    pathname,
                    subFirmTab,
                    queryParam
                  ),
                };
              }
            }
          }

          // Catch all case
          return {
            totalCount: 0,
            firstRowNum: 0,
            lastRowNum: 0,
            nextLink: '',
            previousLink: '',
          };
        },
      },
    };
  };

  // Count the number of jurisdiction names to decided if the heroNav should be 'tall' or not
  navClassName = (props = this.props, tallScopeString = TALL_SCOPE_STRING) => {
    const { jurisdictionAttrs } = props;
    // Placeholder logic, should move to the logic below when jurisdiction service is available
    return jurisdictionAttrs && jurisdictionAttrs.scope === tallScopeString
      ? 'tall'
      : '';

    // return jurisdictionAttrs
    //     && jurisdictionAttrs.scope.split("_").includes("REGION")
    //     && jurisdictionAttrs.scope.split("_").includes("COUNTRY") ? 'tall' : ''
  };

  onShowGenerateReportModal = (props = this.props) => {
    const {
      subFirmTab,
      jurisdictionAttrs,
      currentUserAttrs,
      currentFirmResource,
    } = props;
    const reportTitle = (
      <div>
        {ROSTER_REPORT_TITLES[subFirmTab]}{' '}
        {new Date().toLocaleDateString('en-US')}
      </div>
    );
    this.setState({
      generateReportModalInfo: {
        firmName: get(currentFirmResource, 'attributes.firmName', ''),
        reportTitle,
        scope: this.scopeStr(),
        isImpersonating: isImpersonating(jurisdictionAttrs),
        bbgEmail: currentUserAttrs.bbgEmail,
        corpEmail: currentUserAttrs.corpEmail,
      },
    });
  };

  onCancelGenerateReportModal = (props = this.props) => {
    this.setState({ generateReportModalInfo: null });
  };

  scopeStr = (props = this.props) => {
    const {
      queryParam: { cust, caid },
    } = props;
    if (cust) {
      return `${intl('firm.cust_id')}: ${cust}`;
    }
    if (caid) {
      return `CAID: ${caid}`;
    }
  };

  onGenerateReport = (props = this.props, state = this.state) => {
    const {
      currentUserAttrs,
      firmId,
      subFirmTab: reportType,
      pagelimit,
      tabStates,
      queryParam: { cust, caid },
      createReport,
      createAlert,
      csrftoken,
    } = props;
    createReport(
      reportType,
      csrftoken,
      reportType === ROSTER_TABS.JURISDICTION
        ? { firmId, uuid: currentUserAttrs.uuid } // Jurisdiction Report
        : {
            ...getReadRosterInput({
              firmId,
              pagelimit,
              tabState: tabStates[reportType],
              cust,
              caid,
            }),
          } // Admins & ME Report
    ).then(
      (v) => {
        debug(v);
        createAlert(ALERT_TYPES.INFO, intl('report.report_delivered'), 5000);
      },
      (r) => {
        debug('REJECT', r);
        createAlert(ALERT_TYPES.ERROR, intl('report.error_generating_report'));
      }
    );

    this.setState({ generateReportModalInfo: null });
  };

  render() {
    const {
      firmId,
      subFirmTab,
      location: { pathname, search },
      tabStates,
      queryParam,
    } = this.props;

    const { generateReportModalInfo } = this.state;

    const dataRetrievers = this.dataRetriever();
    const dataRetriever = dataRetrievers[subFirmTab];
    const adminUiStatus = dataRetriever.resourceStatus();

    const isRosterLoading = !(
      (adminUiStatus && adminUiStatus.succeeded) ||
      false
    );
    const isLoadingPrevious = isRosterLoading && this.state.isLoadingPrevious;
    const isLoadingNext = isRosterLoading && this.state.isLoadingNext;

    const { gridContent } = dataRetriever;
    const rowCountInfo = dataRetriever.rowCountInfo();
    const tabSummaries = {
      [ROSTER_TABS.MONITORED_EMPLOYEES]: dataRetrievers.monitoredEmployees.summaryHero(),
      [ROSTER_TABS.ADMINS]: dataRetrievers.admins.summaryHero(),
      [ROSTER_TABS.JURISDICTION]: dataRetrievers.jurisdiction.summaryHero(),
    };

    return (
      <Roster
        firmId={parseInt(firmId, 10)}
        tab={subFirmTab}
        gridContent={gridContent}
        isRosterLoading={isRosterLoading}
        isLoadingPrevious={isLoadingPrevious}
        isLoadingNext={isLoadingNext}
        tabSummaries={tabSummaries}
        rowCountInfo={rowCountInfo}
        onLoadPrevious={this.onLoadPrevious}
        onLoadNext={this.onLoadNext}
        onRowClick={this.onRosterRowClick}
        onCommitFilter={this.onCommitFilter}
        appliedFilters={tabStates[subFirmTab].filters}
        pathname={pathname}
        queryString={search}
        queryParams={queryParam}
        navClassName={this.navClassName()}
        generateReportModalInfo={generateReportModalInfo}
        onShowGenerateReportModal={this.onShowGenerateReportModal}
        onCancelGenerateReportModal={this.onCancelGenerateReportModal}
        onGenerateReport={this.onGenerateReport}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RosterContainer);
/* eslint-enable */
