/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Popover from 'react-popover';

import './styles/Tooltip.css';

// https://bbgithub.dev.bloomberg.com/bbnpm/bb-ui-framework/blob/master/src/components/Tooltip/Tooltip.js
export class Tooltip extends Component {
  static propTypes = {
    /**
     * Preferred position for the tooltip. If there is not enough space for the tooltip to render it will switch
     * orientations automatically.
     */
    position: PropTypes.oneOf(['above', 'right', 'below', 'left']),
    /**
     * Classes will be applied to the root tag.
     */
    className: PropTypes.string,
    /**
     * The contents of the tooltip.
     */
    content: PropTypes.node,
    /**
     * The target of the tooltip.
     */
    children: PropTypes.node,
    /**
     * event that will trigger the tooltip
     */
    triggerOn: PropTypes.oneOf(['hover', 'focus']),
    /**
     * Use to pass extra props to the target wrapper
     */
    targetProps: PropTypes.object,
    /** @ignore */
    showTooltip: PropTypes.bool,
    /** @ignore */
    onShowTooltip: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    position: 'below',
    content: null,
    children: null,
    triggerOn: 'hover',
    showTooltip: false,
    onShowTooltip: _.noop,
  };

  state = {
    popoverIsOpen: false,
  };

  handleShowTooltip = (triggerType, value) => {
    if (this.props.triggerOn === triggerType) {
      this.setState({ showTooltip: value });
    }
  };

  render() {
    const {
      className,
      children,
      content,
      position,
      targetProps,
      ...rest
    } = this.props;
    const { showTooltip } = this.state;

    return (
      <Popover
        body={content}
        isOpen={showTooltip}
        onOuterAction={_.partial(this.handleShowTooltip, 'hover', false)}
        preferPlace={position}
        className={className}
        {..._.omit(rest, ['onShowTooltip', 'targetProps'])}
      >
        <span
          className="tooltip target"
          onMouseEnter={_.partial(this.handleShowTooltip, 'hover', true)}
          onMouseLeave={_.partial(this.handleShowTooltip, 'hover', false)}
          {...targetProps}
        >
          {React.isValidElement(children) // Avoid passing props if label is a string.
            ? React.Children.map(children, (child, index) =>
                React.cloneElement(child, {
                  key: index,
                  onFocus: _.partial(this.handleShowTooltip, 'focus', true),
                  onBlur: _.partial(this.handleShowTooltip, 'focus', false),
                })
              )
            : children}
        </span>
      </Popover>
    );
  }
}

export default Tooltip;
/* eslint-enable */
