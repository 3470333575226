import { connect } from 'react-redux';

import Alerts from '../components/Alerts';
import { dismissAlert } from '../store/alertManager';

/* eslint-disable no-unused-vars */
const mapStateToProps = (state, props) => {
  return {
    alerts: state.alertManager.alerts,
  };
};
/* eslint-enable no-unused-vars */

const mapDispatchToProps = { dismissAlert };

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
