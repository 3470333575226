/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import FontIcon from './FontIcon';
import { SCOPE_TYPE, ScopeType, FirmType } from '../PropTypes';

import './styles/FirmHeader.css';
import debugMod from 'debug';
import { intl } from '../intl/IntlFormat';
import { FormattedMessage } from 'react-intl';

const SCOPE_LABEL = {
  [SCOPE_TYPE.CUST]: intl('search.cust'),
  [SCOPE_TYPE.CAID]: 'CAID',
};

const debug = debugMod('roster:FirmHeader');

export const FirmTitle = ({ firm }) => (
  <div className={`firmTitle${firm ? '' : ' loading'}`}>
    <h1 className="firmName">{firm && firm.firmName}</h1>
    <span className="firmId">{firm && `ID: (${firm.firmId})`}</span>
  </div>
);

FirmTitle.propTypes = { firm: PropTypes.shape(FirmType) };

export const ScopeTitle = ({ scope }) => (
  <div className={`scopeTitle${scope ? '' : ' loading'}`}>
    <h2 className="scopeName">{scope && scope.scopeName}</h2>
    <span className="scopeId">{scope && `ID: (${scope.scopeId})`}</span>
  </div>
);

ScopeTitle.propTypes = { scope: PropTypes.shape(ScopeType) };

export const FirmBadge = ({
  changeScopeOnClick = () => debug('scopeOnClick'),
  clearScopeOnClick = () => debug('clearScopeOnClick'),
  firm,
  scope,
}) => (
  <div className="firmBadge">
    <FirmTitle firm={firm} />
    <em>
      <FormattedMessage id="firm.viewing" />
    </em>
    {scope ? (
      <b className="selectedScope">
        <span className="entity" onClick={changeScopeOnClick}>
          {SCOPE_LABEL[scope.scopeType]} &ndash; <ScopeTitle scope={scope} />
        </span>
        <span onClick={clearScopeOnClick}>
          <FontIcon icon="close" />
        </span>
      </b>
    ) : (
      <b className="unscoped" onClick={changeScopeOnClick}>
        {intl('firm.entire_jurisdiction')}
        <FontIcon icon="edit" />
      </b>
    )}
  </div>
);

FirmBadge.propTypes = {
  changeScopeOnClick: PropTypes.func,
  clearScopeOnClick: PropTypes.func,
  firm: PropTypes.shape(FirmType),
  scope: PropTypes.shape(ScopeType),
};
export const FirmBadgeLoading = () => (
  <div className="firmBadge loading">
    <FirmTitle />
    <em />
    <b className="selectedScope" />
  </div>
);

export const FirmHeader = ({ firmBadge, infoElement }) => (
  <div className="firmHeader">
    {firmBadge}
    {infoElement}
  </div>
);

FirmHeader.propTypes = {
  firmBadge: PropTypes.element.isRequired,
  infoElement: PropTypes.element.isRequired,
};

export default FirmHeader;
/* eslint-enable */
