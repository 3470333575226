import React, { useEffect, useState, useRef } from 'react';
import Tooltip from '../Tooltip';

/*
  For cell value content that should only show a tooltip
  on-hover if the content of the cell is overflowing
*/
const OverflowCellValueTooltip = ({ content, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setShowTooltip(checkOverflow());
  }, [content]);

  function checkOverflow() {
    return ref.current && ref.current.scrollWidth > ref.current.offsetWidth;
  }

  return (
    <td className="value canOverflow" ref={ref}>
      {showTooltip ? <Tooltip content={content}>{children}</Tooltip> : children}
    </td>
  );
};

export default OverflowCellValueTooltip;
