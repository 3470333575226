import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import debugMod from 'debug';

import Loader, { LoadingAnimation } from '../Loader';
import { intl } from '../../intl/IntlFormat';

import './styles/Grid.css';
import Error from '../Error';

// eslint-disable-next-line
const debug = debugMod('roster:Grid');
/////////////////////
// Paging Controls //
/////////////////////

const PAGING_BUTTON_TYPES = {
  PREVIOUS: 'previous',
  NEXT: 'next',
};

const PagingButton = ({
  type,
  link,
  numColumns,
  isLoading,
  isClickable,
  onClick,
}) => {
  const pagingButton = (
    <div
      className="pagingButton"
      onClick={function (e) {
        isClickable && onClick(e);
      }}
    >
      <LoadingAnimation isLoading={isLoading} />
      {isLoading
        ? intl('utility.loading')
        : PAGING_BUTTON_TYPES.PREVIOUS === type
        ? intl('utility.load_previous')
        : intl('utility.load_next')}
    </div>
  );

  return (
    <tr>
      <td
        colSpan={numColumns}
        className={
          'pagingControl' + (!isLoading && isClickable ? ' clickable' : '')
        }
      >
        {!isLoading && isClickable ? (
          <Link to={link}>{pagingButton}</Link>
        ) : (
          pagingButton
        )}
      </td>
    </tr>
  );
};

////////////////
// Loader Row //
////////////////

const LoaderRow = ({ numColumns }) => (
  <tr>
    <td colSpan={numColumns} className="loaderRow">
      <Loader />
    </td>
  </tr>
);

//////////
// Grid //
//////////

const Grid = ({
  columns,
  preHeaders = [],
  rowtemplates,
  gridContent,
  isLoadingRows,
  className = '',
  pagingControl = {},
  gridFooter,
}) => {
  const preColumnHeaders = (
    <tr className="preHeaderRow">
      {preHeaders.map((column, colIndex) => (
        <th
          key={colIndex}
          className={'preHeaderRowCell ' + (column.className || '')}
          colSpan={column.colSpan || 1}
        >
          {column.title}
        </th>
      ))}
    </tr>
  );
  const columnHeaders = (
    <tr className="headerRow">
      {columns.map((column, colIndex) => (
        <th
          key={colIndex}
          className={'headerRowCell ' + (column.className || '')}
        >
          {column.title}
        </th>
      ))}
    </tr>
  );

  const rowGenerator = (row, rowIndex) => {
    const { cells, onRowClick } = rowtemplates[row.templateName];

    const hasRowClickHandler = 'function' === typeof onRowClick;

    return (
      <tr
        className={
          'gridRow' + (hasRowClickHandler ? ' highlightRowOnHover' : '')
        }
        key={rowIndex}
        onClick={
          hasRowClickHandler
            ? (event) => {
                event.preventDefault();
                onRowClick(row);
              }
            : undefined
        }
      >
        {cells.map(
          (
            { mapDataToProps, cellTemplate: CellTemplate, className },
            cellIndex
          ) => (
            <td key={cellIndex} className="gridRowCell">
              <CellTemplate
                value={mapDataToProps && mapDataToProps(row)}
                className={className}
              />
            </td>
          )
        )}
      </tr>
    );
  };
  const gridRows = gridContent(rowGenerator);

  const {
    previousLink,
    isLoadingPrevious,
    onLoadPrevious,
    nextLink,
    isLoadingNext,
    onLoadNext,
  } = pagingControl;

  return (
    <div className={'gridComponent ' + className}>
      <table className="gridTable">
        <tbody>
          {preColumnHeaders}
          {columnHeaders}
          {(((previousLink || onLoadPrevious) && !isLoadingRows) ||
            isLoadingPrevious) && (
            <PagingButton
              type={PAGING_BUTTON_TYPES.PREVIOUS}
              link={previousLink || '#'}
              numColumns={columns.length}
              isLoading={pagingControl.isLoadingPrevious}
              isClickable={!pagingControl.isLoadingNext}
              onClick={onLoadPrevious || function () {}}
            />
          )}
          {isLoadingRows && !(isLoadingPrevious || isLoadingNext) ? (
            <LoaderRow numColumns={columns.length} />
          ) : (
            gridRows
          )}
          {(((nextLink || onLoadNext) && !isLoadingRows) || isLoadingNext) && (
            <PagingButton
              type={PAGING_BUTTON_TYPES.NEXT}
              link={nextLink || '#'}
              numColumns={columns.length}
              isLoading={pagingControl.isLoadingNext}
              isClickable={!pagingControl.isLoadingPrevious}
              onClick={onLoadNext || function () {}}
            />
          )}
        </tbody>
      </table>
      {gridFooter && <span className="gridFooter">{gridFooter}</span>}
    </div>
  );
};

const PreHeaderShape = PropTypes.shape({
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  colSpan: PropTypes.number,
});

const ColumnShape = PropTypes.shape({
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
});

const CellTemplateShape = PropTypes.shape({
  mapDataToProps: PropTypes.func,
  cellTemplate: PropTypes.func.isRequired,
  className: PropTypes.string,
});

const RowTemplateShape = {
  cells: PropTypes.arrayOf(CellTemplateShape).isRequired,
  onRowClick: PropTypes.func,
};

export const PagingControlShape = {
  nextLink: PropTypes.string,
  isLoadingNext: PropTypes.bool,
  previousLink: PropTypes.string,
  isLoadingPrevious: PropTypes.bool,
  onLoadPrevious: PropTypes.func,
  onLoadNext: PropTypes.func,
};
Grid.propTypes = {
  preHeaders: PropTypes.arrayOf(PreHeaderShape),
  columns: PropTypes.arrayOf(ColumnShape).isRequired,
  rowtemplates: PropTypes.objectOf(PropTypes.shape(RowTemplateShape))
    .isRequired,
  gridContent: PropTypes.func.isRequired,
  pagingControl: PropTypes.shape(PagingControlShape),
  gridFooter: PropTypes.node,
};

export const GridLoading = ({ columns, rowtemplate, rowCount }) => {
  const columnHeaders = (
    <tr>
      {columns.map((column, colIndex) => (
        <th
          key={colIndex}
          className={'headerRow ' + (column.className || '')}
        />
      ))}
    </tr>
  );

  const gridRows = [...Array(rowCount)].map((row, rowIndex) => (
    <tr key={rowIndex}>
      {rowtemplate.map(
        (
          { mapDataToProps, cellTemplate: CellTemplate, className },
          cellIndex
        ) => (
          <td key={cellIndex} className="gridRow">
            <CellTemplate className={className} />
          </td>
        )
      )}
    </tr>
  ));

  return (
    <div className="gridComponent loading">
      <table className="gridTable">
        <tbody>
          {columnHeaders}
          {gridRows}
        </tbody>
      </table>
    </div>
  );
};
GridLoading.propTypes = {
  columns: PropTypes.arrayOf(ColumnShape).isRequired,
  //rowtemplate: RowTemplateShape.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export const GridError = ({ columns }) => {
  const columnHeaders = (
    <tr>
      {columns.map((column, colIndex) => (
        <th key={colIndex} className={'headerRow ' + (column.className || '')}>
          {column.title}
        </th>
      ))}
    </tr>
  );

  return (
    <div className="gridComponent error">
      <div className="row">
        <table className="gridTable">
          <tbody>
            {columnHeaders}
            <tr>
              <td colSpan={columns.length}>
                <Error />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
GridError.propTypes = {
  columns: PropTypes.arrayOf(ColumnShape).isRequired,
};

export const GridMessage = ({
  columns,
  className = '',
  preHeaders = [],
  children,
}) => {
  const preColumnHeaders = (
    <tr className="preHeaderRow">
      {preHeaders.map((column, colIndex) => (
        <th
          key={colIndex}
          className={'preHeaderRowCell ' + (column.className || '')}
          colSpan={column.colSpan || 1}
        >
          {column.title}
        </th>
      ))}
    </tr>
  );
  const columnHeaders = (
    <tr className="headerRow">
      {columns.map((column, colIndex) => (
        <th
          key={colIndex}
          className={'headerRowCell ' + (column.className || '')}
        >
          {column.title}
        </th>
      ))}
    </tr>
  );

  return (
    <div className={`gridComponent ${className}`}>
      <table className="gridTable">
        <tbody>
          {preColumnHeaders}
          {columnHeaders}
          <tr>
            <td colSpan={columns.length}>
              <div className="gridMessage">{children}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
GridMessage.propTypes = {
  columns: PropTypes.arrayOf(ColumnShape).isRequired,
  className: PropTypes.string,
  preHeaders: PropTypes.arrayOf(PreHeaderShape),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Grid;
export { PagingButton, PAGING_BUTTON_TYPES };
