import React from 'react';

import FontIcon from './FontIcon';

import './styles/Error.css';
import { FormattedMessage } from 'react-intl';

const Error = ({ className }) => (
  <div className={`errorComponent ${className || ''}`}>
    <FontIcon icon="warning" />
    <div className="errorMessage">
      <span className="errorHead">
        <FormattedMessage id="error.error_loading_data" />
      </span>
      <span className="errorLead">
        <FormattedMessage id="error.please_refresh_try_again" />
      </span>
    </div>
  </div>
);

export default Error;
